(function (cjs, an) {

  var p; // shortcut to reference prototypes
  var lib = {};
  var ss = {};
  var img = {};
  lib.ssMetadata = [];


  (lib.AnMovieClip = function () {
    this.actionFrames = [];
    this.ignorePause = false;
    this.gotoAndPlay = function (positionOrLabel) {
      cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
    }
    this.play = function () {
      cjs.MovieClip.prototype.play.call(this);
    }
    this.gotoAndStop = function (positionOrLabel) {
      cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
    }
    this.stop = function () {
      cjs.MovieClip.prototype.stop.call(this);
    }
  }).prototype = p = new cjs.MovieClip();
  // symbols:



  (lib.bg = function () {
    this.initialize(img.bg);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 1748, 826);


  (lib.chatinput = function () {
    this.initialize(img.chatinput);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 684, 257);


  (lib.menuicox3 = function () {
    this.initialize(img.menuicox3);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 523, 360);


  (lib.Menu = function () {
    this.initialize(img.Menu);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 83, 58);


  (lib.resbtns = function () {
    this.initialize(img.resbtns);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 513, 271);


  (lib.restauranticox3 = function () {
    this.initialize(img.restauranticox3);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 524, 524);


  (lib.restaurantico = function () {
    this.initialize(img.restaurantico);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 83, 83);


  (lib.rocket = function () {
    this.initialize(img.rocket);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 557, 569);


  (lib.smoke = function () {
    this.initialize(img.smoke);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 180, 367);


  (lib.Support = function () {
    this.initialize(img.Support);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 83, 83);


  (lib.support_ico_x3 = function () {
    this.initialize(img.support_ico_x3);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 524, 524);


  (lib.TopMenu111 = function () {
    this.initialize(img.TopMenu111);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 5192, 322);


  (lib.TopMenuMobile = function () {
    this.initialize(img.TopMenuMobile);
  }).prototype = p = new cjs.Bitmap();
  p.nominalBounds = new cjs.Rectangle(0, 0, 1567, 206);


  (lib.Tween101 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#FFFFFF").s().p("AgqCjIAAg5IBVAAIAAA5gAgiBFIgJjoIBXAAIgJDog");
    this.shape.setTransform(204.175, 1.5);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#FFFFFF").s().p("AhfCLQgZgiAAg7QAAggAIgXQAIgYAQgSQAMgPAUgKQATgJAVAAQATAAAMAEQANAEAQAIIAAhpIBNAAIAAFVIhNAAIAAgZIgRANQgKAHgHADQgJAFgIACQgJADgNgBQgqABgYgkgAgcgIQgOATABAiQgBAlALAQQALAQAYAAQALAAAKgEQAKgEAJgGIAAh5QgGgDgJgBQgJgCgHAAQgaAAgPATg");
    this.shape_1.setTransform(181.45, 1.1);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#FFFFFF").s().p("AhSB8IAAj2IBNAAIAAAjIAOgMIARgMQAIgFALgDQALgEAKAAIAJAAIAIABIAABJIgHAAQgEgCgIAAIgTgBQgMAAgNADQgNAEgMAFIAACkg");
    this.shape_2.setTransform(159.875, 5.475);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#FFFFFF").s().p("AhdBsQgVgWgBghQAAgbAMgRQAKgQAVgKQAVgKAdgEQAcgEAigCIAAgCQAAgUgPgIQgPgIgeAAQgNAAgTAFQgSAFgSAIIgGAAIAAg8QALgDAagEQAagFAaAAQA+AAAeAVQAcAVAAAsIAACnIhMAAIAAgaIgOALQgHAGgKAFQgKAFgJADQgKACgRAAQghAAgWgWgAAHALQgLACgMAEQgJADgGAIQgFAHgBAMQAAARAJAHQAKAGASAAQAJAAALgFQAKgEAJgJIAAgzIggADg");
    this.shape_3.setTransform(134.35, 5.475);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#FFFFFF").s().p("AhdBgQgigjABg9QgBg8AigjQAhgjA8AAQA8AAAiAjQAhAjAAA8QAAA9ghAjQgiAjg8AAQg8AAghgjgAgShHQgIACgHAKQgHAIgDAPQgEAPgBAWQAAAWAEAPQAEANAGAIQAGAJAJAEQAKAEAKAAQAJAAAJgEQAKgEAGgIQAGgJAEgNQAEgOgBgXQABgWgEgOQgEgOgGgKQgHgJgJgDQgIgEgLAAQgJAAgJAEg");
    this.shape_4.setTransform(107.85, 5.5);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#FFFFFF").s().p("AgOCpQgOgEgPgJIgEAMIhJAAIAAlVIBNAAIAAB6QARgPARgJQARgJAXAAQArAAAXAiQAYAhAAA6QAAA8geAkQgeAlgsAAQgRgBgOgEgAgXgVQgKADgKAHIAAB7QAIADAHABIAQABQAbAAAOgSQANgTAAglQAAgkgLgPQgLgRgXABQgKgBgKAEg");
    this.shape_5.setTransform(80.55, 1);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#FFFFFF").s().p("AAnB/IAAh7QAAgOgCgOQgBgPgDgHQgEgJgIgDQgHgEgNAAQgIAAgKAEQgKADgLAIIAACuIhMAAIAAj2IBMAAIAAAbQATgQASgJQARgJAWAAQAmAAATAYQAUAYAAAtIAACgg");
    this.shape_6.setTransform(51.2, 5.175);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#FFFFFF").s().p("AhdBgQghgjgBg9QABg8AhgjQAhgjA8AAQA8AAAiAjQAiAjAAA8QAAA9giAjQgiAjg8AAQg8AAghgjgAgShHQgIACgHAKQgHAIgEAPQgEAPAAAWQABAWADAPQAEANAFAIQAHAJAJAEQAKAEAJAAQAKAAAKgEQAJgEAFgIQAIgJADgNQADgOABgXQgBgWgDgOQgEgOgGgKQgHgJgIgDQgJgEgLAAQgKAAgIAEg");
    this.shape_7.setTransform(22.85, 5.5);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#FFFFFF").s().p("AhUBhQgmghAAg+QAAg8AjgjQAjgkA9AAQA5AAAcAeQAdAeAAA6IAAAaIioAAQABASAHALQAGALAKAHQAKAHAMADQANADAQAAQANAAANgDQANgDALgFQAJgEAIgFIANgJIAIAAIAABAIgRAHQgIAEgNADQgNADgNACQgNACgTAAQhFAAglgigAgfhCQgNANgBAXIBcAAQgBgZgKgNQgLgMgWAAQgVAAgNAOg");
    this.shape_8.setTransform(-17.525, 5.45);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#FFFFFF").s().p("ABtB/IAAh8IgBgdQAAgNgEgIQgDgIgHgDQgHgEgMAAQgJAAgJAEQgJAEgJAHIAACuIhNAAIAAh8IgBgdQAAgNgDgIQgEgIgHgDQgHgEgMAAQgJAAgKAFQgIAEgJAGIAACuIhNAAIAAj2IBNAAIAAAbQASgQARgJQARgJAVAAQAXAAAQALQARALAJAVQAWgVAUgLQATgLAVAAQARAAANAGQAOAFAKALQAKAMAFARQAFAQAAAaIAACgg");
    this.shape_9.setTransform(-52.3, 5.175);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#FFFFFF").s().p("AhdBgQghgjAAg9QAAg8AhgjQAigjA7AAQA9AAAhAjQAhAjAAA8QAAA9ghAjQghAjg9AAQg7AAgigjgAgShHQgIACgHAKQgHAIgEAPQgDAPAAAWQgBAWAEAPQAEANAFAIQAHAJAJAEQAJAEAKAAQAKAAAJgEQAKgEAGgIQAGgJAEgNQADgOAAgXQAAgWgDgOQgEgOgGgKQgHgJgJgDQgIgEgLAAQgJAAgJAEg");
    this.shape_10.setTransform(-87.7, 5.5);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#FFFFFF").s().p("AgaB6QgZgHgTgQQgSgRgKgYQgLgYAAghQAAgjALgYQAMgZATgQQATgPAYgIQAYgHAbAAQAVAAAUAFQAVAFAUAJIAABDIgKAAIgLgKQgGgFgJgEQgIgGgLgCQgKgEgNAAQgdABgPAUQgRAVABAhQgBAlASATQAQARAdABQAOAAALgDQALgEAIgEQAHgFAGgFIAJgJIAKAAIAABCIgPAHQgJADgIACIgWAGQgJABgRAAQgdAAgagIg");
    this.shape_11.setTransform(-113.25, 5.5);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#FFFFFF").s().p("AglCrIAAlVIBLAAIAAFVg");
    this.shape_12.setTransform(-132.15, 0.75);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#FFFFFF").s().p("AhUBhQgmghAAg+QAAg8AjgjQAjgkA9AAQA5AAAcAeQAdAeAAA6IAAAaIioAAQABASAHALQAGALAKAHQAKAHAMADQANADAQAAQANAAANgDQANgDALgFQAJgEAIgFIANgJIAIAAIAABAIgRAHQgIAEgNADQgNADgNACQgNACgTAAQhFAAglgigAgfhCQgNANgBAXIBcAAQgBgZgKgNQgLgMgWAAQgVAAgNAOg");
    this.shape_13.setTransform(-152.225, 5.45);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#FFFFFF").s().p("AA0CjIg0jJIgzDJIhaAAIhTlGIBWAAIAtDbIA3jbIBQAAIA0DbIAwjbIBTAAIhTFGg");
    this.shape_14.setTransform(-188.775, 1.5);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-396.6, -29.1, 793.2, 58.3);


  (lib.Tween100 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#FFFFFF").s().p("AgqCjIAAg5IBVAAIAAA5gAgiBFIgJjoIBXAAIgJDog");
    this.shape.setTransform(204.175, 1.5);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#FFFFFF").s().p("AhfCLQgZgiAAg7QAAggAIgXQAIgYAQgSQAMgPAUgKQATgJAVAAQATAAAMAEQANAEAQAIIAAhpIBNAAIAAFVIhNAAIAAgZIgRANQgKAHgHADQgJAFgIACQgJADgNgBQgqABgYgkgAgcgIQgOATABAiQgBAlALAQQALAQAYAAQALAAAKgEQAKgEAJgGIAAh5QgGgDgJgBQgJgCgHAAQgaAAgPATg");
    this.shape_1.setTransform(181.45, 1.1);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#FFFFFF").s().p("AhSB8IAAj2IBNAAIAAAjIAOgMIARgMQAIgFALgDQALgEAKAAIAJAAIAIABIAABJIgHAAQgEgCgIAAIgTgBQgMAAgNADQgNAEgMAFIAACkg");
    this.shape_2.setTransform(159.875, 5.475);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#FFFFFF").s().p("AhdBsQgVgWgBghQAAgbAMgRQAKgQAVgKQAVgKAdgEQAcgEAigCIAAgCQAAgUgPgIQgPgIgeAAQgNAAgTAFQgSAFgSAIIgGAAIAAg8QALgDAagEQAagFAaAAQA+AAAeAVQAcAVAAAsIAACnIhMAAIAAgaIgOALQgHAGgKAFQgKAFgJADQgKACgRAAQghAAgWgWgAAHALQgLACgMAEQgJADgGAIQgFAHgBAMQAAARAJAHQAKAGASAAQAJAAALgFQAKgEAJgJIAAgzIggADg");
    this.shape_3.setTransform(134.35, 5.475);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#FFFFFF").s().p("AhdBgQgigjABg9QgBg8AigjQAhgjA8AAQA8AAAiAjQAhAjAAA8QAAA9ghAjQgiAjg8AAQg8AAghgjgAgShHQgIACgHAKQgHAIgDAPQgEAPgBAWQAAAWAEAPQAEANAGAIQAGAJAJAEQAKAEAKAAQAJAAAJgEQAKgEAGgIQAGgJAEgNQAEgOgBgXQABgWgEgOQgEgOgGgKQgHgJgJgDQgIgEgLAAQgJAAgJAEg");
    this.shape_4.setTransform(107.85, 5.5);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#FFFFFF").s().p("AgOCpQgOgEgPgJIgEAMIhJAAIAAlVIBNAAIAAB6QARgPARgJQARgJAXAAQArAAAXAiQAYAhAAA6QAAA8geAkQgeAlgsAAQgRgBgOgEgAgXgVQgKADgKAHIAAB7QAIADAHABIAQABQAbAAAOgSQANgTAAglQAAgkgLgPQgLgRgXABQgKgBgKAEg");
    this.shape_5.setTransform(80.55, 1);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#FFFFFF").s().p("AAnB/IAAh7QAAgOgCgOQgBgPgDgHQgEgJgIgDQgHgEgNAAQgIAAgKAEQgKADgLAIIAACuIhMAAIAAj2IBMAAIAAAbQATgQASgJQARgJAWAAQAmAAATAYQAUAYAAAtIAACgg");
    this.shape_6.setTransform(51.2, 5.175);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#FFFFFF").s().p("AhdBgQghgjgBg9QABg8AhgjQAhgjA8AAQA8AAAiAjQAiAjAAA8QAAA9giAjQgiAjg8AAQg8AAghgjgAgShHQgIACgHAKQgHAIgEAPQgEAPAAAWQABAWADAPQAEANAFAIQAHAJAJAEQAKAEAJAAQAKAAAKgEQAJgEAFgIQAIgJADgNQADgOABgXQgBgWgDgOQgEgOgGgKQgHgJgIgDQgJgEgLAAQgKAAgIAEg");
    this.shape_7.setTransform(22.85, 5.5);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#FFFFFF").s().p("AhUBhQgmghAAg+QAAg8AjgjQAjgkA9AAQA5AAAcAeQAdAeAAA6IAAAaIioAAQABASAHALQAGALAKAHQAKAHAMADQANADAQAAQANAAANgDQANgDALgFQAJgEAIgFIANgJIAIAAIAABAIgRAHQgIAEgNADQgNADgNACQgNACgTAAQhFAAglgigAgfhCQgNANgBAXIBcAAQgBgZgKgNQgLgMgWAAQgVAAgNAOg");
    this.shape_8.setTransform(-17.525, 5.45);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#FFFFFF").s().p("ABtB/IAAh8IgBgdQAAgNgEgIQgDgIgHgDQgHgEgMAAQgJAAgJAEQgJAEgJAHIAACuIhNAAIAAh8IgBgdQAAgNgDgIQgEgIgHgDQgHgEgMAAQgJAAgKAFQgIAEgJAGIAACuIhNAAIAAj2IBNAAIAAAbQASgQARgJQARgJAVAAQAXAAAQALQARALAJAVQAWgVAUgLQATgLAVAAQARAAANAGQAOAFAKALQAKAMAFARQAFAQAAAaIAACgg");
    this.shape_9.setTransform(-52.3, 5.175);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#FFFFFF").s().p("AhdBgQghgjAAg9QAAg8AhgjQAigjA7AAQA9AAAhAjQAhAjAAA8QAAA9ghAjQghAjg9AAQg7AAgigjgAgShHQgIACgHAKQgHAIgEAPQgDAPAAAWQgBAWAEAPQAEANAFAIQAHAJAJAEQAJAEAKAAQAKAAAJgEQAKgEAGgIQAGgJAEgNQADgOAAgXQAAgWgDgOQgEgOgGgKQgHgJgJgDQgIgEgLAAQgJAAgJAEg");
    this.shape_10.setTransform(-87.7, 5.5);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#FFFFFF").s().p("AgaB6QgZgHgTgQQgSgRgKgYQgLgYAAghQAAgjALgYQAMgZATgQQATgPAYgIQAYgHAbAAQAVAAAUAFQAVAFAUAJIAABDIgKAAIgLgKQgGgFgJgEQgIgGgLgCQgKgEgNAAQgdABgPAUQgRAVABAhQgBAlASATQAQARAdABQAOAAALgDQALgEAIgEQAHgFAGgFIAJgJIAKAAIAABCIgPAHQgJADgIACIgWAGQgJABgRAAQgdAAgagIg");
    this.shape_11.setTransform(-113.25, 5.5);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#FFFFFF").s().p("AglCrIAAlVIBLAAIAAFVg");
    this.shape_12.setTransform(-132.15, 0.75);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#FFFFFF").s().p("AhUBhQgmghAAg+QAAg8AjgjQAjgkA9AAQA5AAAcAeQAdAeAAA6IAAAaIioAAQABASAHALQAGALAKAHQAKAHAMADQANADAQAAQANAAANgDQANgDALgFQAJgEAIgFIANgJIAIAAIAABAIgRAHQgIAEgNADQgNADgNACQgNACgTAAQhFAAglgigAgfhCQgNANgBAXIBcAAQgBgZgKgNQgLgMgWAAQgVAAgNAOg");
    this.shape_13.setTransform(-152.225, 5.45);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#FFFFFF").s().p("AA0CjIg0jJIgzDJIhaAAIhTlGIBWAAIAtDbIA3jbIBQAAIA0DbIAwjbIBTAAIhTFGg");
    this.shape_14.setTransform(-188.775, 1.5);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-396.6, -29.1, 793.2, 58.3);


  (lib.Tween99 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("AigCUQhAg7AAhZQAAhYBAg/QBDg8BdAAQBeAABAA8QBDA/AABYQAABZhDA7QhABAheAAQhdAAhDhAg");

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-22.5, -21.2, 45, 42.4);


  (lib.Tween98 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("AigCUQhAg7AAhZQAAhYBAg/QBDg8BdAAQBeAABAA8QBDA/AABYQAABZhDA7QhABAheAAQhdAAhDhAg");

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-22.5, -21.2, 45, 42.4);


  (lib.Tween95 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgDIAAgZIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgCIgKgDIgMgDQgPgFgHgHQgHgKAAgNQAAgRAOgMQANgLAVABQALgBALADQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAEQgHAFAAAKQAAAIAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAHgHAGQgHAGgJADQgJACgMAAQgNAAgLgDg");
    this.shape.setTransform(112.925, 18.45);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#E8A014").s().p("AgZBBQgIgDgGgGQgFgGgEgKQgDgJAAgNIAAhUIAXAAIAABJIABASQAAAHADAFQADAFAEACQAFACAIAAQAIAAAIgEQAKgFAHgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgHgCg");
    this.shape_1.setTransform(100.65, 18.625);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#E8A014").s().p("AgqAyQgQgSAAggQAAgfARgTQAQgSAaAAQAaAAAQASQAPASAAAgQAAAhgPASQgQASgaAAQgbAAgQgTgAgZglQgJAMAAAZQAAAZAJANQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgZgJgMQgKgMgQAAQgRAAgJAMg");
    this.shape_2.setTransform(79.95, 18.45);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#E8A014").s().p("AgNBLQgKgKAAgWIAAhHIgPAAIAAgTIAPAAIAAglIAWAAIAAAlIAoAAIAAATIgoAAIAAA9IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNACIgMACQgRAAgKgLg");
    this.shape_3.setTransform(69.525, 16.7);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#E8A014").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgGADgGAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgEgEgGgDQgHgDgJAAQgHAAgGADg");
    this.shape_4.setTransform(51.6, 18.425);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#E8A014").s().p("AAeBDIAAhJIgBgRQgCgIgCgFQgDgEgFgDQgEgCgIAAQgIAAgJAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQALgJAJgFQAJgEAKAAQATAAAMAMQALANgBAYIAABUg");
    this.shape_5.setTransform(38.6, 18.275);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_6.setTransform(29.1, 16.225);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#E8A014").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_7.setTransform(23.6, 15.875);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#E8A014").s().p("AgNBLQgKgKAAgWIAAhHIgPAAIAAgTIAPAAIAAglIAWAAIAAAlIAoAAIAAATIgoAAIAAA9IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNACIgMACQgRAAgKgLg");
    this.shape_8.setTransform(9.425, 16.7);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#E8A014").s().p("AgNBBQgLgFgIgJQgIgIgEgMQgFgOAAgRQAAgQAFgNQAEgMAIgIQAIgJALgFQAMgEAMAAQALAAAKACQAKADAIAEIAAAaIgBAAIgGgFIgJgFQgFgDgGgCQgGgBgGAAQgQgBgLANQgKANAAAXQAAAYAKANQAKANARgBQALAAAJgEQAKgEAIgIIABAAIAAAZIgIAEIgJADIgKACIgMABQgMAAgMgDg");
    this.shape_9.setTransform(-0.075, 18.45);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#E8A014").s().p("AgmAzQgSgRAAghQAAgfARgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQADALABAQIAAALIhaAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgGgDgJAAQgIAAgGADg");
    this.shape_10.setTransform(-12.05, 18.425);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#E8A014").s().p("AgiBCIAAiDIAWAAIAAAUQAMgKAIgFQAJgEAJgBIAFABIAEAAIAAAYIgCAAIgFgBIgGgBQgJAAgJAEQgIAEgIAHIAABdg");
    this.shape_11.setTransform(-21.975, 18.45);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_12.setTransform(-29.8, 16.225);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#E8A014").s().p("AgZBZQgJgFgGgJQgHgIgDgMQgEgOAAgQQAAgRAFgMQAEgMAIgJQAHgKAKgEQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC2IgWAAIAAgOIgJAHIgJAGIgJADIgLABQgLAAgJgEgAgVgLQgKAMAAAYQAAAWAHANQAIAMAQAAQAIAAAIgEQAJgDAIgIIAAhKQgIgEgHgCQgGgBgIAAQgQAAgJANg");
    this.shape_13.setTransform(-39.625, 16.05);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#E8A014").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQAMgFAOgCIAggDIAAgEQAAgIgDgFQgBgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgIAAgHgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGANAAQAJAAAIgFQAJgEAHgHIAAgjIgTACg");
    this.shape_14.setTransform(-59.95, 18.475);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics.f("#E8A014").s().p("AAeBbIAAhKIgBgRQgBgHgDgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgVAAIAAi1IAVAAIAABCQAKgJAKgFQAJgEAKAAQAUAAAKAMQALANAAAXIAABVg");
    this.shape_15.setTransform(-80, 15.875);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics.f("#E8A014").s().p("AgNBLQgKgKAAgWIAAhHIgPAAIAAgTIAPAAIAAglIAWAAIAAAlIAoAAIAAATIgoAAIAAA9IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNACIgMACQgRAAgKgLg");
    this.shape_16.setTransform(-90.675, 16.7);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_17.setTransform(-97.55, 16.225);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics.f("#E8A014").s().p("AAgBCIgghkIgeBkIgVAAIghiDIAWAAIAYBlIAehlIASAAIAgBlIAVhlIAXAAIgiCDg");
    this.shape_18.setTransform(-109.2, 18.45);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics.f("#E8A014").s().p("AAgBCIgghkIgfBkIgUAAIgiiDIAYAAIAWBlIAghlIARAAIAfBlIAXhlIAVAAIggCDg");
    this.shape_19.setTransform(235.65, -12.5);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics.f("#E8A014").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZglQgKAMAAAZQAAAZAKANQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgZgKgMQgJgMgRAAQgQAAgJAMg");
    this.shape_20.setTransform(220.2, -12.5);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics.f("#E8A014").s().p("AgZBZQgJgFgGgJQgHgIgDgNQgEgNAAgQQAAgRAFgMQAEgNAIgJQAHgJAKgEQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC2IgWAAIAAgOIgJAHIgJAGIgJADIgLABQgLAAgJgEgAgVgLQgKALAAAZQAAAWAHANQAIAMAQAAQAIAAAIgDQAJgFAIgHIAAhKQgIgEgHgCQgGgBgIAAQgQAAgJANg");
    this.shape_21.setTransform(206.625, -14.9);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics.f("#E8A014").s().p("AAdBDIAAhJIgBgRQgBgIgCgFQgDgEgEgDQgFgCgJAAQgGAAgKAEQgIAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQAUAAAKAMQAMANAAAYIAABUg");
    this.shape_22.setTransform(193.8, -12.675);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_23.setTransform(184.3, -14.725);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics.f("#E8A014").s().p("AAgBCIgghkIgfBkIgUAAIgiiDIAYAAIAWBlIAghlIARAAIAfBlIAXhlIAVAAIggCDg");
    this.shape_24.setTransform(172.65, -12.5);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics.f("#E8A014").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAGgKQAGgIAMgGQAKgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgLgBQgJAAgJACQgLACgKAFIgCAAIAAgXIASgEQALgCAMAAQAMAAAJACQAJACAIAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgFACgKAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_25.setTransform(149.6, -12.475);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgIQgHgKAAgNQAAgRAOgMQANgLAVAAQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAFQgHAFgJAEQgJACgMAAQgNABgLgEg");
    this.shape_26.setTransform(131.025, -12.5);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics.f("#E8A014").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_27.setTransform(118.85, -12.675);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics.f("#E8A014").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQAEALgBAQIAAALIhZAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgGAFQgFAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgEgEgGgDQgFgDgKAAQgHAAgGADg");
    this.shape_28.setTransform(105.8, -12.525);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics.f("#E8A014").s().p("Ag2BcIAAizIAWAAIAAAOQAIgIALgFQAJgEALgBQAXAAAMASQANASAAAeQAAAggQATQgPATgYABQgJAAgHgDQgIgCgIgEIAAA3gAgPhCQgJAFgIAGIAABKIAPAFQAGACAIAAQAQAAAKgNQAJgMAAgYQAAgWgHgMQgHgMgQAAQgIAAgJADg");
    this.shape_29.setTransform(93.275, -10.25);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics.f("#E8A014").s().p("AgpAyQgQgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgTgAgZglQgKAMABAZQgBAZAKANQAJAMARAAQARAAAIgMQAKgNAAgZQAAgZgKgMQgIgMgRAAQgRAAgJAMg");
    this.shape_30.setTransform(79.65, -12.5);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics.f("#E8A014").s().p("AgWAhIAShBIAbAAIgdBBg");
    this.shape_31.setTransform(64.075, -20.925);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgIQgHgKAAgNQAAgRAOgMQANgLAVAAQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAFQgHAFgJAEQgJACgMAAQgNABgLgEg");
    this.shape_32.setTransform(55.425, -12.5);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics.f("#E8A014").s().p("AgZBBQgIgDgFgGQgHgGgCgKQgEgJAAgNIAAhUIAXAAIAABJIABASQAAAHADAFQADAFAEACQAFACAIAAQAIAAAIgEQAKgFAHgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgHgCg");
    this.shape_33.setTransform(43.15, -12.325);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics.f("#E8A014").s().p("AgmAzQgRgRgBghQABgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALABAQIAAALIhaAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_34.setTransform(22.7, -12.525);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics.f("#E8A014").s().p("AgXBaIgSgDIAAgYIABAAIAGADIAKADIAMACIALABQAJAAAHgCQAHgDAEgFQAEgFABgGQACgGAAgJIAAgLQgJAIgJADQgIAFgMAAQgWAAgOgRQgNgRAAgeQAAgQAFgNQAEgMAIgJQAHgJALgFQAKgEAJAAQALAAAHACIAPAHIABgGIAVAAIAABzQAAAigPAQQgOAPgdAAIgUgCgAgVg7QgKANAAAWQAAAWAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgCIgOgBQgQAAgJAMg");
    this.shape_35.setTransform(9.275, -10.2);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics.f("#E8A014").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAGgKQAFgIANgGQALgFAPgCIAfgDIAAgEQAAgIgDgFQgCgEgEgDQgEgDgGAAIgLgBQgJAAgJACQgLACgKAFIgCAAIAAgXIASgEQAMgCALAAQAMAAAJACQAKACAHAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgFACgJAAQgJAAgHgDgAAKAAQgLAAgHACQgJADgGAFQgFAGAAAKQAAALAGAGQAGAGANAAQAJAAAIgFQAIgEAIgHIAAgjIgUACg");
    this.shape_36.setTransform(-3.55, -12.475);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgIQgHgKAAgNQAAgRAOgMQANgLAVAAQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAFQgHAFgJAEQgJACgMAAQgNABgLgEg");
    this.shape_37.setTransform(-14.625, -12.5);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgIQgHgKAAgNQAAgRAOgMQANgLAVAAQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAFQgHAFgJAEQgJACgMAAQgNABgLgEg");
    this.shape_38.setTransform(-25.325, -12.5);

    this.shape_39 = new cjs.Shape();
    this.shape_39.graphics.f("#E8A014").s().p("AgmAzQgRgRgBghQABgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_39.setTransform(-37.15, -12.525);

    this.shape_40 = new cjs.Shape();
    this.shape_40.graphics.f("#E8A014").s().p("AA0BXIAAiVIguBlIgNAAIgthlIAACVIgWAAIAAitIAgAAIArBgIAqhgIAgAAIAACtg");
    this.shape_40.setTransform(-52.775, -14.675);

    this.shape_41 = new cjs.Shape();
    this.shape_41.graphics.f("#E8A014").s().p("AAHAhIgdhBIAbAAIATBBg");
    this.shape_41.setTransform(-65.45, -20.925);

    this.shape_42 = new cjs.Shape();
    this.shape_42.graphics.f("#E8A014").s().p("AgXBaIgSgDIAAgYIABAAIAGADIAKADIAMACIALABQAJAAAHgCQAHgDAEgFQAEgFABgGQACgGAAgJIAAgLQgJAIgJADQgIAFgMAAQgWAAgOgRQgNgRAAgeQAAgQAFgNQAEgMAIgJQAHgJALgFQAKgEAJAAQALAAAHACIAPAHIABgGIAVAAIAABzQAAAigPAQQgOAPgdAAIgUgCgAgVg7QgKANAAAWQAAAWAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgCIgOgBQgQAAgJAMg");
    this.shape_42.setTransform(-81.625, -10.2);

    this.shape_43 = new cjs.Shape();
    this.shape_43.graphics.f("#E8A014").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_43.setTransform(-94.45, -12.675);

    this.shape_44 = new cjs.Shape();
    this.shape_44.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_44.setTransform(-103.95, -14.725);

    this.shape_45 = new cjs.Shape();
    this.shape_45.graphics.f("#E8A014").s().p("AAZBbIgwg/IgIAJIAAA2IgWAAIAAi1IAWAAIAABqIAzg3IAbAAIg1A2IA8BMg");
    this.shape_45.setTransform(-111.625, -15.075);

    this.shape_46 = new cjs.Shape();
    this.shape_46.graphics.f("#E8A014").s().p("AgNBBQgLgFgIgJQgIgIgEgNQgFgNAAgRQAAgQAFgNQAEgMAIgJQAIgIALgFQAMgEAMgBQALAAAKADQAKAEAIADIAAAaIgBAAIgGgFIgJgFQgFgDgGgCQgGgCgGAAQgQABgLANQgKAMAAAXQAAAYAKANQAKANARgBQALAAAJgEQAKgEAIgIIABAAIAAAZIgIAEIgJADIgKACIgMABQgMAAgMgDg");
    this.shape_46.setTransform(-124.025, -12.5);

    this.shape_47 = new cjs.Shape();
    this.shape_47.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_47.setTransform(-132.45, -14.725);

    this.shape_48 = new cjs.Shape();
    this.shape_48.graphics.f("#E8A014").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_48.setTransform(-137.95, -15.075);

    this.shape_49 = new cjs.Shape();
    this.shape_49.graphics.f("#E8A014").s().p("AgSBVQgPgGgKgLQgLgMgFgRQgGgRAAgWQAAgVAGgQQAFgQALgMQAKgMAPgGQAOgGARAAIAQABIAOADIAMAFIALAFIAAAcIgBAAIgIgGIgMgIQgHgEgIgCQgHgDgLAAQgLAAgJAFQgKAFgHAIQgIAJgEAOQgEANAAAQQAAASAEANQAFANAHAJQAIAJAJAEQAJAEALAAQAKAAAJgCIAPgGQAHgEAEgEIAIgGIABAAIAAAbIgKAGIgNAEIgOAEIgRABQgQAAgOgFg");
    this.shape_49.setTransform(-147.725, -14.675);

    this.shape_50 = new cjs.Shape();
    this.shape_50.graphics.f("#E8A014").s().p("AgUBCIAAguIApAAIAAAugAgUgTIAAguIApAAIAAAug");
    this.shape_50.setTransform(-166.925, -12.525);

    this.shape_51 = new cjs.Shape();
    this.shape_51.graphics.f("#E8A014").s().p("AhABcIAAizIAqAAIAAANQAJgHAJgFQAJgFAMAAQAWAAANASQAMASAAAgQAAAegPATQgQAUgYAAQgJAAgHgCIgPgHIAAA3gAgLg5QgGABgFAEIAABBIAHACIAJABQAOAAAHgKQAIgJAAgUQAAgTgGgIQgGgJgMAAQgFAAgFACg");
    this.shape_51.setTransform(-178.4, -10.275);

    this.shape_52 = new cjs.Shape();
    this.shape_52.graphics.f("#E8A014").s().p("AgUBbIAAiCIAoAAIAACCgAgVg6IAAggIArAAIAAAgg");
    this.shape_52.setTransform(-190.025, -15.075);

    this.shape_53 = new cjs.Shape();
    this.shape_53.graphics.f("#E8A014").s().p("AgUBMQgLgLAAgXIAAg9IgQAAIAAgcIAQAAIAAgmIAnAAIAAAmIAoAAIAAAcIgoAAIAAAuIABAMQAAAFACAEQACAEADADQAFACAIAAIAJgBIAGgDIAEAAIAAAdIgOADIgTABQgYAAgLgKg");
    this.shape_53.setTransform(-198.55, -14.275);

    this.shape_54 = new cjs.Shape();
    this.shape_54.graphics.f("#E8A014").s().p("AgxAzQgSgTAAggQAAggASgSQASgTAfAAQAhAAARATQASASAAAgQAAAggRATQgTATggAAQgfAAgSgTgAgJgmQgEACgEAFQgDAEgDAIQgCAIAAALQAAAMACAIQACAHADAFQAEAEAFACQAFACAEAAQAFAAAFgCQAGgCACgEQAEgFACgHQACgHAAgNQAAgKgCgIQgCgIgEgFQgDgFgFgCQgEgCgGAAQgEAAgFACg");
    this.shape_54.setTransform(-211, -12.525);

    this.shape_55 = new cjs.Shape();
    this.shape_55.graphics.f("#E8A014").s().p("AgrBCIAAiCIApAAIAAATIAHgHIAJgHIAKgEQAGgCAFAAIAFAAIAEABIAAAmIgDAAIgHgBIgKAAQgGAAgHABIgNAFIAABXg");
    this.shape_55.setTransform(-222.825, -12.55);

    this.shape_56 = new cjs.Shape();
    this.shape_56.graphics.f("#E8A014").s().p("AhCBXIAAitIBDAAQAPAAALADQALACAIAGQAKAHAGALQAFALAAAPQAAAMgEALQgEAKgHAHQgEAFgGAEQgFAEgGACQgHADgHACQgIABgJAAIgWAAIAAA5gAgWgBIADAAIALgBIAJAAIAIgDIAFgDQAFgFACgEQABgFAAgIQAAgHgDgGQgEgFgFgCQgGgDgFAAIgPgBIgGAAg");
    this.shape_56.setTransform(-236.025, -14.675);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_56
      }, {
        t: this.shape_55
      }, {
        t: this.shape_54
      }, {
        t: this.shape_53
      }, {
        t: this.shape_52
      }, {
        t: this.shape_51
      }, {
        t: this.shape_50
      }, {
        t: this.shape_49
      }, {
        t: this.shape_48
      }, {
        t: this.shape_47
      }, {
        t: this.shape_46
      }, {
        t: this.shape_45
      }, {
        t: this.shape_44
      }, {
        t: this.shape_43
      }, {
        t: this.shape_42
      }, {
        t: this.shape_41
      }, {
        t: this.shape_40
      }, {
        t: this.shape_39
      }, {
        t: this.shape_38
      }, {
        t: this.shape_37
      }, {
        t: this.shape_36
      }, {
        t: this.shape_35
      }, {
        t: this.shape_34
      }, {
        t: this.shape_33
      }, {
        t: this.shape_32
      }, {
        t: this.shape_31
      }, {
        t: this.shape_30
      }, {
        t: this.shape_29
      }, {
        t: this.shape_28
      }, {
        t: this.shape_27
      }, {
        t: this.shape_26
      }, {
        t: this.shape_25
      }, {
        t: this.shape_24
      }, {
        t: this.shape_23
      }, {
        t: this.shape_22
      }, {
        t: this.shape_21
      }, {
        t: this.shape_20
      }, {
        t: this.shape_19
      }, {
        t: this.shape_18
      }, {
        t: this.shape_17
      }, {
        t: this.shape_16
      }, {
        t: this.shape_15
      }, {
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400.6, -31.9, 801.2, 63.9);


  (lib.Tween94 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgDIAAgZIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgCIgKgDIgMgDQgPgFgHgHQgHgKAAgNQAAgRAOgMQANgLAVABQALgBALADQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAEQgHAFAAAKQAAAIAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAHgHAGQgHAGgJADQgJACgMAAQgNAAgLgDg");
    this.shape.setTransform(112.925, 18.45);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#E8A014").s().p("AgZBBQgIgDgGgGQgFgGgEgKQgDgJAAgNIAAhUIAXAAIAABJIABASQAAAHADAFQADAFAEACQAFACAIAAQAIAAAIgEQAKgFAHgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgHgCg");
    this.shape_1.setTransform(100.65, 18.625);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#E8A014").s().p("AgqAyQgQgSAAggQAAgfARgTQAQgSAaAAQAaAAAQASQAPASAAAgQAAAhgPASQgQASgaAAQgbAAgQgTgAgZglQgJAMAAAZQAAAZAJANQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgZgJgMQgKgMgQAAQgRAAgJAMg");
    this.shape_2.setTransform(79.95, 18.45);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#E8A014").s().p("AgNBLQgKgKAAgWIAAhHIgPAAIAAgTIAPAAIAAglIAWAAIAAAlIAoAAIAAATIgoAAIAAA9IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNACIgMACQgRAAgKgLg");
    this.shape_3.setTransform(69.525, 16.7);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#E8A014").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgGADgGAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgEgEgGgDQgHgDgJAAQgHAAgGADg");
    this.shape_4.setTransform(51.6, 18.425);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#E8A014").s().p("AAeBDIAAhJIgBgRQgCgIgCgFQgDgEgFgDQgEgCgIAAQgIAAgJAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQALgJAJgFQAJgEAKAAQATAAAMAMQALANgBAYIAABUg");
    this.shape_5.setTransform(38.6, 18.275);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_6.setTransform(29.1, 16.225);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#E8A014").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_7.setTransform(23.6, 15.875);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#E8A014").s().p("AgNBLQgKgKAAgWIAAhHIgPAAIAAgTIAPAAIAAglIAWAAIAAAlIAoAAIAAATIgoAAIAAA9IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNACIgMACQgRAAgKgLg");
    this.shape_8.setTransform(9.425, 16.7);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#E8A014").s().p("AgNBBQgLgFgIgJQgIgIgEgMQgFgOAAgRQAAgQAFgNQAEgMAIgIQAIgJALgFQAMgEAMAAQALAAAKACQAKADAIAEIAAAaIgBAAIgGgFIgJgFQgFgDgGgCQgGgBgGAAQgQgBgLANQgKANAAAXQAAAYAKANQAKANARgBQALAAAJgEQAKgEAIgIIABAAIAAAZIgIAEIgJADIgKACIgMABQgMAAgMgDg");
    this.shape_9.setTransform(-0.075, 18.45);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#E8A014").s().p("AgmAzQgSgRAAghQAAgfARgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQADALABAQIAAALIhaAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgGgDgJAAQgIAAgGADg");
    this.shape_10.setTransform(-12.05, 18.425);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#E8A014").s().p("AgiBCIAAiDIAWAAIAAAUQAMgKAIgFQAJgEAJgBIAFABIAEAAIAAAYIgCAAIgFgBIgGgBQgJAAgJAEQgIAEgIAHIAABdg");
    this.shape_11.setTransform(-21.975, 18.45);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_12.setTransform(-29.8, 16.225);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#E8A014").s().p("AgZBZQgJgFgGgJQgHgIgDgMQgEgOAAgQQAAgRAFgMQAEgMAIgJQAHgKAKgEQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC2IgWAAIAAgOIgJAHIgJAGIgJADIgLABQgLAAgJgEgAgVgLQgKAMAAAYQAAAWAHANQAIAMAQAAQAIAAAIgEQAJgDAIgIIAAhKQgIgEgHgCQgGgBgIAAQgQAAgJANg");
    this.shape_13.setTransform(-39.625, 16.05);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#E8A014").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQAMgFAOgCIAggDIAAgEQAAgIgDgFQgBgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgIAAgHgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGANAAQAJAAAIgFQAJgEAHgHIAAgjIgTACg");
    this.shape_14.setTransform(-59.95, 18.475);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics.f("#E8A014").s().p("AAeBbIAAhKIgBgRQgBgHgDgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgVAAIAAi1IAVAAIAABCQAKgJAKgFQAJgEAKAAQAUAAAKAMQALANAAAXIAABVg");
    this.shape_15.setTransform(-80, 15.875);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics.f("#E8A014").s().p("AgNBLQgKgKAAgWIAAhHIgPAAIAAgTIAPAAIAAglIAWAAIAAAlIAoAAIAAATIgoAAIAAA9IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNACIgMACQgRAAgKgLg");
    this.shape_16.setTransform(-90.675, 16.7);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_17.setTransform(-97.55, 16.225);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics.f("#E8A014").s().p("AAgBCIgghkIgeBkIgVAAIghiDIAWAAIAYBlIAehlIASAAIAgBlIAVhlIAXAAIgiCDg");
    this.shape_18.setTransform(-109.2, 18.45);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics.f("#E8A014").s().p("AAgBCIgghkIgfBkIgUAAIgiiDIAYAAIAWBlIAghlIARAAIAfBlIAXhlIAVAAIggCDg");
    this.shape_19.setTransform(235.65, -12.5);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics.f("#E8A014").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZglQgKAMAAAZQAAAZAKANQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgZgKgMQgJgMgRAAQgQAAgJAMg");
    this.shape_20.setTransform(220.2, -12.5);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics.f("#E8A014").s().p("AgZBZQgJgFgGgJQgHgIgDgNQgEgNAAgQQAAgRAFgMQAEgNAIgJQAHgJAKgEQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC2IgWAAIAAgOIgJAHIgJAGIgJADIgLABQgLAAgJgEgAgVgLQgKALAAAZQAAAWAHANQAIAMAQAAQAIAAAIgDQAJgFAIgHIAAhKQgIgEgHgCQgGgBgIAAQgQAAgJANg");
    this.shape_21.setTransform(206.625, -14.9);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics.f("#E8A014").s().p("AAdBDIAAhJIgBgRQgBgIgCgFQgDgEgEgDQgFgCgJAAQgGAAgKAEQgIAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQAUAAAKAMQAMANAAAYIAABUg");
    this.shape_22.setTransform(193.8, -12.675);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_23.setTransform(184.3, -14.725);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics.f("#E8A014").s().p("AAgBCIgghkIgfBkIgUAAIgiiDIAYAAIAWBlIAghlIARAAIAfBlIAXhlIAVAAIggCDg");
    this.shape_24.setTransform(172.65, -12.5);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics.f("#E8A014").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAGgKQAGgIAMgGQAKgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgLgBQgJAAgJACQgLACgKAFIgCAAIAAgXIASgEQALgCAMAAQAMAAAJACQAJACAIAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgFACgKAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_25.setTransform(149.6, -12.475);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgIQgHgKAAgNQAAgRAOgMQANgLAVAAQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAFQgHAFgJAEQgJACgMAAQgNABgLgEg");
    this.shape_26.setTransform(131.025, -12.5);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics.f("#E8A014").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_27.setTransform(118.85, -12.675);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics.f("#E8A014").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQAEALgBAQIAAALIhZAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgGAFQgFAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgEgEgGgDQgFgDgKAAQgHAAgGADg");
    this.shape_28.setTransform(105.8, -12.525);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics.f("#E8A014").s().p("Ag2BcIAAizIAWAAIAAAOQAIgIALgFQAJgEALgBQAXAAAMASQANASAAAeQAAAggQATQgPATgYABQgJAAgHgDQgIgCgIgEIAAA3gAgPhCQgJAFgIAGIAABKIAPAFQAGACAIAAQAQAAAKgNQAJgMAAgYQAAgWgHgMQgHgMgQAAQgIAAgJADg");
    this.shape_29.setTransform(93.275, -10.25);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics.f("#E8A014").s().p("AgpAyQgQgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgTgAgZglQgKAMABAZQgBAZAKANQAJAMARAAQARAAAIgMQAKgNAAgZQAAgZgKgMQgIgMgRAAQgRAAgJAMg");
    this.shape_30.setTransform(79.65, -12.5);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics.f("#E8A014").s().p("AgWAhIAShBIAbAAIgdBBg");
    this.shape_31.setTransform(64.075, -20.925);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgIQgHgKAAgNQAAgRAOgMQANgLAVAAQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAFQgHAFgJAEQgJACgMAAQgNABgLgEg");
    this.shape_32.setTransform(55.425, -12.5);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics.f("#E8A014").s().p("AgZBBQgIgDgFgGQgHgGgCgKQgEgJAAgNIAAhUIAXAAIAABJIABASQAAAHADAFQADAFAEACQAFACAIAAQAIAAAIgEQAKgFAHgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgHgCg");
    this.shape_33.setTransform(43.15, -12.325);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics.f("#E8A014").s().p("AgmAzQgRgRgBghQABgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALABAQIAAALIhaAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_34.setTransform(22.7, -12.525);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics.f("#E8A014").s().p("AgXBaIgSgDIAAgYIABAAIAGADIAKADIAMACIALABQAJAAAHgCQAHgDAEgFQAEgFABgGQACgGAAgJIAAgLQgJAIgJADQgIAFgMAAQgWAAgOgRQgNgRAAgeQAAgQAFgNQAEgMAIgJQAHgJALgFQAKgEAJAAQALAAAHACIAPAHIABgGIAVAAIAABzQAAAigPAQQgOAPgdAAIgUgCgAgVg7QgKANAAAWQAAAWAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgCIgOgBQgQAAgJAMg");
    this.shape_35.setTransform(9.275, -10.2);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics.f("#E8A014").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAGgKQAFgIANgGQALgFAPgCIAfgDIAAgEQAAgIgDgFQgCgEgEgDQgEgDgGAAIgLgBQgJAAgJACQgLACgKAFIgCAAIAAgXIASgEQAMgCALAAQAMAAAJACQAKACAHAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgFACgJAAQgJAAgHgDgAAKAAQgLAAgHACQgJADgGAFQgFAGAAAKQAAALAGAGQAGAGANAAQAJAAAIgFQAIgEAIgHIAAgjIgUACg");
    this.shape_36.setTransform(-3.55, -12.475);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgIQgHgKAAgNQAAgRAOgMQANgLAVAAQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAFQgHAFgJAEQgJACgMAAQgNABgLgEg");
    this.shape_37.setTransform(-14.625, -12.5);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics.f("#E8A014").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAFIAMAFQAHACAHAAIAKgBQAGgCADgBQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgIQgHgKAAgNQAAgRAOgMQANgLAVAAQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANADQAPADAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAFQgHAFgJAEQgJACgMAAQgNABgLgEg");
    this.shape_38.setTransform(-25.325, -12.5);

    this.shape_39 = new cjs.Shape();
    this.shape_39.graphics.f("#E8A014").s().p("AgmAzQgRgRgBghQABgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_39.setTransform(-37.15, -12.525);

    this.shape_40 = new cjs.Shape();
    this.shape_40.graphics.f("#E8A014").s().p("AA0BXIAAiVIguBlIgNAAIgthlIAACVIgWAAIAAitIAgAAIArBgIAqhgIAgAAIAACtg");
    this.shape_40.setTransform(-52.775, -14.675);

    this.shape_41 = new cjs.Shape();
    this.shape_41.graphics.f("#E8A014").s().p("AAHAhIgdhBIAbAAIATBBg");
    this.shape_41.setTransform(-65.45, -20.925);

    this.shape_42 = new cjs.Shape();
    this.shape_42.graphics.f("#E8A014").s().p("AgXBaIgSgDIAAgYIABAAIAGADIAKADIAMACIALABQAJAAAHgCQAHgDAEgFQAEgFABgGQACgGAAgJIAAgLQgJAIgJADQgIAFgMAAQgWAAgOgRQgNgRAAgeQAAgQAFgNQAEgMAIgJQAHgJALgFQAKgEAJAAQALAAAHACIAPAHIABgGIAVAAIAABzQAAAigPAQQgOAPgdAAIgUgCgAgVg7QgKANAAAWQAAAWAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgCIgOgBQgQAAgJAMg");
    this.shape_42.setTransform(-81.625, -10.2);

    this.shape_43 = new cjs.Shape();
    this.shape_43.graphics.f("#E8A014").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_43.setTransform(-94.45, -12.675);

    this.shape_44 = new cjs.Shape();
    this.shape_44.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_44.setTransform(-103.95, -14.725);

    this.shape_45 = new cjs.Shape();
    this.shape_45.graphics.f("#E8A014").s().p("AAZBbIgwg/IgIAJIAAA2IgWAAIAAi1IAWAAIAABqIAzg3IAbAAIg1A2IA8BMg");
    this.shape_45.setTransform(-111.625, -15.075);

    this.shape_46 = new cjs.Shape();
    this.shape_46.graphics.f("#E8A014").s().p("AgNBBQgLgFgIgJQgIgIgEgNQgFgNAAgRQAAgQAFgNQAEgMAIgJQAIgIALgFQAMgEAMgBQALAAAKADQAKAEAIADIAAAaIgBAAIgGgFIgJgFQgFgDgGgCQgGgCgGAAQgQABgLANQgKAMAAAXQAAAYAKANQAKANARgBQALAAAJgEQAKgEAIgIIABAAIAAAZIgIAEIgJADIgKACIgMABQgMAAgMgDg");
    this.shape_46.setTransform(-124.025, -12.5);

    this.shape_47 = new cjs.Shape();
    this.shape_47.graphics.f("#E8A014").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_47.setTransform(-132.45, -14.725);

    this.shape_48 = new cjs.Shape();
    this.shape_48.graphics.f("#E8A014").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_48.setTransform(-137.95, -15.075);

    this.shape_49 = new cjs.Shape();
    this.shape_49.graphics.f("#E8A014").s().p("AgSBVQgPgGgKgLQgLgMgFgRQgGgRAAgWQAAgVAGgQQAFgQALgMQAKgMAPgGQAOgGARAAIAQABIAOADIAMAFIALAFIAAAcIgBAAIgIgGIgMgIQgHgEgIgCQgHgDgLAAQgLAAgJAFQgKAFgHAIQgIAJgEAOQgEANAAAQQAAASAEANQAFANAHAJQAIAJAJAEQAJAEALAAQAKAAAJgCIAPgGQAHgEAEgEIAIgGIABAAIAAAbIgKAGIgNAEIgOAEIgRABQgQAAgOgFg");
    this.shape_49.setTransform(-147.725, -14.675);

    this.shape_50 = new cjs.Shape();
    this.shape_50.graphics.f("#E8A014").s().p("AgUBCIAAguIApAAIAAAugAgUgTIAAguIApAAIAAAug");
    this.shape_50.setTransform(-166.925, -12.525);

    this.shape_51 = new cjs.Shape();
    this.shape_51.graphics.f("#E8A014").s().p("AhABcIAAizIAqAAIAAANQAJgHAJgFQAJgFAMAAQAWAAANASQAMASAAAgQAAAegPATQgQAUgYAAQgJAAgHgCIgPgHIAAA3gAgLg5QgGABgFAEIAABBIAHACIAJABQAOAAAHgKQAIgJAAgUQAAgTgGgIQgGgJgMAAQgFAAgFACg");
    this.shape_51.setTransform(-178.4, -10.275);

    this.shape_52 = new cjs.Shape();
    this.shape_52.graphics.f("#E8A014").s().p("AgUBbIAAiCIAoAAIAACCgAgVg6IAAggIArAAIAAAgg");
    this.shape_52.setTransform(-190.025, -15.075);

    this.shape_53 = new cjs.Shape();
    this.shape_53.graphics.f("#E8A014").s().p("AgUBMQgLgLAAgXIAAg9IgQAAIAAgcIAQAAIAAgmIAnAAIAAAmIAoAAIAAAcIgoAAIAAAuIABAMQAAAFACAEQACAEADADQAFACAIAAIAJgBIAGgDIAEAAIAAAdIgOADIgTABQgYAAgLgKg");
    this.shape_53.setTransform(-198.55, -14.275);

    this.shape_54 = new cjs.Shape();
    this.shape_54.graphics.f("#E8A014").s().p("AgxAzQgSgTAAggQAAggASgSQASgTAfAAQAhAAARATQASASAAAgQAAAggRATQgTATggAAQgfAAgSgTgAgJgmQgEACgEAFQgDAEgDAIQgCAIAAALQAAAMACAIQACAHADAFQAEAEAFACQAFACAEAAQAFAAAFgCQAGgCACgEQAEgFACgHQACgHAAgNQAAgKgCgIQgCgIgEgFQgDgFgFgCQgEgCgGAAQgEAAgFACg");
    this.shape_54.setTransform(-211, -12.525);

    this.shape_55 = new cjs.Shape();
    this.shape_55.graphics.f("#E8A014").s().p("AgrBCIAAiCIApAAIAAATIAHgHIAJgHIAKgEQAGgCAFAAIAFAAIAEABIAAAmIgDAAIgHgBIgKAAQgGAAgHABIgNAFIAABXg");
    this.shape_55.setTransform(-222.825, -12.55);

    this.shape_56 = new cjs.Shape();
    this.shape_56.graphics.f("#E8A014").s().p("AhCBXIAAitIBDAAQAPAAALADQALACAIAGQAKAHAGALQAFALAAAPQAAAMgEALQgEAKgHAHQgEAFgGAEQgFAEgGACQgHADgHACQgIABgJAAIgWAAIAAA5gAgWgBIADAAIALgBIAJAAIAIgDIAFgDQAFgFACgEQABgFAAgIQAAgHgDgGQgEgFgFgCQgGgDgFAAIgPgBIgGAAg");
    this.shape_56.setTransform(-236.025, -14.675);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_56
      }, {
        t: this.shape_55
      }, {
        t: this.shape_54
      }, {
        t: this.shape_53
      }, {
        t: this.shape_52
      }, {
        t: this.shape_51
      }, {
        t: this.shape_50
      }, {
        t: this.shape_49
      }, {
        t: this.shape_48
      }, {
        t: this.shape_47
      }, {
        t: this.shape_46
      }, {
        t: this.shape_45
      }, {
        t: this.shape_44
      }, {
        t: this.shape_43
      }, {
        t: this.shape_42
      }, {
        t: this.shape_41
      }, {
        t: this.shape_40
      }, {
        t: this.shape_39
      }, {
        t: this.shape_38
      }, {
        t: this.shape_37
      }, {
        t: this.shape_36
      }, {
        t: this.shape_35
      }, {
        t: this.shape_34
      }, {
        t: this.shape_33
      }, {
        t: this.shape_32
      }, {
        t: this.shape_31
      }, {
        t: this.shape_30
      }, {
        t: this.shape_29
      }, {
        t: this.shape_28
      }, {
        t: this.shape_27
      }, {
        t: this.shape_26
      }, {
        t: this.shape_25
      }, {
        t: this.shape_24
      }, {
        t: this.shape_23
      }, {
        t: this.shape_22
      }, {
        t: this.shape_21
      }, {
        t: this.shape_20
      }, {
        t: this.shape_19
      }, {
        t: this.shape_18
      }, {
        t: this.shape_17
      }, {
        t: this.shape_16
      }, {
        t: this.shape_15
      }, {
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400.6, -31.9, 801.2, 63.9);


  (lib.Tween93 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.chatinput();
    this.instance.setTransform(-171.4, -58.35, 0.5012, 0.5012);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-171.4, -58.3, 342.8, 128.8);


  (lib.Tween92 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.chatinput();
    this.instance.setTransform(-171.4, -58.35, 0.5012, 0.5012);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-171.4, -58.3, 342.8, 128.8);


  (lib.Tween91 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#000000").s().p("AgLBXIAAgYIAXAAIAAAYgAgJAnIgDh9IAZAAIgDB9g");
    this.shape.setTransform(240.625, 0.825);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#000000").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_1.setTransform(231.475, 3);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#000000").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_2.setTransform(219.3, 2.825);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#000000").s().p("AgpAyQgRgSABggQAAgfAQgTQAQgSAZAAQAbAAAPASQAQASABAgQgBAhgQASQgPASgbAAQgaAAgPgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMAQAAQASAAAIgMQAKgNAAgZQAAgYgKgMQgIgNgSAAQgQAAgJANg");
    this.shape_3.setTransform(206, 3);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#000000").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_4.setTransform(196.75, 0.775);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#000000").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_5.setTransform(190.075, 1.25);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#000000").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_6.setTransform(180.775, 3);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#000000").s().p("AgmAzQgRgRgBghQABgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_7.setTransform(168.95, 2.975);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#000000").s().p("AgaBBQgHgDgFgGQgHgGgCgKQgDgJgBgNIAAhUIAXAAIAABJIABASQAAAHADAFQACAFAFACQAEACAKAAQAGAAAKgEQAIgFAIgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgIgCg");
    this.shape_8.setTransform(155.85, 3.175);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#000000").s().p("AAhBbIAAg/QgKAKgJADQgJAFgKAAQgWAAgOgSQgNgSAAgfQAAgRAFgNQAEgNAIgIQAHgJAKgFQAKgFAKABQAKAAAIACQAHACAIAFIABgGIAVAAIAACygAgWg6QgJANAAAXQAAAWAHAMQAIANAQAAQAIgBAJgEQAJgDAHgHIAAhJQgIgFgHgBQgHgCgHABQgQAAgKAMg");
    this.shape_9.setTransform(142.125, 5.25);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#000000").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_10.setTransform(124.875, 3);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#000000").s().p("AgZBBQgIgDgGgGQgGgGgDgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAJAAQAHAAAJgEQAKgFAIgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgHgCg");
    this.shape_11.setTransform(113.05, 3.175);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#000000").s().p("AgqAyQgQgSAAggQABgfAQgTQAQgSAaAAQAaAAAQASQAPASAAAgQAAAhgPASQgQASgaAAQgbAAgQgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_12.setTransform(99.85, 3);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#000000").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_13.setTransform(87.375, 5.425);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#000000").s().p("AgXBaIgSgEIAAgXIABAAIAGADIAKADIAMACIALABQAJAAAHgDQAHgCAEgFQAEgFABgGQACgHAAgIIAAgLQgJAIgJADQgIAFgMAAQgWgBgOgQQgNgRAAgeQAAgQAFgNQAEgMAIgKQAHgIALgEQAKgFAJAAQALAAAHACIAPAHIABgFIAVAAIAAByQAAAigPAQQgOAPgdAAQgKAAgKgCgAgVg7QgKAMAAAYQAAAVAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgBQgHgCgHAAQgQAAgJAMg");
    this.shape_14.setTransform(66.825, 5.3);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics.f("#000000").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgGgDQgEgCgIAAQgIAAgJAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQAMANgBAYIAABUg");
    this.shape_15.setTransform(54, 2.825);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics.f("#000000").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAXAAIAAAXg");
    this.shape_16.setTransform(44.5, 0.775);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics.f("#000000").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_17.setTransform(38.175, 3);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics.f("#000000").s().p("AgmAzQgRgRgBghQAAgfARgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQAEALAAAQIAAALIhaAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgFAFQgFAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgEgFQgDgEgHgDQgGgDgIAAQgIAAgGADg");
    this.shape_18.setTransform(26.8, 2.975);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics.f("#000000").s().p("AAgBBIgghjIgfBjIgUAAIgiiBIAYAAIAWBkIAghkIARAAIAfBkIAXhkIAVAAIggCBg");
    this.shape_19.setTransform(11.55, 3);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics.f("#000000").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_20.setTransform(-2.525, 3);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics.f("#000000").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_21.setTransform(-14.7, 2.825);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics.f("#000000").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQAMgFAOgCIAggDIAAgEQAAgIgDgFQgCgEgEgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAJACAIAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgKAAQgJAAgHgDgAAKAAQgLAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGANAAQAJAAAIgFQAJgEAHgHIAAgjIgUACg");
    this.shape_22.setTransform(-28.1, 3.025);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics.f("#000000").s().p("AgpAyQgQgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQARASgBAgQABAhgRASQgPASgaAAQgbAAgPgTgAgZgkQgJAMAAAYQAAAYAJAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_23.setTransform(-48.05, 3);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics.f("#000000").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_24.setTransform(-58.475, 1.25);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics.f("#000000").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAHIgJAFIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAZQAAAXAHAMQAIAMAQAAQAIAAAIgDQAJgFAIgGIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_25.setTransform(-77.175, 0.6);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics.f("#000000").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_26.setTransform(-86.925, 3);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics.f("#000000").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQAMgFAOgCIAggDIAAgEQAAgIgDgFQgBgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgIAAgHgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGANAAQAJAAAIgFQAJgEAHgHIAAgjIgTACg");
    this.shape_27.setTransform(-98.65, 3.025);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics.f("#000000").s().p("AAgBBIgghjIgfBjIgUAAIgiiBIAXAAIAYBkIAehkIASAAIAgBkIAVhkIAWAAIggCBg");
    this.shape_28.setTransform(-113.45, 3);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics.f("#000000").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_29.setTransform(-125.925, 3);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics.f("#000000").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgYgKgMQgJgNgRAAQgQAAgJANg");
    this.shape_30.setTransform(-137.55, 3);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics.f("#000000").s().p("AgZBcIAAhwIgPAAIAAgSIAPAAIAAgEQAAgYALgMQAMgNAUAAIAMABIALABIAAAVIgCAAQgDgCgFAAIgKgCQgOAAgGAHQgFAGAAAQIAAAFIAkAAIAAASIgjAAIAABwg");
    this.shape_31.setTransform(-147.275, 0.375);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics.f("#000000").s().p("AAZBbIgwg/IgIAJIAAA2IgWAAIAAi1IAWAAIAABqIAzg3IAbAAIg1A2IA8BMg");
    this.shape_32.setTransform(-164.125, 0.425);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics.f("#000000").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgYgKgMQgJgNgRAAQgQAAgJANg");
    this.shape_33.setTransform(-177.7, 3);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics.f("#000000").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgYgKgMQgJgNgRAAQgQAAgJANg");
    this.shape_34.setTransform(-190.75, 3);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics.f("#000000").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_35.setTransform(-200, 0.425);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics.f("#000000").s().p("AgmAzQgSgRAAghQAAgfARgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQAEALAAAQIAAALIhaAAQAAAWAMANQALAMATAAQAHAAAHgCIANgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgHADgEAFQgFAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgEgFQgDgEgHgDQgGgDgIAAQgIAAgGADg");
    this.shape_36.setTransform(-216.55, 2.975);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics.f("#000000").s().p("AAjBXIgjiQIgiCQIgaAAIgsitIAYAAIAjCQIAjiQIAWAAIAjCRIAjiRIAXAAIgsCtg");
    this.shape_37.setTransform(-233.725, 0.825);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_37
      }, {
        t: this.shape_36
      }, {
        t: this.shape_35
      }, {
        t: this.shape_34
      }, {
        t: this.shape_33
      }, {
        t: this.shape_32
      }, {
        t: this.shape_31
      }, {
        t: this.shape_30
      }, {
        t: this.shape_29
      }, {
        t: this.shape_28
      }, {
        t: this.shape_27
      }, {
        t: this.shape_26
      }, {
        t: this.shape_25
      }, {
        t: this.shape_24
      }, {
        t: this.shape_23
      }, {
        t: this.shape_22
      }, {
        t: this.shape_21
      }, {
        t: this.shape_20
      }, {
        t: this.shape_19
      }, {
        t: this.shape_18
      }, {
        t: this.shape_17
      }, {
        t: this.shape_16
      }, {
        t: this.shape_15
      }, {
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-401.9, -16.4, 803.9, 32.9);


  (lib.Tween90 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#000000").s().p("AgLBXIAAgYIAXAAIAAAYgAgJAnIgDh9IAZAAIgDB9g");
    this.shape.setTransform(240.625, 0.825);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#000000").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_1.setTransform(231.475, 3);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#000000").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_2.setTransform(219.3, 2.825);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#000000").s().p("AgpAyQgRgSABggQAAgfAQgTQAQgSAZAAQAbAAAPASQAQASABAgQgBAhgQASQgPASgbAAQgaAAgPgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMAQAAQASAAAIgMQAKgNAAgZQAAgYgKgMQgIgNgSAAQgQAAgJANg");
    this.shape_3.setTransform(206, 3);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#000000").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_4.setTransform(196.75, 0.775);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#000000").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_5.setTransform(190.075, 1.25);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#000000").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_6.setTransform(180.775, 3);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#000000").s().p("AgmAzQgRgRgBghQABgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_7.setTransform(168.95, 2.975);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#000000").s().p("AgaBBQgHgDgFgGQgHgGgCgKQgDgJgBgNIAAhUIAXAAIAABJIABASQAAAHADAFQACAFAFACQAEACAKAAQAGAAAKgEQAIgFAIgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgIgCg");
    this.shape_8.setTransform(155.85, 3.175);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#000000").s().p("AAhBbIAAg/QgKAKgJADQgJAFgKAAQgWAAgOgSQgNgSAAgfQAAgRAFgNQAEgNAIgIQAHgJAKgFQAKgFAKABQAKAAAIACQAHACAIAFIABgGIAVAAIAACygAgWg6QgJANAAAXQAAAWAHAMQAIANAQAAQAIgBAJgEQAJgDAHgHIAAhJQgIgFgHgBQgHgCgHABQgQAAgKAMg");
    this.shape_9.setTransform(142.125, 5.25);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#000000").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_10.setTransform(124.875, 3);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#000000").s().p("AgZBBQgIgDgGgGQgGgGgDgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAJAAQAHAAAJgEQAKgFAIgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgHgCg");
    this.shape_11.setTransform(113.05, 3.175);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#000000").s().p("AgqAyQgQgSAAggQABgfAQgTQAQgSAaAAQAaAAAQASQAPASAAAgQAAAhgPASQgQASgaAAQgbAAgQgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_12.setTransform(99.85, 3);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#000000").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_13.setTransform(87.375, 5.425);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#000000").s().p("AgXBaIgSgEIAAgXIABAAIAGADIAKADIAMACIALABQAJAAAHgDQAHgCAEgFQAEgFABgGQACgHAAgIIAAgLQgJAIgJADQgIAFgMAAQgWgBgOgQQgNgRAAgeQAAgQAFgNQAEgMAIgKQAHgIALgEQAKgFAJAAQALAAAHACIAPAHIABgFIAVAAIAAByQAAAigPAQQgOAPgdAAQgKAAgKgCgAgVg7QgKAMAAAYQAAAVAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgBQgHgCgHAAQgQAAgJAMg");
    this.shape_14.setTransform(66.825, 5.3);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics.f("#000000").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgGgDQgEgCgIAAQgIAAgJAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQAMANgBAYIAABUg");
    this.shape_15.setTransform(54, 2.825);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics.f("#000000").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAXAAIAAAXg");
    this.shape_16.setTransform(44.5, 0.775);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics.f("#000000").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_17.setTransform(38.175, 3);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics.f("#000000").s().p("AgmAzQgRgRgBghQAAgfARgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQAEALAAAQIAAALIhaAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgFAFQgFAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgEgFQgDgEgHgDQgGgDgIAAQgIAAgGADg");
    this.shape_18.setTransform(26.8, 2.975);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics.f("#000000").s().p("AAgBBIgghjIgfBjIgUAAIgiiBIAYAAIAWBkIAghkIARAAIAfBkIAXhkIAVAAIggCBg");
    this.shape_19.setTransform(11.55, 3);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics.f("#000000").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_20.setTransform(-2.525, 3);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics.f("#000000").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_21.setTransform(-14.7, 2.825);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics.f("#000000").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQAMgFAOgCIAggDIAAgEQAAgIgDgFQgCgEgEgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAJACAIAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgKAAQgJAAgHgDgAAKAAQgLAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGANAAQAJAAAIgFQAJgEAHgHIAAgjIgUACg");
    this.shape_22.setTransform(-28.1, 3.025);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics.f("#000000").s().p("AgpAyQgQgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQARASgBAgQABAhgRASQgPASgaAAQgbAAgPgTgAgZgkQgJAMAAAYQAAAYAJAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_23.setTransform(-48.05, 3);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics.f("#000000").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_24.setTransform(-58.475, 1.25);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics.f("#000000").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAHIgJAFIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAZQAAAXAHAMQAIAMAQAAQAIAAAIgDQAJgFAIgGIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_25.setTransform(-77.175, 0.6);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics.f("#000000").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_26.setTransform(-86.925, 3);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics.f("#000000").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQAMgFAOgCIAggDIAAgEQAAgIgDgFQgBgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgIAAgHgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGANAAQAJAAAIgFQAJgEAHgHIAAgjIgTACg");
    this.shape_27.setTransform(-98.65, 3.025);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics.f("#000000").s().p("AAgBBIgghjIgfBjIgUAAIgiiBIAXAAIAYBkIAehkIASAAIAgBkIAVhkIAWAAIggCBg");
    this.shape_28.setTransform(-113.45, 3);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics.f("#000000").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_29.setTransform(-125.925, 3);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics.f("#000000").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgYgKgMQgJgNgRAAQgQAAgJANg");
    this.shape_30.setTransform(-137.55, 3);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics.f("#000000").s().p("AgZBcIAAhwIgPAAIAAgSIAPAAIAAgEQAAgYALgMQAMgNAUAAIAMABIALABIAAAVIgCAAQgDgCgFAAIgKgCQgOAAgGAHQgFAGAAAQIAAAFIAkAAIAAASIgjAAIAABwg");
    this.shape_31.setTransform(-147.275, 0.375);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics.f("#000000").s().p("AAZBbIgwg/IgIAJIAAA2IgWAAIAAi1IAWAAIAABqIAzg3IAbAAIg1A2IA8BMg");
    this.shape_32.setTransform(-164.125, 0.425);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics.f("#000000").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgYgKgMQgJgNgRAAQgQAAgJANg");
    this.shape_33.setTransform(-177.7, 3);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics.f("#000000").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZgkQgKAMAAAYQAAAYAKAOQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgYgKgMQgJgNgRAAQgQAAgJANg");
    this.shape_34.setTransform(-190.75, 3);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics.f("#000000").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_35.setTransform(-200, 0.425);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics.f("#000000").s().p("AgmAzQgSgRAAghQAAgfARgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQAEALAAAQIAAALIhaAAQAAAWAMANQALAMATAAQAHAAAHgCIANgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgHADgEAFQgFAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgEgFQgDgEgHgDQgGgDgIAAQgIAAgGADg");
    this.shape_36.setTransform(-216.55, 2.975);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics.f("#000000").s().p("AAjBXIgjiQIgiCQIgaAAIgsitIAYAAIAjCQIAjiQIAWAAIAjCRIAjiRIAXAAIgsCtg");
    this.shape_37.setTransform(-233.725, 0.825);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_37
      }, {
        t: this.shape_36
      }, {
        t: this.shape_35
      }, {
        t: this.shape_34
      }, {
        t: this.shape_33
      }, {
        t: this.shape_32
      }, {
        t: this.shape_31
      }, {
        t: this.shape_30
      }, {
        t: this.shape_29
      }, {
        t: this.shape_28
      }, {
        t: this.shape_27
      }, {
        t: this.shape_26
      }, {
        t: this.shape_25
      }, {
        t: this.shape_24
      }, {
        t: this.shape_23
      }, {
        t: this.shape_22
      }, {
        t: this.shape_21
      }, {
        t: this.shape_20
      }, {
        t: this.shape_19
      }, {
        t: this.shape_18
      }, {
        t: this.shape_17
      }, {
        t: this.shape_16
      }, {
        t: this.shape_15
      }, {
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-401.9, -16.4, 803.9, 32.9);


  (lib.Tween89 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("AgLBXIAAgYIAXAAIAAAYgAgJAnIgDh9IAZAAIgDB9g");
    this.shape.setTransform(133.275, 31.775);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#252423").s().p("AAeBbIAAhKIgCgRQgBgHgCgFQgDgEgFgDQgEgCgIAAQgHAAgJAEQgJAEgJAIIAABgIgVAAIAAi1IAVAAIAABCQALgJAJgFQAJgEAKAAQATAAAMAMQALANAAAXIAABVg");
    this.shape_1.setTransform(122.65, 31.375);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#252423").s().p("AgNBBQgLgFgIgJQgIgIgEgNQgFgMAAgSQAAgQAFgNQAEgMAIgJQAIgIALgFQAMgEAMgBQALAAAKADQAKAEAIADIAAAaIgBAAIgGgFIgJgFQgFgDgGgCQgGgCgGAAQgQABgLANQgKAMAAAXQAAAYAKANQAKANARgBQALABAJgFQAKgEAIgIIABAAIAAAZIgIAEIgJADIgKACIgMABQgMAAgMgDg");
    this.shape_2.setTransform(110.525, 33.95);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#252423").s().p("AgZBBQgIgDgGgGQgFgGgEgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQACAFAFACQAEACAKAAQAHAAAJgEQAJgFAIgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgHgCg");
    this.shape_3.setTransform(98.1, 34.125);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#252423").s().p("AgqAyQgQgSAAggQAAgfARgTQAQgSAaAAQAaAAAQASQAPASAAAgQAAAhgPASQgQASgaAAQgbAAgQgTgAgZgkQgJALAAAZQAAAZAJANQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgZgJgLQgKgNgQAAQgRAAgJANg");
    this.shape_4.setTransform(84.9, 33.95);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA+IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNADIgMABQgRgBgKgJg");
    this.shape_5.setTransform(74.475, 32.2);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#252423").s().p("AAdBDIAAhJIgBgRQgBgIgCgFQgDgEgEgDQgFgCgJAAQgHAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAJgJAKgFQAJgEAKAAQAUAAAKAMQAMANAAAYIAABUg");
    this.shape_6.setTransform(56.2, 33.775);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_7.setTransform(46.7, 31.725);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA+IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNADIgMABQgRgBgKgJg");
    this.shape_8.setTransform(32.525, 32.2);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQAEALgBAQIAAALIhZAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgGAFQgFAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgEgEgFgDQgGgDgKAAQgHAAgGADg");
    this.shape_9.setTransform(22.1, 33.925);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#252423").s().p("AgXBaIgSgDIAAgYIABAAIAGADIAKADIAMACIALABQAJAAAHgCQAHgDAEgFQAEgFABgGQACgGAAgJIAAgLQgJAIgJADQgIAFgMAAQgWgBgOgQQgNgRAAgeQAAgQAFgNQAEgMAIgKQAHgIALgEQAKgFAJAAQALAAAHACIAPAHIABgGIAVAAIAABzQAAAigPAQQgOAPgdAAIgUgCgAgVg7QgKAMAAAYQAAAVAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgCIgOgBQgQAAgJAMg");
    this.shape_10.setTransform(8.675, 36.25);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#252423").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZgkQgKALAAAZQAAAZAKANQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgZgKgLQgJgNgRAAQgQAAgJANg");
    this.shape_11.setTransform(-11.55, 33.95);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA+IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNADIgMABQgRgBgKgJg");
    this.shape_12.setTransform(-21.975, 32.2);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgFAFQgGAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgDgEgHgDQgFgDgKAAQgHAAgGADg");
    this.shape_13.setTransform(-39.9, 33.925);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#252423").s().p("AAdBDIAAhJIAAgRQgCgIgCgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgWAAIAAiCIAWAAIAAAPQAKgJAKgFQAJgEAKAAQATAAALAMQALANAAAYIAABUg");
    this.shape_14.setTransform(-52.9, 33.775);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_15.setTransform(-62.4, 31.725);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_16.setTransform(-67.9, 31.375);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA+IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNADIgMABQgRgBgKgJg");
    this.shape_17.setTransform(-82.075, 32.2);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics.f("#252423").s().p("AgNBBQgLgFgIgJQgIgIgEgNQgFgMAAgSQAAgQAFgNQAEgMAIgJQAIgIALgFQAMgEAMgBQALAAAKADQAKAEAIADIAAAaIgBAAIgGgFIgJgFQgFgDgGgCQgGgCgGAAQgQABgLANQgKAMAAAXQAAAYAKANQAKANARgBQALABAJgFQAKgEAIgIIABAAIAAAZIgIAEIgJADIgKACIgMABQgMAAgMgDg");
    this.shape_18.setTransform(-91.575, 33.95);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQAEALgBAQIAAALIhZAAQAAAWALANQAMAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgNABQgeAAgRgSgAgLgvQgGADgGAFQgFAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgEgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_19.setTransform(-103.55, 33.925);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics.f("#252423").s().p("AgiBBIAAiCIAWAAIAAAUQAMgKAIgFQAJgFAJAAIAFABIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_20.setTransform(-113.475, 33.95);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_21.setTransform(-121.3, 31.725);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics.f("#252423").s().p("AgZBZQgJgFgGgJQgHgIgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgJAKgEQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC2IgWAAIAAgOIgJAHIgJAGIgJADIgLABQgLAAgJgEgAgVgLQgKALAAAZQAAAWAHANQAIAMAQAAQAIAAAIgDQAJgFAIgHIAAhKQgIgEgHgCQgGgBgIAAQgQAAgJANg");
    this.shape_22.setTransform(-131.125, 31.55);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAGgIAMgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgDgEgEgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_23.setTransform(375.75, 3.025);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics.f("#252423").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAHIgJAFIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAZQAAAXAHAMQAIAMAQAAQAIAAAIgDQAJgFAIgGIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_24.setTransform(355.275, 0.6);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgGgDQgEgCgJAAQgHAAgJAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQAMANgBAYIAABUg");
    this.shape_25.setTransform(342.45, 2.825);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgDgEgEgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgCAAIAAgXIASgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_26.setTransform(329.05, 3.025);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics.f("#252423").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_27.setTransform(310.475, 3);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics.f("#252423").s().p("AASBlQgLgLgCgVIgCAAIgDAAQgRABgOgHQgOgFgLgMQgKgMgFgRQgGgQABgVQgBgWAGgRQAFgRAKgMQALgLAOgGQAPgGAQAAQARAAAPAGQAPAHAJAKQAKAMAFARQAGARAAAWQAAAggNAWQgOAXgXAHQABAHACAGQABAEADAEQADAEAFABQAFABAHAAIAMgBIAJgDIACAAIAAAVQgFACgHABIgOABQgUAAgNgLgAgVhWQgJADgIAKQgHAJgDAOQgEANAAARQAAAhAOATQAOARAYABQAYgBAPgRQAOgTAAghQAAgSgEgNQgEgNgGgJQgIgJgJgEQgKgFgMAAQgMAAgJAFg");
    this.shape_28.setTransform(296.45, 2.95);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics.f("#252423").s().p("AAwBXIgPgwIhCAAIgQAwIgXAAIA6itIAdAAIA6CtgAgaATIA1AAIgbhSg");
    this.shape_29.setTransform(280.75, 0.825);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics.f("#252423").s().p("AgzBXIAAitIBnAAIAAAUIhQAAIAAAyIBNAAIAAATIhNAAIAABUg");
    this.shape_30.setTransform(268.075, 0.825);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics.f("#252423").s().p("AgYAmIAVhLIAcAAIggBLg");
    this.shape_31.setTransform(249.7, 10.05);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics.f("#252423").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_32.setTransform(241.175, 3);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_33.setTransform(232.9, 0.425);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_34.setTransform(227.4, 0.775);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAGgKQAGgIAMgGQAKgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgLgBQgJAAgJACQgLACgKAFIgCAAIAAgXIASgEQALgCAMAAQAMAAAJACQAJACAIAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgFACgKAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_35.setTransform(218, 3.025);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_36.setTransform(208.175, 1.25);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics.f("#252423").s().p("AgmAzQgRgRgBghQABgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgHgDgIAAQgIAAgGADg");
    this.shape_37.setTransform(197.75, 2.975);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics.f("#252423").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAHIgJAFIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAZQAAAXAHAMQAIAMAQAAQAIAAAIgDQAJgFAIgGIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_38.setTransform(184.325, 0.6);

    this.shape_39 = new cjs.Shape();
    this.shape_39.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_39.setTransform(166.725, 1.25);

    this.shape_40 = new cjs.Shape();
    this.shape_40.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_40.setTransform(157.225, 3);

    this.shape_41 = new cjs.Shape();
    this.shape_41.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAGgIAMgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgDgEgEgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_41.setTransform(144.9, 3.025);

    this.shape_42 = new cjs.Shape();
    this.shape_42.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_42.setTransform(135.075, 1.25);

    this.shape_43 = new cjs.Shape();
    this.shape_43.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQALANAAAYIAABUg");
    this.shape_43.setTransform(124.3, 2.825);

    this.shape_44 = new cjs.Shape();
    this.shape_44.graphics.f("#252423").s().p("AgqAyQgQgSAAggQAAgfARgTQAQgSAaAAQAaAAAQASQAPASAAAgQAAAhgPASQgQASgaAAQgbAAgQgTgAgZgkQgJAMAAAYQAAAYAJAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_44.setTransform(111, 3);

    this.shape_45 = new cjs.Shape();
    this.shape_45.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_45.setTransform(99.125, 3);

    this.shape_46 = new cjs.Shape();
    this.shape_46.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_46.setTransform(82.375, 3);

    this.shape_47 = new cjs.Shape();
    this.shape_47.graphics.f("#252423").s().p("AgaBBQgHgDgGgGQgFgGgEgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAKAAQAGAAAKgEQAIgFAJgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgIgCg");
    this.shape_47.setTransform(70.55, 3.175);

    this.shape_48 = new cjs.Shape();
    this.shape_48.graphics.f("#252423").s().p("AgqAyQgPgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQARASgBAgQABAhgRASQgPASgaAAQgbAAgQgTgAgZgkQgJAMAAAYQAAAYAJAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_48.setTransform(57.35, 3);

    this.shape_49 = new cjs.Shape();
    this.shape_49.graphics.f("#252423").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAHIgJAFIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAZQAAAXAHAMQAIAMAQAAQAIAAAIgDQAJgFAIgGIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_49.setTransform(36.275, 0.6);

    this.shape_50 = new cjs.Shape();
    this.shape_50.graphics.f("#252423").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_50.setTransform(23.45, 2.825);

    this.shape_51 = new cjs.Shape();
    this.shape_51.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_51.setTransform(13.95, 0.775);

    this.shape_52 = new cjs.Shape();
    this.shape_52.graphics.f("#252423").s().p("AgZBcIAAhwIgPAAIAAgSIAPAAIAAgEQAAgYALgMQAMgNAUAAIAMABIALABIAAAVIgCAAQgDgCgFAAIgKgCQgOAAgGAHQgFAGAAAQIAAAFIAkAAIAAASIgjAAIAABwg");
    this.shape_52.setTransform(7.975, 0.375);

    this.shape_53 = new cjs.Shape();
    this.shape_53.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_53.setTransform(-6.7, 0.425);

    this.shape_54 = new cjs.Shape();
    this.shape_54.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_54.setTransform(-12.2, 0.425);

    this.shape_55 = new cjs.Shape();
    this.shape_55.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_55.setTransform(-17.7, 0.775);

    this.shape_56 = new cjs.Shape();
    this.shape_56.graphics.f("#252423").s().p("AAgBBIgghjIgeBjIgVAAIghiBIAWAAIAXBkIAfhkIASAAIAfBkIAWhkIAXAAIgiCBg");
    this.shape_56.setTransform(-29.35, 3);

    this.shape_57 = new cjs.Shape();
    this.shape_57.graphics.f("#252423").s().p("AgaBBQgHgDgFgGQgHgGgCgKQgDgJgBgNIAAhUIAXAAIAABJIAAASQABAHADAFQACAFAFACQAEACAKAAQAGAAAKgEQAIgFAIgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgLAAQgIAAgIgCg");
    this.shape_57.setTransform(-52.5, 3.175);

    this.shape_58 = new cjs.Shape();
    this.shape_58.graphics.f("#252423").s().p("AgpAyQgQgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgTgAgZgkQgKAMABAYQgBAYAKAOQAJAMARAAQARAAAIgMQAKgNAAgZQAAgYgKgMQgIgNgRAAQgRAAgJANg");
    this.shape_58.setTransform(-65.7, 3);

    this.shape_59 = new cjs.Shape();
    this.shape_59.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_59.setTransform(-78.175, 5.425);

    this.shape_60 = new cjs.Shape();
    this.shape_60.graphics.f("#252423").s().p("AAeBDIAAhJIgCgRQgBgIgCgFQgDgEgFgDQgEgCgIAAQgHAAgJAEQgJAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQALgJAJgFQAJgEAKAAQATAAAMAMQALANgBAYIAABUg");
    this.shape_60.setTransform(-98.3, 2.825);

    this.shape_61 = new cjs.Shape();
    this.shape_61.graphics.f("#252423").s().p("AgpAyQgQgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgTgAgZgkQgKAMABAYQgBAYAKAOQAJAMARAAQARAAAIgMQAKgNAAgZQAAgYgKgMQgIgNgRAAQgRAAgJANg");
    this.shape_61.setTransform(-111.6, 3);

    this.shape_62 = new cjs.Shape();
    this.shape_62.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_62.setTransform(-123.475, 3);

    this.shape_63 = new cjs.Shape();
    this.shape_63.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAXAAIAAAXg");
    this.shape_63.setTransform(-131.9, 0.775);

    this.shape_64 = new cjs.Shape();
    this.shape_64.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_64.setTransform(-146.075, 1.25);

    this.shape_65 = new cjs.Shape();
    this.shape_65.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_65.setTransform(-153.775, 3);

    this.shape_66 = new cjs.Shape();
    this.shape_66.graphics.f("#252423").s().p("AgqAyQgPgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQARASgBAgQABAhgRASQgPASgaAAQgbAAgQgTgAgZgkQgJAMAAAYQAAAYAJAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_66.setTransform(-165.4, 3);

    this.shape_67 = new cjs.Shape();
    this.shape_67.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYAAQgJAAgHgCQgIgDgIgEIAAA2gAgPhCQgJAFgIAHIAABJIAPAGQAGABAIAAQAQAAAKgNQAJgMAAgYQAAgWgHgMQgHgNgQAAQgIAAgJAEg");
    this.shape_67.setTransform(-178.075, 5.25);

    this.shape_68 = new cjs.Shape();
    this.shape_68.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYAAQgJAAgHgCQgIgDgIgEIAAA2gAgPhCQgJAFgIAHIAABJIAPAGQAGABAIAAQAQAAAKgNQAJgMAAgYQAAgWgHgMQgHgNgQAAQgIAAgJAEg");
    this.shape_68.setTransform(-191.325, 5.25);

    this.shape_69 = new cjs.Shape();
    this.shape_69.graphics.f("#252423").s().p("AgaBBQgHgDgFgGQgHgGgCgKQgEgJAAgNIAAhUIAXAAIAABJIABASQAAAHADAFQACAFAFACQAEACAKAAQAHAAAIgEQAKgFAHgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgIgCg");
    this.shape_69.setTransform(-205.15, 3.175);

    this.shape_70 = new cjs.Shape();
    this.shape_70.graphics.f("#252423").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_70.setTransform(-216.975, 3);

    this.shape_71 = new cjs.Shape();
    this.shape_71.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQAEALgBAQIAAALIhZAAQAAAWALANQAMAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgNABQgeAAgRgSgAgLgvQgGADgGAFQgFAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgEgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_71.setTransform(-236.3, 2.975);

    this.shape_72 = new cjs.Shape();
    this.shape_72.graphics.f("#252423").s().p("AAeBbIAAhKIgBgRQgBgHgDgFQgCgEgGgDQgEgCgJAAQgHAAgJAEQgIAEgJAIIAABgIgVAAIAAi1IAVAAIAABCQAKgJAKgFQAJgEAKAAQAUAAAKAMQAMANgBAXIAABVg");
    this.shape_72.setTransform(-249.3, 0.425);

    this.shape_73 = new cjs.Shape();
    this.shape_73.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_73.setTransform(-259.975, 1.25);

    this.shape_74 = new cjs.Shape();
    this.shape_74.graphics.f("#252423").s().p("AgXBaIgSgEIAAgXIABAAIAGADIAKADIAMACIALABQAJAAAHgDQAHgCAEgFQAEgFABgGQACgHAAgIIAAgLQgJAIgJADQgIAFgMAAQgWgBgOgQQgNgRAAgeQAAgQAFgNQAEgMAIgKQAHgIALgEQAKgFAJAAQALAAAHACIAPAHIABgFIAVAAIAAByQAAAigPAQQgOAPgdAAQgKAAgKgCgAgVg7QgKAMAAAYQAAAVAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgBQgHgCgHAAQgQAAgJAMg");
    this.shape_74.setTransform(-278.675, 5.3);

    this.shape_75 = new cjs.Shape();
    this.shape_75.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQALANAAAYIAABUg");
    this.shape_75.setTransform(-291.5, 2.825);

    this.shape_76 = new cjs.Shape();
    this.shape_76.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAXAAIAAAXg");
    this.shape_76.setTransform(-301, 0.775);

    this.shape_77 = new cjs.Shape();
    this.shape_77.graphics.f("#252423").s().p("AAZBbIgwg/IgIAJIAAA2IgWAAIAAi1IAWAAIAABqIAzg3IAbAAIg1A2IA8BMg");
    this.shape_77.setTransform(-308.675, 0.425);

    this.shape_78 = new cjs.Shape();
    this.shape_78.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_78.setTransform(-321.075, 3);

    this.shape_79 = new cjs.Shape();
    this.shape_79.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_79.setTransform(-329.5, 0.775);

    this.shape_80 = new cjs.Shape();
    this.shape_80.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_80.setTransform(-335, 0.425);

    this.shape_81 = new cjs.Shape();
    this.shape_81.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_81.setTransform(-343.125, 3);

    this.shape_82 = new cjs.Shape();
    this.shape_82.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_82.setTransform(-362.275, 5.425);

    this.shape_83 = new cjs.Shape();
    this.shape_83.graphics.f("#252423").s().p("Ag6BXIAAitIAuAAQASAAAJABQAKABAIAFQAJAFAFAIQAEAIAAALQAAAMgGAKQgHAKgLAFIAAABQAPAFAJAKQAIALAAARQAAANgFAKQgFAKgIAGQgKAIgLADQgLADgRAAgAgjBDIATAAIAXgBQAJgBAHgFQAGgFADgFQADgGAAgJQAAgKgDgHQgCgGgJgEQgGgDgGgBIgRgBIgbAAgAgjgPIAbAAIAPgBQAFgCAFgDQAGgDACgGQACgFAAgJQAAgGgCgEQgCgFgFgDQgGgDgGgBIgTAAIgWAAg");
    this.shape_83.setTransform(-374.725, 0.825);

    this.shape_84 = new cjs.Shape();
    this.shape_84.graphics.f("#252423").s().p("AgLBXIAAgYIAXAAIAAAYgAgJAnIgDh9IAZAAIgDB9g");
    this.shape_84.setTransform(377.325, -30.125);

    this.shape_85 = new cjs.Shape();
    this.shape_85.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgEgEgGgDQgHgDgJAAQgHAAgGADg");
    this.shape_85.setTransform(367.05, -27.975);

    this.shape_86 = new cjs.Shape();
    this.shape_86.graphics.f("#252423").s().p("AgJBBIgxiBIAYAAIAjBkIAjhkIAXAAIgxCBg");
    this.shape_86.setTransform(354.725, -27.95);

    this.shape_87 = new cjs.Shape();
    this.shape_87.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgCAAIAAgXIASgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_87.setTransform(342.1, -27.925);

    this.shape_88 = new cjs.Shape();
    this.shape_88.graphics.f("#252423").s().p("AAdBbIAAhKIgBgRQAAgHgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAi1IAXAAIAABCQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAXIAABVg");
    this.shape_88.setTransform(329.55, -30.525);

    this.shape_89 = new cjs.Shape();
    this.shape_89.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_89.setTransform(309.325, -25.525);

    this.shape_90 = new cjs.Shape();
    this.shape_90.graphics.f("#252423").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAGgIANgGQALgFAPgCIAfgDIAAgEQAAgIgDgFQgCgEgEgDQgEgDgGAAIgMgBQgIAAgJACQgLACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAJACAIAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgKAAQgJAAgHgDgAAKAAQgLAAgHACQgJADgFAFQgHAGABAKQAAALAGAGQAGAGANAAQAJAAAIgFQAIgEAIgHIAAgjIgUACg");
    this.shape_90.setTransform(296.7, -27.925);

    this.shape_91 = new cjs.Shape();
    this.shape_91.graphics.f("#252423").s().p("AA/BDIAAhKIAAgRQgBgIgCgEQgDgFgEgCQgEgCgIAAQgHAAgHAEQgIAEgIAIIAAAFIAAAGIAABVIgVAAIAAhKIgBgRQAAgIgDgEQgCgFgEgCQgFgCgHAAQgHAAgIAEQgIAFgHAHIAABgIgWAAIAAiCIAWAAIAAAPQAIgJAJgEQAIgFALAAQALAAAJAFQAIAFAFAMQAKgLAJgGQAKgFALAAQAIAAAHACQAHADAFAGQAGAGADAJQACAJAAANIAABVg");
    this.shape_91.setTransform(280.775, -28.125);

    this.shape_92 = new cjs.Shape();
    this.shape_92.graphics.f("#252423").s().p("AgaBBQgHgDgGgGQgFgGgDgKQgDgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAKAAQAGAAAKgEQAIgFAJgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgIgCg");
    this.shape_92.setTransform(256.4, -27.775);

    this.shape_93 = new cjs.Shape();
    this.shape_93.graphics.f("#252423").s().p("AgqAzQgPgTgBggQAAggARgSQAQgSAaAAQAaAAAPASQARASgBAgQABAhgRASQgPASgaAAQgbAAgQgSgAgZgkQgJAMAAAYQAAAYAJANQAJANARAAQAQAAAKgNQAJgMAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_93.setTransform(243.2, -27.95);

    this.shape_94 = new cjs.Shape();
    this.shape_94.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_94.setTransform(230.725, -25.525);

    this.shape_95 = new cjs.Shape();
    this.shape_95.graphics.f("#252423").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgDQAEgCACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgIAAgNQAAgTAOgKQANgMAVAAQALAAALADQALADAHAEIAAAXIgBAAQgIgGgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJADIAKACIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMABQgNAAgLgEg");
    this.shape_95.setTransform(212.075, -27.95);

    this.shape_96 = new cjs.Shape();
    this.shape_96.graphics.f("#252423").s().p("AgmAzQgSgRAAghQAAgfARgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQADALABAQIAAALIhaAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgNABQgfAAgRgSgAgLgvQgHADgEAFQgFAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgGgDQgGgDgIAAQgIAAgGADg");
    this.shape_96.setTransform(200.25, -27.975);

    this.shape_97 = new cjs.Shape();
    this.shape_97.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_97.setTransform(191.15, -30.175);

    this.shape_98 = new cjs.Shape();
    this.shape_98.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgKAIgFQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_98.setTransform(184.825, -27.95);

    this.shape_99 = new cjs.Shape();
    this.shape_99.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_99.setTransform(177, -30.175);

    this.shape_100 = new cjs.Shape();
    this.shape_100.graphics.f("#252423").s().p("AgZBBQgIgDgGgGQgGgGgDgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAJAAQAIAAAJgEQAIgFAJgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgHgCg");
    this.shape_100.setTransform(167.5, -27.775);

    this.shape_101 = new cjs.Shape();
    this.shape_101.graphics.f("#252423").s().p("AAhBbIAAg/QgKAJgJAFQgJADgKAAQgWAAgOgRQgNgSAAgfQAAgRAFgNQAEgNAIgIQAHgJAKgFQAKgFAKABQAKgBAIADQAHACAIAFIABgGIAVAAIAACygAgWg6QgJAMAAAYQAAAWAHAMQAIANAQAAQAIAAAJgFQAJgEAHgGIAAhJQgIgFgHgBQgHgBgHgBQgQAAgKANg");
    this.shape_101.setTransform(153.775, -25.7);

    this.shape_102 = new cjs.Shape();
    this.shape_102.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQALANAAAYIAABUg");
    this.shape_102.setTransform(140.95, -28.125);

    this.shape_103 = new cjs.Shape();
    this.shape_103.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAXAAIAAAXg");
    this.shape_103.setTransform(131.45, -30.175);

    this.shape_104 = new cjs.Shape();
    this.shape_104.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_104.setTransform(115.225, -25.525);

    this.shape_105 = new cjs.Shape();
    this.shape_105.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgGgDQgEgCgJAAQgHAAgJAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAALAMQALANgBAYIAABUg");
    this.shape_105.setTransform(102.6, -28.125);

    this.shape_106 = new cjs.Shape();
    this.shape_106.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgCAAIAAgXIASgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_106.setTransform(89.2, -27.925);

    this.shape_107 = new cjs.Shape();
    this.shape_107.graphics.f("#252423").s().p("AAdBbIAAhKIgBgRQgBgHgCgFQgDgEgEgDQgFgCgJAAQgGAAgKAEQgIAEgIAIIAABgIgXAAIAAi1IAXAAIAABCQAKgJAJgFQAJgEAKAAQAUAAAKAMQAMANAAAXIAABVg");
    this.shape_107.setTransform(69.15, -30.525);

    this.shape_108 = new cjs.Shape();
    this.shape_108.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAgmIAWAAIAAAmIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMAAQgRABgKgKg");
    this.shape_108.setTransform(58.475, -29.7);

    this.shape_109 = new cjs.Shape();
    this.shape_109.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_109.setTransform(51.6, -30.175);

    this.shape_110 = new cjs.Shape();
    this.shape_110.graphics.f("#252423").s().p("AAgBBIgghkIgeBkIgVAAIghiBIAWAAIAXBkIAfhkIASAAIAfBkIAWhkIAXAAIgiCBg");
    this.shape_110.setTransform(39.95, -27.95);

    this.shape_111 = new cjs.Shape();
    this.shape_111.graphics.f("#252423").s().p("AgaBBQgHgDgFgGQgHgGgCgKQgDgJgBgNIAAhUIAXAAIAABJIAAASQABAHADAFQACAFAFACQAEACAKAAQAGAAAKgEQAIgFAIgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgLAAQgIAAgIgCg");
    this.shape_111.setTransform(16.8, -27.775);

    this.shape_112 = new cjs.Shape();
    this.shape_112.graphics.f("#252423").s().p("AgpAzQgQgTgBggQAAggARgSQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgSgAgZgkQgKAMABAYQgBAYAKANQAJANARAAQARAAAIgNQAKgMAAgZQAAgYgKgMQgIgNgRAAQgRAAgJANg");
    this.shape_112.setTransform(3.6, -27.95);

    this.shape_113 = new cjs.Shape();
    this.shape_113.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_113.setTransform(-8.875, -25.525);

    this.shape_114 = new cjs.Shape();
    this.shape_114.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYgBQgJAAgHgBQgIgDgIgFIAAA3gAgPhBQgJAEgIAHIAABIIAPAHQAGABAIAAQAQAAAKgNQAJgNAAgXQAAgWgHgMQgHgNgQAAQgIAAgJAFg");
    this.shape_114.setTransform(-28.525, -25.7);

    this.shape_115 = new cjs.Shape();
    this.shape_115.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_115.setTransform(-38.35, -30.525);

    this.shape_116 = new cjs.Shape();
    this.shape_116.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgGADgGAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgEgEgGgDQgHgDgJAAQgHAAgGADg");
    this.shape_116.setTransform(-47.4, -27.975);

    this.shape_117 = new cjs.Shape();
    this.shape_117.graphics.f("#252423").s().p("AAeBbIAAhKIgBgRQgCgHgCgFQgDgEgFgDQgEgCgIAAQgIAAgJAEQgIAEgJAIIAABgIgVAAIAAi1IAVAAIAABCQALgJAJgFQAJgEAKAAQATAAAMAMQALANgBAXIAABVg");
    this.shape_117.setTransform(-60.4, -30.525);

    this.shape_118 = new cjs.Shape();
    this.shape_118.graphics.f("#252423").s().p("AgpAzQgRgTABggQAAggAQgSQAQgSAZAAQAbAAAPASQAQASABAgQgBAhgQASQgPASgbAAQgaAAgPgSgAgZgkQgKAMAAAYQAAAYAKANQAJANAQAAQASAAAIgNQAKgMAAgZQAAgYgKgMQgIgNgSAAQgQAAgJANg");
    this.shape_118.setTransform(-81.2, -27.95);

    this.shape_119 = new cjs.Shape();
    this.shape_119.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAgmIAWAAIAAAmIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMAAQgRABgKgKg");
    this.shape_119.setTransform(-91.625, -29.7);

    this.shape_120 = new cjs.Shape();
    this.shape_120.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_120.setTransform(-109.225, -25.525);

    this.shape_121 = new cjs.Shape();
    this.shape_121.graphics.f("#252423").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgNAAgQQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAIIgJAEIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAYQAAAYAHAMQAIAMAQAAQAIAAAIgDQAJgEAIgHIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_121.setTransform(-122.275, -30.35);

    this.shape_122 = new cjs.Shape();
    this.shape_122.graphics.f("#252423").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQAMgFAOgCIAggDIAAgEQAAgIgDgFQgBgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgIAAgHgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGANAAQAJAAAIgFQAJgEAHgHIAAgjIgTACg");
    this.shape_122.setTransform(-135.1, -27.925);

    this.shape_123 = new cjs.Shape();
    this.shape_123.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgGADgGAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgEgEgGgDQgHgDgJAAQgHAAgGADg");
    this.shape_123.setTransform(-147.3, -27.975);

    this.shape_124 = new cjs.Shape();
    this.shape_124.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgKAIgFQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_124.setTransform(-157.225, -27.95);

    this.shape_125 = new cjs.Shape();
    this.shape_125.graphics.f("#252423").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgDQAEgCACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgIAAgNQAAgTAOgKQANgMAVAAQALAAALADQALADAHAEIAAAXIgBAAQgIgGgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJADIAKACIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMABQgNAAgLgEg");
    this.shape_125.setTransform(-174.975, -27.95);

    this.shape_126 = new cjs.Shape();
    this.shape_126.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_126.setTransform(-183.25, -30.175);

    this.shape_127 = new cjs.Shape();
    this.shape_127.graphics.f("#252423").s().p("AA/BDIAAhKIAAgRQgBgIgCgEQgDgFgEgCQgEgCgIAAQgHAAgHAEQgIAEgIAIIAAAFIAAAGIAABVIgVAAIAAhKIgBgRQAAgIgDgEQgCgFgEgCQgFgCgHAAQgHAAgIAEQgIAFgHAHIAABgIgWAAIAAiCIAWAAIAAAPQAIgJAJgEQAIgFALAAQALAAAJAFQAIAFAFAMQAKgLAJgGQAKgFALAAQAIAAAHACQAHADAFAGQAGAGADAJQACAJAAANIAABVg");
    this.shape_127.setTransform(-203.525, -28.125);

    this.shape_128 = new cjs.Shape();
    this.shape_128.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAGgKQAGgIAMgGQAKgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgLgBQgJAAgJACQgLACgKAFIgCAAIAAgXIASgEQALgCAMAAQAMAAAJACQAJACAIAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgFACgKAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_128.setTransform(-220.3, -27.925);

    this.shape_129 = new cjs.Shape();
    this.shape_129.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgFAFQgGAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgDgEgHgDQgFgDgKAAQgHAAgGADg");
    this.shape_129.setTransform(-232.5, -27.975);

    this.shape_130 = new cjs.Shape();
    this.shape_130.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAgmIAWAAIAAAmIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMAAQgRABgKgKg");
    this.shape_130.setTransform(-242.775, -29.7);

    this.shape_131 = new cjs.Shape();
    this.shape_131.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAgmIAWAAIAAAmIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMAAQgRABgKgKg");
    this.shape_131.setTransform(-258.325, -29.7);

    this.shape_132 = new cjs.Shape();
    this.shape_132.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgKAIgFQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_132.setTransform(-266.025, -27.95);

    this.shape_133 = new cjs.Shape();
    this.shape_133.graphics.f("#252423").s().p("AgpAzQgQgTgBggQAAggARgSQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgSgAgZgkQgKAMABAYQgBAYAKANQAJANARAAQARAAAIgNQAKgMAAgZQAAgYgKgMQgIgNgRAAQgRAAgJANg");
    this.shape_133.setTransform(-277.65, -27.95);

    this.shape_134 = new cjs.Shape();
    this.shape_134.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYgBQgJAAgHgBQgIgDgIgFIAAA3gAgPhBQgJAEgIAHIAABIIAPAHQAGABAIAAQAQAAAKgNQAJgNAAgXQAAgWgHgMQgHgNgQAAQgIAAgJAFg");
    this.shape_134.setTransform(-290.325, -25.7);

    this.shape_135 = new cjs.Shape();
    this.shape_135.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYgBQgJAAgHgBQgIgDgIgFIAAA3gAgPhBQgJAEgIAHIAABIIAPAHQAGABAIAAQAQAAAKgNQAJgNAAgXQAAgWgHgMQgHgNgQAAQgIAAgJAFg");
    this.shape_135.setTransform(-303.575, -25.7);

    this.shape_136 = new cjs.Shape();
    this.shape_136.graphics.f("#252423").s().p("AgZBBQgIgDgGgGQgFgGgEgKQgDgJAAgNIAAhUIAXAAIAABJIABASQAAAHADAFQADAFAEACQAFACAIAAQAIAAAIgEQAKgFAHgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgHgCg");
    this.shape_136.setTransform(-317.4, -27.775);

    this.shape_137 = new cjs.Shape();
    this.shape_137.graphics.f("#252423").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgDQAEgCACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgIAAgNQAAgTAOgKQANgMAVAAQALAAALADQALADAHAEIAAAXIgBAAQgIgGgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJADIAKACIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMABQgNAAgLgEg");
    this.shape_137.setTransform(-329.225, -27.95);

    this.shape_138 = new cjs.Shape();
    this.shape_138.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgKAIgFQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_138.setTransform(-345.825, -27.95);

    this.shape_139 = new cjs.Shape();
    this.shape_139.graphics.f("#252423").s().p("AgZBBQgIgDgGgGQgGgGgDgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAJAAQAHAAAJgEQAJgFAJgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgHgCg");
    this.shape_139.setTransform(-357.65, -27.775);

    this.shape_140 = new cjs.Shape();
    this.shape_140.graphics.f("#252423").s().p("AgfBVQgOgGgKgMQgKgMgGgQQgFgRAAgWQAAgVAFgRQAGgQAKgMQAKgMAOgGQAPgGAQAAQASAAAOAGQAPAHAJALQAKALAGARQAFARAAAVQAAAVgFARQgFARgLAMQgKAMgOAGQgPAGgRAAQgRAAgOgGgAgVhBQgJAEgIAJQgGAJgEAOQgEANAAAQQAAAiAPATQAOASAXAAQAYAAAPgSQAOgTAAgiQAAgQgEgOQgDgNgHgJQgHgJgKgEQgKgFgMAAQgLAAgKAFg");
    this.shape_140.setTransform(-372.825, -30.125);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_140
      }, {
        t: this.shape_139
      }, {
        t: this.shape_138
      }, {
        t: this.shape_137
      }, {
        t: this.shape_136
      }, {
        t: this.shape_135
      }, {
        t: this.shape_134
      }, {
        t: this.shape_133
      }, {
        t: this.shape_132
      }, {
        t: this.shape_131
      }, {
        t: this.shape_130
      }, {
        t: this.shape_129
      }, {
        t: this.shape_128
      }, {
        t: this.shape_127
      }, {
        t: this.shape_126
      }, {
        t: this.shape_125
      }, {
        t: this.shape_124
      }, {
        t: this.shape_123
      }, {
        t: this.shape_122
      }, {
        t: this.shape_121
      }, {
        t: this.shape_120
      }, {
        t: this.shape_119
      }, {
        t: this.shape_118
      }, {
        t: this.shape_117
      }, {
        t: this.shape_116
      }, {
        t: this.shape_115
      }, {
        t: this.shape_114
      }, {
        t: this.shape_113
      }, {
        t: this.shape_112
      }, {
        t: this.shape_111
      }, {
        t: this.shape_110
      }, {
        t: this.shape_109
      }, {
        t: this.shape_108
      }, {
        t: this.shape_107
      }, {
        t: this.shape_106
      }, {
        t: this.shape_105
      }, {
        t: this.shape_104
      }, {
        t: this.shape_103
      }, {
        t: this.shape_102
      }, {
        t: this.shape_101
      }, {
        t: this.shape_100
      }, {
        t: this.shape_99
      }, {
        t: this.shape_98
      }, {
        t: this.shape_97
      }, {
        t: this.shape_96
      }, {
        t: this.shape_95
      }, {
        t: this.shape_94
      }, {
        t: this.shape_93
      }, {
        t: this.shape_92
      }, {
        t: this.shape_91
      }, {
        t: this.shape_90
      }, {
        t: this.shape_89
      }, {
        t: this.shape_88
      }, {
        t: this.shape_87
      }, {
        t: this.shape_86
      }, {
        t: this.shape_85
      }, {
        t: this.shape_84
      }, {
        t: this.shape_83
      }, {
        t: this.shape_82
      }, {
        t: this.shape_81
      }, {
        t: this.shape_80
      }, {
        t: this.shape_79
      }, {
        t: this.shape_78
      }, {
        t: this.shape_77
      }, {
        t: this.shape_76
      }, {
        t: this.shape_75
      }, {
        t: this.shape_74
      }, {
        t: this.shape_73
      }, {
        t: this.shape_72
      }, {
        t: this.shape_71
      }, {
        t: this.shape_70
      }, {
        t: this.shape_69
      }, {
        t: this.shape_68
      }, {
        t: this.shape_67
      }, {
        t: this.shape_66
      }, {
        t: this.shape_65
      }, {
        t: this.shape_64
      }, {
        t: this.shape_63
      }, {
        t: this.shape_62
      }, {
        t: this.shape_61
      }, {
        t: this.shape_60
      }, {
        t: this.shape_59
      }, {
        t: this.shape_58
      }, {
        t: this.shape_57
      }, {
        t: this.shape_56
      }, {
        t: this.shape_55
      }, {
        t: this.shape_54
      }, {
        t: this.shape_53
      }, {
        t: this.shape_52
      }, {
        t: this.shape_51
      }, {
        t: this.shape_50
      }, {
        t: this.shape_49
      }, {
        t: this.shape_48
      }, {
        t: this.shape_47
      }, {
        t: this.shape_46
      }, {
        t: this.shape_45
      }, {
        t: this.shape_44
      }, {
        t: this.shape_43
      }, {
        t: this.shape_42
      }, {
        t: this.shape_41
      }, {
        t: this.shape_40
      }, {
        t: this.shape_39
      }, {
        t: this.shape_38
      }, {
        t: this.shape_37
      }, {
        t: this.shape_36
      }, {
        t: this.shape_35
      }, {
        t: this.shape_34
      }, {
        t: this.shape_33
      }, {
        t: this.shape_32
      }, {
        t: this.shape_31
      }, {
        t: this.shape_30
      }, {
        t: this.shape_29
      }, {
        t: this.shape_28
      }, {
        t: this.shape_27
      }, {
        t: this.shape_26
      }, {
        t: this.shape_25
      }, {
        t: this.shape_24
      }, {
        t: this.shape_23
      }, {
        t: this.shape_22
      }, {
        t: this.shape_21
      }, {
        t: this.shape_20
      }, {
        t: this.shape_19
      }, {
        t: this.shape_18
      }, {
        t: this.shape_17
      }, {
        t: this.shape_16
      }, {
        t: this.shape_15
      }, {
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400.6, -47.4, 801.2, 94.9);


  (lib.Tween88 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("AgLBXIAAgYIAXAAIAAAYgAgJAnIgDh9IAZAAIgDB9g");
    this.shape.setTransform(133.275, 31.775);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#252423").s().p("AAeBbIAAhKIgCgRQgBgHgCgFQgDgEgFgDQgEgCgIAAQgHAAgJAEQgJAEgJAIIAABgIgVAAIAAi1IAVAAIAABCQALgJAJgFQAJgEAKAAQATAAAMAMQALANAAAXIAABVg");
    this.shape_1.setTransform(122.65, 31.375);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#252423").s().p("AgNBBQgLgFgIgJQgIgIgEgNQgFgMAAgSQAAgQAFgNQAEgMAIgJQAIgIALgFQAMgEAMgBQALAAAKADQAKAEAIADIAAAaIgBAAIgGgFIgJgFQgFgDgGgCQgGgCgGAAQgQABgLANQgKAMAAAXQAAAYAKANQAKANARgBQALABAJgFQAKgEAIgIIABAAIAAAZIgIAEIgJADIgKACIgMABQgMAAgMgDg");
    this.shape_2.setTransform(110.525, 33.95);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#252423").s().p("AgZBBQgIgDgGgGQgFgGgEgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQACAFAFACQAEACAKAAQAHAAAJgEQAJgFAIgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgHgCg");
    this.shape_3.setTransform(98.1, 34.125);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#252423").s().p("AgqAyQgQgSAAggQAAgfARgTQAQgSAaAAQAaAAAQASQAPASAAAgQAAAhgPASQgQASgaAAQgbAAgQgTgAgZgkQgJALAAAZQAAAZAJANQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgZgJgLQgKgNgQAAQgRAAgJANg");
    this.shape_4.setTransform(84.9, 33.95);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA+IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNADIgMABQgRgBgKgJg");
    this.shape_5.setTransform(74.475, 32.2);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#252423").s().p("AAdBDIAAhJIgBgRQgBgIgCgFQgDgEgEgDQgFgCgJAAQgHAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAJgJAKgFQAJgEAKAAQAUAAAKAMQAMANAAAYIAABUg");
    this.shape_6.setTransform(56.2, 33.775);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_7.setTransform(46.7, 31.725);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA+IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNADIgMABQgRgBgKgJg");
    this.shape_8.setTransform(32.525, 32.2);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQAEALgBAQIAAALIhZAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgGAFQgFAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgEgEgFgDQgGgDgKAAQgHAAgGADg");
    this.shape_9.setTransform(22.1, 33.925);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#252423").s().p("AgXBaIgSgDIAAgYIABAAIAGADIAKADIAMACIALABQAJAAAHgCQAHgDAEgFQAEgFABgGQACgGAAgJIAAgLQgJAIgJADQgIAFgMAAQgWgBgOgQQgNgRAAgeQAAgQAFgNQAEgMAIgKQAHgIALgEQAKgFAJAAQALAAAHACIAPAHIABgGIAVAAIAABzQAAAigPAQQgOAPgdAAIgUgCgAgVg7QgKAMAAAYQAAAVAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgCIgOgBQgQAAgJAMg");
    this.shape_10.setTransform(8.675, 36.25);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#252423").s().p("AgqAyQgPgSAAggQAAgfAQgTQAQgSAZAAQAbAAAQASQAQASAAAgQAAAhgQASQgQASgbAAQgaAAgQgTgAgZgkQgKALAAAZQAAAZAKANQAJAMAQAAQARAAAJgMQAKgNAAgZQAAgZgKgLQgJgNgRAAQgQAAgJANg");
    this.shape_11.setTransform(-11.55, 33.95);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA+IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNADIgMABQgRgBgKgJg");
    this.shape_12.setTransform(-21.975, 32.2);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgFAFQgGAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgDgEgHgDQgFgDgKAAQgHAAgGADg");
    this.shape_13.setTransform(-39.9, 33.925);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#252423").s().p("AAdBDIAAhJIAAgRQgCgIgCgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgWAAIAAiCIAWAAIAAAPQAKgJAKgFQAJgEAKAAQATAAALAMQALANAAAYIAABUg");
    this.shape_14.setTransform(-52.9, 33.775);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_15.setTransform(-62.4, 31.725);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_16.setTransform(-67.9, 31.375);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA+IAAAPQABAGABAEQACAEAFACQAEACAIAAQAFAAAFgCIAHgCIACAAIAAAUIgNADIgMABQgRgBgKgJg");
    this.shape_17.setTransform(-82.075, 32.2);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics.f("#252423").s().p("AgNBBQgLgFgIgJQgIgIgEgNQgFgMAAgSQAAgQAFgNQAEgMAIgJQAIgIALgFQAMgEAMgBQALAAAKADQAKAEAIADIAAAaIgBAAIgGgFIgJgFQgFgDgGgCQgGgCgGAAQgQABgLANQgKAMAAAXQAAAYAKANQAKANARgBQALABAJgFQAKgEAIgIIABAAIAAAZIgIAEIgJADIgKACIgMABQgMAAgMgDg");
    this.shape_18.setTransform(-91.575, 33.95);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQAEALgBAQIAAALIhZAAQAAAWALANQAMAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgNABQgeAAgRgSgAgLgvQgGADgGAFQgFAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgEgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_19.setTransform(-103.55, 33.925);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics.f("#252423").s().p("AgiBBIAAiCIAWAAIAAAUQAMgKAIgFQAJgFAJAAIAFABIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_20.setTransform(-113.475, 33.95);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_21.setTransform(-121.3, 31.725);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics.f("#252423").s().p("AgZBZQgJgFgGgJQgHgIgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgJAKgEQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC2IgWAAIAAgOIgJAHIgJAGIgJADIgLABQgLAAgJgEgAgVgLQgKALAAAZQAAAWAHANQAIAMAQAAQAIAAAIgDQAJgFAIgHIAAhKQgIgEgHgCQgGgBgIAAQgQAAgJANg");
    this.shape_22.setTransform(-131.125, 31.55);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAGgIAMgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgDgEgEgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_23.setTransform(375.75, 3.025);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics.f("#252423").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAHIgJAFIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAZQAAAXAHAMQAIAMAQAAQAIAAAIgDQAJgFAIgGIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_24.setTransform(355.275, 0.6);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgGgDQgEgCgJAAQgHAAgJAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQAMANgBAYIAABUg");
    this.shape_25.setTransform(342.45, 2.825);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgDgEgEgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgCAAIAAgXIASgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_26.setTransform(329.05, 3.025);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics.f("#252423").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_27.setTransform(310.475, 3);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics.f("#252423").s().p("AASBlQgLgLgCgVIgCAAIgDAAQgRABgOgHQgOgFgLgMQgKgMgFgRQgGgQABgVQgBgWAGgRQAFgRAKgMQALgLAOgGQAPgGAQAAQARAAAPAGQAPAHAJAKQAKAMAFARQAGARAAAWQAAAggNAWQgOAXgXAHQABAHACAGQABAEADAEQADAEAFABQAFABAHAAIAMgBIAJgDIACAAIAAAVQgFACgHABIgOABQgUAAgNgLgAgVhWQgJADgIAKQgHAJgDAOQgEANAAARQAAAhAOATQAOARAYABQAYgBAPgRQAOgTAAghQAAgSgEgNQgEgNgGgJQgIgJgJgEQgKgFgMAAQgMAAgJAFg");
    this.shape_28.setTransform(296.45, 2.95);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics.f("#252423").s().p("AAwBXIgPgwIhCAAIgQAwIgXAAIA6itIAdAAIA6CtgAgaATIA1AAIgbhSg");
    this.shape_29.setTransform(280.75, 0.825);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics.f("#252423").s().p("AgzBXIAAitIBnAAIAAAUIhQAAIAAAyIBNAAIAAATIhNAAIAABUg");
    this.shape_30.setTransform(268.075, 0.825);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics.f("#252423").s().p("AgYAmIAVhLIAcAAIggBLg");
    this.shape_31.setTransform(249.7, 10.05);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics.f("#252423").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_32.setTransform(241.175, 3);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_33.setTransform(232.9, 0.425);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_34.setTransform(227.4, 0.775);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAGgKQAGgIAMgGQAKgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgLgBQgJAAgJACQgLACgKAFIgCAAIAAgXIASgEQALgCAMAAQAMAAAJACQAJACAIAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgFACgKAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_35.setTransform(218, 3.025);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_36.setTransform(208.175, 1.25);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics.f("#252423").s().p("AgmAzQgRgRgBghQABgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgFgDQgHgDgIAAQgIAAgGADg");
    this.shape_37.setTransform(197.75, 2.975);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics.f("#252423").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAHIgJAFIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAZQAAAXAHAMQAIAMAQAAQAIAAAIgDQAJgFAIgGIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_38.setTransform(184.325, 0.6);

    this.shape_39 = new cjs.Shape();
    this.shape_39.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_39.setTransform(166.725, 1.25);

    this.shape_40 = new cjs.Shape();
    this.shape_40.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_40.setTransform(157.225, 3);

    this.shape_41 = new cjs.Shape();
    this.shape_41.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAGgIAMgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgDgEgEgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_41.setTransform(144.9, 3.025);

    this.shape_42 = new cjs.Shape();
    this.shape_42.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_42.setTransform(135.075, 1.25);

    this.shape_43 = new cjs.Shape();
    this.shape_43.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQALANAAAYIAABUg");
    this.shape_43.setTransform(124.3, 2.825);

    this.shape_44 = new cjs.Shape();
    this.shape_44.graphics.f("#252423").s().p("AgqAyQgQgSAAggQAAgfARgTQAQgSAaAAQAaAAAQASQAPASAAAgQAAAhgPASQgQASgaAAQgbAAgQgTgAgZgkQgJAMAAAYQAAAYAJAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_44.setTransform(111, 3);

    this.shape_45 = new cjs.Shape();
    this.shape_45.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_45.setTransform(99.125, 3);

    this.shape_46 = new cjs.Shape();
    this.shape_46.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_46.setTransform(82.375, 3);

    this.shape_47 = new cjs.Shape();
    this.shape_47.graphics.f("#252423").s().p("AgaBBQgHgDgGgGQgFgGgEgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAKAAQAGAAAKgEQAIgFAJgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgIgCg");
    this.shape_47.setTransform(70.55, 3.175);

    this.shape_48 = new cjs.Shape();
    this.shape_48.graphics.f("#252423").s().p("AgqAyQgPgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQARASgBAgQABAhgRASQgPASgaAAQgbAAgQgTgAgZgkQgJAMAAAYQAAAYAJAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_48.setTransform(57.35, 3);

    this.shape_49 = new cjs.Shape();
    this.shape_49.graphics.f("#252423").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgMAAgRQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAHIgJAFIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAZQAAAXAHAMQAIAMAQAAQAIAAAIgDQAJgFAIgGIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_49.setTransform(36.275, 0.6);

    this.shape_50 = new cjs.Shape();
    this.shape_50.graphics.f("#252423").s().p("AAdBDIAAhJIgBgRQAAgIgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAiCIAXAAIAAAPQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAYIAABUg");
    this.shape_50.setTransform(23.45, 2.825);

    this.shape_51 = new cjs.Shape();
    this.shape_51.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_51.setTransform(13.95, 0.775);

    this.shape_52 = new cjs.Shape();
    this.shape_52.graphics.f("#252423").s().p("AgZBcIAAhwIgPAAIAAgSIAPAAIAAgEQAAgYALgMQAMgNAUAAIAMABIALABIAAAVIgCAAQgDgCgFAAIgKgCQgOAAgGAHQgFAGAAAQIAAAFIAkAAIAAASIgjAAIAABwg");
    this.shape_52.setTransform(7.975, 0.375);

    this.shape_53 = new cjs.Shape();
    this.shape_53.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_53.setTransform(-6.7, 0.425);

    this.shape_54 = new cjs.Shape();
    this.shape_54.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_54.setTransform(-12.2, 0.425);

    this.shape_55 = new cjs.Shape();
    this.shape_55.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_55.setTransform(-17.7, 0.775);

    this.shape_56 = new cjs.Shape();
    this.shape_56.graphics.f("#252423").s().p("AAgBBIgghjIgeBjIgVAAIghiBIAWAAIAXBkIAfhkIASAAIAfBkIAWhkIAXAAIgiCBg");
    this.shape_56.setTransform(-29.35, 3);

    this.shape_57 = new cjs.Shape();
    this.shape_57.graphics.f("#252423").s().p("AgaBBQgHgDgFgGQgHgGgCgKQgDgJgBgNIAAhUIAXAAIAABJIAAASQABAHADAFQACAFAFACQAEACAKAAQAGAAAKgEQAIgFAIgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgLAAQgIAAgIgCg");
    this.shape_57.setTransform(-52.5, 3.175);

    this.shape_58 = new cjs.Shape();
    this.shape_58.graphics.f("#252423").s().p("AgpAyQgQgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgTgAgZgkQgKAMABAYQgBAYAKAOQAJAMARAAQARAAAIgMQAKgNAAgZQAAgYgKgMQgIgNgRAAQgRAAgJANg");
    this.shape_58.setTransform(-65.7, 3);

    this.shape_59 = new cjs.Shape();
    this.shape_59.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_59.setTransform(-78.175, 5.425);

    this.shape_60 = new cjs.Shape();
    this.shape_60.graphics.f("#252423").s().p("AAeBDIAAhJIgCgRQgBgIgCgFQgDgEgFgDQgEgCgIAAQgHAAgJAEQgJAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQALgJAJgFQAJgEAKAAQATAAAMAMQALANgBAYIAABUg");
    this.shape_60.setTransform(-98.3, 2.825);

    this.shape_61 = new cjs.Shape();
    this.shape_61.graphics.f("#252423").s().p("AgpAyQgQgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgTgAgZgkQgKAMABAYQgBAYAKAOQAJAMARAAQARAAAIgMQAKgNAAgZQAAgYgKgMQgIgNgRAAQgRAAgJANg");
    this.shape_61.setTransform(-111.6, 3);

    this.shape_62 = new cjs.Shape();
    this.shape_62.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_62.setTransform(-123.475, 3);

    this.shape_63 = new cjs.Shape();
    this.shape_63.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAXAAIAAAXg");
    this.shape_63.setTransform(-131.9, 0.775);

    this.shape_64 = new cjs.Shape();
    this.shape_64.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_64.setTransform(-146.075, 1.25);

    this.shape_65 = new cjs.Shape();
    this.shape_65.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgLAIgEQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_65.setTransform(-153.775, 3);

    this.shape_66 = new cjs.Shape();
    this.shape_66.graphics.f("#252423").s().p("AgqAyQgPgSgBggQAAgfARgTQAQgSAaAAQAaAAAPASQARASgBAgQABAhgRASQgPASgaAAQgbAAgQgTgAgZgkQgJAMAAAYQAAAYAJAOQAJAMARAAQAQAAAKgMQAJgNAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_66.setTransform(-165.4, 3);

    this.shape_67 = new cjs.Shape();
    this.shape_67.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYAAQgJAAgHgCQgIgDgIgEIAAA2gAgPhCQgJAFgIAHIAABJIAPAGQAGABAIAAQAQAAAKgNQAJgMAAgYQAAgWgHgMQgHgNgQAAQgIAAgJAEg");
    this.shape_67.setTransform(-178.075, 5.25);

    this.shape_68 = new cjs.Shape();
    this.shape_68.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYAAQgJAAgHgCQgIgDgIgEIAAA2gAgPhCQgJAFgIAHIAABJIAPAGQAGABAIAAQAQAAAKgNQAJgMAAgYQAAgWgHgMQgHgNgQAAQgIAAgJAEg");
    this.shape_68.setTransform(-191.325, 5.25);

    this.shape_69 = new cjs.Shape();
    this.shape_69.graphics.f("#252423").s().p("AgaBBQgHgDgFgGQgHgGgCgKQgEgJAAgNIAAhUIAXAAIAABJIABASQAAAHADAFQACAFAFACQAEACAKAAQAHAAAIgEQAKgFAHgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgIgCg");
    this.shape_69.setTransform(-205.15, 3.175);

    this.shape_70 = new cjs.Shape();
    this.shape_70.graphics.f("#252423").s().p("AgbBBQgMgDgHgFIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgCQAEgDACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgJAAgMQAAgTAOgLQANgKAVgBQALABALACQALADAHAEIAAAYIgBAAQgIgHgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJACIAKADIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMAAQgNAAgLgDg");
    this.shape_70.setTransform(-216.975, 3);

    this.shape_71 = new cjs.Shape();
    this.shape_71.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQAEALgBAQIAAALIhZAAQAAAWALANQAMAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgNABQgeAAgRgSgAgLgvQgGADgGAFQgFAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgEgEgFgDQgHgDgJAAQgHAAgGADg");
    this.shape_71.setTransform(-236.3, 2.975);

    this.shape_72 = new cjs.Shape();
    this.shape_72.graphics.f("#252423").s().p("AAeBbIAAhKIgBgRQgBgHgDgFQgCgEgGgDQgEgCgJAAQgHAAgJAEQgIAEgJAIIAABgIgVAAIAAi1IAVAAIAABCQAKgJAKgFQAJgEAKAAQAUAAAKAMQAMANgBAXIAABVg");
    this.shape_72.setTransform(-249.3, 0.425);

    this.shape_73 = new cjs.Shape();
    this.shape_73.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAglIAWAAIAAAlIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMABQgRAAgKgKg");
    this.shape_73.setTransform(-259.975, 1.25);

    this.shape_74 = new cjs.Shape();
    this.shape_74.graphics.f("#252423").s().p("AgXBaIgSgEIAAgXIABAAIAGADIAKADIAMACIALABQAJAAAHgDQAHgCAEgFQAEgFABgGQACgHAAgIIAAgLQgJAIgJADQgIAFgMAAQgWgBgOgQQgNgRAAgeQAAgQAFgNQAEgMAIgKQAHgIALgEQAKgFAJAAQALAAAHACIAPAHIABgFIAVAAIAAByQAAAigPAQQgOAPgdAAQgKAAgKgCgAgVg7QgKAMAAAYQAAAVAHAKQAIAMAQAAQAIAAAJgEQAJgDAHgGIAAhHQgIgEgHgBQgHgCgHAAQgQAAgJAMg");
    this.shape_74.setTransform(-278.675, 5.3);

    this.shape_75 = new cjs.Shape();
    this.shape_75.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQALANAAAYIAABUg");
    this.shape_75.setTransform(-291.5, 2.825);

    this.shape_76 = new cjs.Shape();
    this.shape_76.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAXAAIAAAXg");
    this.shape_76.setTransform(-301, 0.775);

    this.shape_77 = new cjs.Shape();
    this.shape_77.graphics.f("#252423").s().p("AAZBbIgwg/IgIAJIAAA2IgWAAIAAi1IAWAAIAABqIAzg3IAbAAIg1A2IA8BMg");
    this.shape_77.setTransform(-308.675, 0.425);

    this.shape_78 = new cjs.Shape();
    this.shape_78.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_78.setTransform(-321.075, 3);

    this.shape_79 = new cjs.Shape();
    this.shape_79.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_79.setTransform(-329.5, 0.775);

    this.shape_80 = new cjs.Shape();
    this.shape_80.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_80.setTransform(-335, 0.425);

    this.shape_81 = new cjs.Shape();
    this.shape_81.graphics.f("#252423").s().p("AgNBAQgLgEgIgIQgIgJgEgNQgFgMAAgSQAAgQAFgMQAEgNAIgJQAIgIALgFQAMgEAMgBQALAAAKAEQAKADAIADIAAAZIgBAAIgGgEIgJgGQgFgCgGgBQgGgDgGAAQgQAAgLAOQgKANAAAWQAAAYAKANQAKAMARAAQALABAJgFQAKgFAIgGIABAAIAAAYIgIAEIgJADIgKACIgMABQgMAAgMgEg");
    this.shape_81.setTransform(-343.125, 3);

    this.shape_82 = new cjs.Shape();
    this.shape_82.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_82.setTransform(-362.275, 5.425);

    this.shape_83 = new cjs.Shape();
    this.shape_83.graphics.f("#252423").s().p("Ag6BXIAAitIAuAAQASAAAJABQAKABAIAFQAJAFAFAIQAEAIAAALQAAAMgGAKQgHAKgLAFIAAABQAPAFAJAKQAIALAAARQAAANgFAKQgFAKgIAGQgKAIgLADQgLADgRAAgAgjBDIATAAIAXgBQAJgBAHgFQAGgFADgFQADgGAAgJQAAgKgDgHQgCgGgJgEQgGgDgGgBIgRgBIgbAAgAgjgPIAbAAIAPgBQAFgCAFgDQAGgDACgGQACgFAAgJQAAgGgCgEQgCgFgFgDQgGgDgGgBIgTAAIgWAAg");
    this.shape_83.setTransform(-374.725, 0.825);

    this.shape_84 = new cjs.Shape();
    this.shape_84.graphics.f("#252423").s().p("AgLBXIAAgYIAXAAIAAAYgAgJAnIgDh9IAZAAIgDB9g");
    this.shape_84.setTransform(377.325, -30.125);

    this.shape_85 = new cjs.Shape();
    this.shape_85.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgHADgFAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgEgEgGgDQgHgDgJAAQgHAAgGADg");
    this.shape_85.setTransform(367.05, -27.975);

    this.shape_86 = new cjs.Shape();
    this.shape_86.graphics.f("#252423").s().p("AgJBBIgxiBIAYAAIAjBkIAjhkIAXAAIgxCBg");
    this.shape_86.setTransform(354.725, -27.95);

    this.shape_87 = new cjs.Shape();
    this.shape_87.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgCAAIAAgXIASgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_87.setTransform(342.1, -27.925);

    this.shape_88 = new cjs.Shape();
    this.shape_88.graphics.f("#252423").s().p("AAdBbIAAhKIgBgRQAAgHgDgFQgDgEgFgDQgEgCgIAAQgIAAgIAEQgJAEgIAIIAABgIgXAAIAAi1IAXAAIAABCQAKgJAJgFQAJgEAKAAQATAAAMAMQAKANABAXIAABVg");
    this.shape_88.setTransform(329.55, -30.525);

    this.shape_89 = new cjs.Shape();
    this.shape_89.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_89.setTransform(309.325, -25.525);

    this.shape_90 = new cjs.Shape();
    this.shape_90.graphics.f("#252423").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAGgIANgGQALgFAPgCIAfgDIAAgEQAAgIgDgFQgCgEgEgDQgEgDgGAAIgMgBQgIAAgJACQgLACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAJACAIAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgKAAQgJAAgHgDgAAKAAQgLAAgHACQgJADgFAFQgHAGABAKQAAALAGAGQAGAGANAAQAJAAAIgFQAIgEAIgHIAAgjIgUACg");
    this.shape_90.setTransform(296.7, -27.925);

    this.shape_91 = new cjs.Shape();
    this.shape_91.graphics.f("#252423").s().p("AA/BDIAAhKIAAgRQgBgIgCgEQgDgFgEgCQgEgCgIAAQgHAAgHAEQgIAEgIAIIAAAFIAAAGIAABVIgVAAIAAhKIgBgRQAAgIgDgEQgCgFgEgCQgFgCgHAAQgHAAgIAEQgIAFgHAHIAABgIgWAAIAAiCIAWAAIAAAPQAIgJAJgEQAIgFALAAQALAAAJAFQAIAFAFAMQAKgLAJgGQAKgFALAAQAIAAAHACQAHADAFAGQAGAGADAJQACAJAAANIAABVg");
    this.shape_91.setTransform(280.775, -28.125);

    this.shape_92 = new cjs.Shape();
    this.shape_92.graphics.f("#252423").s().p("AgaBBQgHgDgGgGQgFgGgDgKQgDgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAKAAQAGAAAKgEQAIgFAJgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgIgCg");
    this.shape_92.setTransform(256.4, -27.775);

    this.shape_93 = new cjs.Shape();
    this.shape_93.graphics.f("#252423").s().p("AgqAzQgPgTgBggQAAggARgSQAQgSAaAAQAaAAAPASQARASgBAgQABAhgRASQgPASgaAAQgbAAgQgSgAgZgkQgJAMAAAYQAAAYAJANQAJANARAAQAQAAAKgNQAJgMAAgZQAAgYgJgMQgKgNgQAAQgRAAgJANg");
    this.shape_93.setTransform(243.2, -27.95);

    this.shape_94 = new cjs.Shape();
    this.shape_94.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_94.setTransform(230.725, -25.525);

    this.shape_95 = new cjs.Shape();
    this.shape_95.graphics.f("#252423").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgDQAEgCACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgIAAgNQAAgTAOgKQANgMAVAAQALAAALADQALADAHAEIAAAXIgBAAQgIgGgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJADIAKACIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMABQgNAAgLgEg");
    this.shape_95.setTransform(212.075, -27.95);

    this.shape_96 = new cjs.Shape();
    this.shape_96.graphics.f("#252423").s().p("AgmAzQgSgRAAghQAAgfARgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQADALABAQIAAALIhaAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgNABQgfAAgRgSgAgLgvQgHADgEAFQgFAFgDAGQgDAGAAAJIBEAAQAAgJgCgHQgCgGgDgFQgFgEgGgDQgGgDgIAAQgIAAgGADg");
    this.shape_96.setTransform(200.25, -27.975);

    this.shape_97 = new cjs.Shape();
    this.shape_97.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_97.setTransform(191.15, -30.175);

    this.shape_98 = new cjs.Shape();
    this.shape_98.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgKAIgFQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_98.setTransform(184.825, -27.95);

    this.shape_99 = new cjs.Shape();
    this.shape_99.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAYAAIAAAXg");
    this.shape_99.setTransform(177, -30.175);

    this.shape_100 = new cjs.Shape();
    this.shape_100.graphics.f("#252423").s().p("AgZBBQgIgDgGgGQgGgGgDgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAJAAQAIAAAJgEQAIgFAJgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgHgCg");
    this.shape_100.setTransform(167.5, -27.775);

    this.shape_101 = new cjs.Shape();
    this.shape_101.graphics.f("#252423").s().p("AAhBbIAAg/QgKAJgJAFQgJADgKAAQgWAAgOgRQgNgSAAgfQAAgRAFgNQAEgNAIgIQAHgJAKgFQAKgFAKABQAKgBAIADQAHACAIAFIABgGIAVAAIAACygAgWg6QgJAMAAAYQAAAWAHAMQAIANAQAAQAIAAAJgFQAJgEAHgGIAAhJQgIgFgHgBQgHgBgHgBQgQAAgKANg");
    this.shape_101.setTransform(153.775, -25.7);

    this.shape_102 = new cjs.Shape();
    this.shape_102.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgFgDQgFgCgJAAQgGAAgKAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAAKAMQALANAAAYIAABUg");
    this.shape_102.setTransform(140.95, -28.125);

    this.shape_103 = new cjs.Shape();
    this.shape_103.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgLhAIAAgXIAXAAIAAAXg");
    this.shape_103.setTransform(131.45, -30.175);

    this.shape_104 = new cjs.Shape();
    this.shape_104.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_104.setTransform(115.225, -25.525);

    this.shape_105 = new cjs.Shape();
    this.shape_105.graphics.f("#252423").s().p("AAeBDIAAhJIgBgRQgBgIgDgFQgCgEgGgDQgEgCgJAAQgHAAgJAEQgIAEgJAIIAABgIgVAAIAAiCIAVAAIAAAPQAKgJAKgFQAJgEAKAAQAUAAALAMQALANgBAYIAABUg");
    this.shape_105.setTransform(102.6, -28.125);

    this.shape_106 = new cjs.Shape();
    this.shape_106.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQALgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgCAAIAAgXIASgEQAMgCAKAAQANAAAJACQAKACAHAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_106.setTransform(89.2, -27.925);

    this.shape_107 = new cjs.Shape();
    this.shape_107.graphics.f("#252423").s().p("AAdBbIAAhKIgBgRQgBgHgCgFQgDgEgEgDQgFgCgJAAQgGAAgKAEQgIAEgIAIIAABgIgXAAIAAi1IAXAAIAABCQAKgJAJgFQAJgEAKAAQAUAAAKAMQAMANAAAXIAABVg");
    this.shape_107.setTransform(69.15, -30.525);

    this.shape_108 = new cjs.Shape();
    this.shape_108.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAgmIAWAAIAAAmIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMAAQgRABgKgKg");
    this.shape_108.setTransform(58.475, -29.7);

    this.shape_109 = new cjs.Shape();
    this.shape_109.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAYAAIAAAXg");
    this.shape_109.setTransform(51.6, -30.175);

    this.shape_110 = new cjs.Shape();
    this.shape_110.graphics.f("#252423").s().p("AAgBBIgghkIgeBkIgVAAIghiBIAWAAIAXBkIAfhkIASAAIAfBkIAWhkIAXAAIgiCBg");
    this.shape_110.setTransform(39.95, -27.95);

    this.shape_111 = new cjs.Shape();
    this.shape_111.graphics.f("#252423").s().p("AgaBBQgHgDgFgGQgHgGgCgKQgDgJgBgNIAAhUIAXAAIAABJIAAASQABAHADAFQACAFAFACQAEACAKAAQAGAAAKgEQAIgFAIgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgLAAQgIAAgIgCg");
    this.shape_111.setTransform(16.8, -27.775);

    this.shape_112 = new cjs.Shape();
    this.shape_112.graphics.f("#252423").s().p("AgpAzQgQgTgBggQAAggARgSQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgSgAgZgkQgKAMABAYQgBAYAKANQAJANARAAQARAAAIgNQAKgMAAgZQAAgYgKgMQgIgNgRAAQgRAAgJANg");
    this.shape_112.setTransform(3.6, -27.95);

    this.shape_113 = new cjs.Shape();
    this.shape_113.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_113.setTransform(-8.875, -25.525);

    this.shape_114 = new cjs.Shape();
    this.shape_114.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYgBQgJAAgHgBQgIgDgIgFIAAA3gAgPhBQgJAEgIAHIAABIIAPAHQAGABAIAAQAQAAAKgNQAJgNAAgXQAAgWgHgMQgHgNgQAAQgIAAgJAFg");
    this.shape_114.setTransform(-28.525, -25.7);

    this.shape_115 = new cjs.Shape();
    this.shape_115.graphics.f("#252423").s().p("AgKBbIAAi1IAVAAIAAC1g");
    this.shape_115.setTransform(-38.35, -30.525);

    this.shape_116 = new cjs.Shape();
    this.shape_116.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgGADgGAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgEgEgGgDQgHgDgJAAQgHAAgGADg");
    this.shape_116.setTransform(-47.4, -27.975);

    this.shape_117 = new cjs.Shape();
    this.shape_117.graphics.f("#252423").s().p("AAeBbIAAhKIgBgRQgCgHgCgFQgDgEgFgDQgEgCgIAAQgIAAgJAEQgIAEgJAIIAABgIgVAAIAAi1IAVAAIAABCQALgJAJgFQAJgEAKAAQATAAAMAMQALANgBAXIAABVg");
    this.shape_117.setTransform(-60.4, -30.525);

    this.shape_118 = new cjs.Shape();
    this.shape_118.graphics.f("#252423").s().p("AgpAzQgRgTABggQAAggAQgSQAQgSAZAAQAbAAAPASQAQASABAgQgBAhgQASQgPASgbAAQgaAAgPgSgAgZgkQgKAMAAAYQAAAYAKANQAJANAQAAQASAAAIgNQAKgMAAgZQAAgYgKgMQgIgNgSAAQgQAAgJANg");
    this.shape_118.setTransform(-81.2, -27.95);

    this.shape_119 = new cjs.Shape();
    this.shape_119.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAgmIAWAAIAAAmIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMAAQgRABgKgKg");
    this.shape_119.setTransform(-91.625, -29.7);

    this.shape_120 = new cjs.Shape();
    this.shape_120.graphics.f("#252423").s().p("AgiBaIAYg3Igwh8IAYAAIAiBgIAkhgIAXAAIhFCzg");
    this.shape_120.setTransform(-109.225, -25.525);

    this.shape_121 = new cjs.Shape();
    this.shape_121.graphics.f("#252423").s().p("AgZBYQgJgEgGgIQgHgJgDgNQgEgNAAgQQAAgRAFgLQAEgNAIgKQAHgIAKgFQAKgFAKAAQAKAAAIACIAPAHIAAg5IAWAAIAAC1IgWAAIAAgNIgJAIIgJAEIgJAEIgLABQgLAAgJgFgAgVgLQgKALAAAYQAAAYAHAMQAIAMAQAAQAIAAAIgDQAJgEAIgHIAAhLQgIgEgHgBQgGgCgIAAQgQAAgJANg");
    this.shape_121.setTransform(-122.275, -30.35);

    this.shape_122 = new cjs.Shape();
    this.shape_122.graphics.f("#252423").s().p("AgbBCQgHgDgFgGQgGgFgDgIQgDgIAAgJQAAgNAFgKQAHgIALgGQAMgFAOgCIAggDIAAgEQAAgIgDgFQgBgEgFgDQgEgDgGAAIgMgBQgHAAgLACQgKACgKAFIgBAAIAAgXIARgEQALgCALAAQANAAAJACQAKACAHAGQAHAFAEAJQADAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgEACgLAAQgIAAgHgDgAALAAQgMAAgHACQgJADgFAFQgHAGAAAKQABALAGAGQAGAGANAAQAJAAAIgFQAJgEAHgHIAAgjIgTACg");
    this.shape_122.setTransform(-135.1, -27.925);

    this.shape_123 = new cjs.Shape();
    this.shape_123.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAKAEQAJADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWALANQAMAMATAAQAIAAAGgCIANgEIAKgFIAHgFIABAAIAAAYIgJAEIgLADIgMADIgMABQgfAAgRgSgAgLgvQgGADgGAFQgEAFgDAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgDgFQgEgEgGgDQgHgDgJAAQgHAAgGADg");
    this.shape_123.setTransform(-147.3, -27.975);

    this.shape_124 = new cjs.Shape();
    this.shape_124.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgKAIgFQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_124.setTransform(-157.225, -27.95);

    this.shape_125 = new cjs.Shape();
    this.shape_125.graphics.f("#252423").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgDQAEgCACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgIAAgNQAAgTAOgKQANgMAVAAQALAAALADQALADAHAEIAAAXIgBAAQgIgGgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJADIAKACIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMABQgNAAgLgEg");
    this.shape_125.setTransform(-174.975, -27.95);

    this.shape_126 = new cjs.Shape();
    this.shape_126.graphics.f("#252423").s().p("AgKBYIAAiCIAVAAIAACCgAgMhAIAAgXIAZAAIAAAXg");
    this.shape_126.setTransform(-183.25, -30.175);

    this.shape_127 = new cjs.Shape();
    this.shape_127.graphics.f("#252423").s().p("AA/BDIAAhKIAAgRQgBgIgCgEQgDgFgEgCQgEgCgIAAQgHAAgHAEQgIAEgIAIIAAAFIAAAGIAABVIgVAAIAAhKIgBgRQAAgIgDgEQgCgFgEgCQgFgCgHAAQgHAAgIAEQgIAFgHAHIAABgIgWAAIAAiCIAWAAIAAAPQAIgJAJgEQAIgFALAAQALAAAJAFQAIAFAFAMQAKgLAJgGQAKgFALAAQAIAAAHACQAHADAFAGQAGAGADAJQACAJAAANIAABVg");
    this.shape_127.setTransform(-203.525, -28.125);

    this.shape_128 = new cjs.Shape();
    this.shape_128.graphics.f("#252423").s().p("AgbBCQgHgDgGgGQgFgFgDgIQgDgIAAgJQAAgNAGgKQAGgIAMgGQAKgFAPgCIAggDIAAgEQAAgIgCgFQgCgEgFgDQgEgDgGAAIgLgBQgJAAgJACQgLACgKAFIgCAAIAAgXIASgEQALgCAMAAQAMAAAJACQAJACAIAGQAHAFADAJQAEAIAAANIAABYIgWAAIAAgOIgHAGIgJAGIgLAEQgFACgKAAQgHAAgIgDgAALAAQgMAAgHACQgJADgGAFQgFAGgBAKQAAALAHAGQAGAGAMAAQAKAAAIgFQAIgEAIgHIAAgjIgTACg");
    this.shape_128.setTransform(-220.3, -27.925);

    this.shape_129 = new cjs.Shape();
    this.shape_129.graphics.f("#252423").s().p("AgmAzQgRgRAAghQAAgfAQgTQARgTAaAAQAMAAAJAEQAKADAHAIQAHAHAEALQADALAAAQIAAALIhZAAQAAAWAMANQALAMATAAQAHAAAIgCIAMgEIAKgFIAHgFIABAAIAAAYIgKAEIgKADIgLADIgOABQgeAAgRgSgAgLgvQgGADgFAFQgGAFgCAGQgCAGgBAJIBEAAQAAgJgCgHQgCgGgEgFQgDgEgHgDQgFgDgKAAQgHAAgGADg");
    this.shape_129.setTransform(-232.5, -27.975);

    this.shape_130 = new cjs.Shape();
    this.shape_130.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAgmIAWAAIAAAmIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMAAQgRABgKgKg");
    this.shape_130.setTransform(-242.775, -29.7);

    this.shape_131 = new cjs.Shape();
    this.shape_131.graphics.f("#252423").s().p("AgNBMQgKgLAAgWIAAhIIgPAAIAAgSIAPAAIAAgmIAWAAIAAAmIAoAAIAAASIgoAAIAAA9IAAAQQABAGABAEQACAEAFADQAEABAIAAQAFAAAFgBIAHgDIACAAIAAAUIgNADIgMAAQgRABgKgKg");
    this.shape_131.setTransform(-258.325, -29.7);

    this.shape_132 = new cjs.Shape();
    this.shape_132.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgKAIgFQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_132.setTransform(-266.025, -27.95);

    this.shape_133 = new cjs.Shape();
    this.shape_133.graphics.f("#252423").s().p("AgpAzQgQgTgBggQAAggARgSQAQgSAaAAQAaAAAPASQAQASAAAgQAAAhgQASQgPASgaAAQgbAAgPgSgAgZgkQgKAMABAYQgBAYAKANQAJANARAAQARAAAIgNQAKgMAAgZQAAgYgKgMQgIgNgRAAQgRAAgJANg");
    this.shape_133.setTransform(-277.65, -27.95);

    this.shape_134 = new cjs.Shape();
    this.shape_134.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYgBQgJAAgHgBQgIgDgIgFIAAA3gAgPhBQgJAEgIAHIAABIIAPAHQAGABAIAAQAQAAAKgNQAJgNAAgXQAAgWgHgMQgHgNgQAAQgIAAgJAFg");
    this.shape_134.setTransform(-290.325, -25.7);

    this.shape_135 = new cjs.Shape();
    this.shape_135.graphics.f("#252423").s().p("Ag2BbIAAiyIAWAAIAAAOQAIgHALgGQAJgEALAAQAXgBAMATQANARAAAeQAAAggQATQgPAUgYgBQgJAAgHgBQgIgDgIgFIAAA3gAgPhBQgJAEgIAHIAABIIAPAHQAGABAIAAQAQAAAKgNQAJgNAAgXQAAgWgHgMQgHgNgQAAQgIAAgJAFg");
    this.shape_135.setTransform(-303.575, -25.7);

    this.shape_136 = new cjs.Shape();
    this.shape_136.graphics.f("#252423").s().p("AgZBBQgIgDgGgGQgFgGgEgKQgDgJAAgNIAAhUIAXAAIAABJIABASQAAAHADAFQADAFAEACQAFACAIAAQAIAAAIgEQAKgFAHgGIAAhhIAXAAIAACCIgXAAIAAgPQgKAJgJAFQgJAEgKAAQgJAAgHgCg");
    this.shape_136.setTransform(-317.4, -27.775);

    this.shape_137 = new cjs.Shape();
    this.shape_137.graphics.f("#252423").s().p("AgbBBQgMgEgHgEIAAgYIABAAIAGAFIALAGIAMAEQAHACAHAAIAKgBQAGgBADgDQAEgCACgDQACgDAAgGQAAgIgEgEQgFgEgLgDIgKgCIgMgDQgPgEgHgJQgHgIAAgNQAAgTAOgKQANgMAVAAQALAAALADQALADAHAEIAAAXIgBAAQgIgGgKgEQgLgEgKAAQgKAAgIAFQgHAEAAAJQAAAJAEAEQAFAEAJADIAKACIANACQAPAEAHAIQAHAIAAAPQAAAIgDAIQgDAIgHAEQgHAHgJACQgJADgMABQgNAAgLgEg");
    this.shape_137.setTransform(-329.225, -27.95);

    this.shape_138 = new cjs.Shape();
    this.shape_138.graphics.f("#252423").s().p("AgiBBIAAiBIAWAAIAAATQAMgKAIgFQAJgFAJABIAFAAIAEABIAAAWIgCAAIgFgBIgGAAQgJAAgJAEQgIAEgIAIIAABbg");
    this.shape_138.setTransform(-345.825, -27.95);

    this.shape_139 = new cjs.Shape();
    this.shape_139.graphics.f("#252423").s().p("AgZBBQgIgDgGgGQgGgGgDgKQgCgJAAgNIAAhUIAVAAIAABJIABASQABAHADAFQADAFAEACQAEACAJAAQAHAAAJgEQAJgFAJgGIAAhhIAVAAIAACCIgVAAIAAgPQgLAJgJAFQgJAEgLAAQgIAAgHgCg");
    this.shape_139.setTransform(-357.65, -27.775);

    this.shape_140 = new cjs.Shape();
    this.shape_140.graphics.f("#252423").s().p("AgfBVQgOgGgKgMQgKgMgGgQQgFgRAAgWQAAgVAFgRQAGgQAKgMQAKgMAOgGQAPgGAQAAQASAAAOAGQAPAHAJALQAKALAGARQAFARAAAVQAAAVgFARQgFARgLAMQgKAMgOAGQgPAGgRAAQgRAAgOgGgAgVhBQgJAEgIAJQgGAJgEAOQgEANAAAQQAAAiAPATQAOASAXAAQAYAAAPgSQAOgTAAgiQAAgQgEgOQgDgNgHgJQgHgJgKgEQgKgFgMAAQgLAAgKAFg");
    this.shape_140.setTransform(-372.825, -30.125);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_140
      }, {
        t: this.shape_139
      }, {
        t: this.shape_138
      }, {
        t: this.shape_137
      }, {
        t: this.shape_136
      }, {
        t: this.shape_135
      }, {
        t: this.shape_134
      }, {
        t: this.shape_133
      }, {
        t: this.shape_132
      }, {
        t: this.shape_131
      }, {
        t: this.shape_130
      }, {
        t: this.shape_129
      }, {
        t: this.shape_128
      }, {
        t: this.shape_127
      }, {
        t: this.shape_126
      }, {
        t: this.shape_125
      }, {
        t: this.shape_124
      }, {
        t: this.shape_123
      }, {
        t: this.shape_122
      }, {
        t: this.shape_121
      }, {
        t: this.shape_120
      }, {
        t: this.shape_119
      }, {
        t: this.shape_118
      }, {
        t: this.shape_117
      }, {
        t: this.shape_116
      }, {
        t: this.shape_115
      }, {
        t: this.shape_114
      }, {
        t: this.shape_113
      }, {
        t: this.shape_112
      }, {
        t: this.shape_111
      }, {
        t: this.shape_110
      }, {
        t: this.shape_109
      }, {
        t: this.shape_108
      }, {
        t: this.shape_107
      }, {
        t: this.shape_106
      }, {
        t: this.shape_105
      }, {
        t: this.shape_104
      }, {
        t: this.shape_103
      }, {
        t: this.shape_102
      }, {
        t: this.shape_101
      }, {
        t: this.shape_100
      }, {
        t: this.shape_99
      }, {
        t: this.shape_98
      }, {
        t: this.shape_97
      }, {
        t: this.shape_96
      }, {
        t: this.shape_95
      }, {
        t: this.shape_94
      }, {
        t: this.shape_93
      }, {
        t: this.shape_92
      }, {
        t: this.shape_91
      }, {
        t: this.shape_90
      }, {
        t: this.shape_89
      }, {
        t: this.shape_88
      }, {
        t: this.shape_87
      }, {
        t: this.shape_86
      }, {
        t: this.shape_85
      }, {
        t: this.shape_84
      }, {
        t: this.shape_83
      }, {
        t: this.shape_82
      }, {
        t: this.shape_81
      }, {
        t: this.shape_80
      }, {
        t: this.shape_79
      }, {
        t: this.shape_78
      }, {
        t: this.shape_77
      }, {
        t: this.shape_76
      }, {
        t: this.shape_75
      }, {
        t: this.shape_74
      }, {
        t: this.shape_73
      }, {
        t: this.shape_72
      }, {
        t: this.shape_71
      }, {
        t: this.shape_70
      }, {
        t: this.shape_69
      }, {
        t: this.shape_68
      }, {
        t: this.shape_67
      }, {
        t: this.shape_66
      }, {
        t: this.shape_65
      }, {
        t: this.shape_64
      }, {
        t: this.shape_63
      }, {
        t: this.shape_62
      }, {
        t: this.shape_61
      }, {
        t: this.shape_60
      }, {
        t: this.shape_59
      }, {
        t: this.shape_58
      }, {
        t: this.shape_57
      }, {
        t: this.shape_56
      }, {
        t: this.shape_55
      }, {
        t: this.shape_54
      }, {
        t: this.shape_53
      }, {
        t: this.shape_52
      }, {
        t: this.shape_51
      }, {
        t: this.shape_50
      }, {
        t: this.shape_49
      }, {
        t: this.shape_48
      }, {
        t: this.shape_47
      }, {
        t: this.shape_46
      }, {
        t: this.shape_45
      }, {
        t: this.shape_44
      }, {
        t: this.shape_43
      }, {
        t: this.shape_42
      }, {
        t: this.shape_41
      }, {
        t: this.shape_40
      }, {
        t: this.shape_39
      }, {
        t: this.shape_38
      }, {
        t: this.shape_37
      }, {
        t: this.shape_36
      }, {
        t: this.shape_35
      }, {
        t: this.shape_34
      }, {
        t: this.shape_33
      }, {
        t: this.shape_32
      }, {
        t: this.shape_31
      }, {
        t: this.shape_30
      }, {
        t: this.shape_29
      }, {
        t: this.shape_28
      }, {
        t: this.shape_27
      }, {
        t: this.shape_26
      }, {
        t: this.shape_25
      }, {
        t: this.shape_24
      }, {
        t: this.shape_23
      }, {
        t: this.shape_22
      }, {
        t: this.shape_21
      }, {
        t: this.shape_20
      }, {
        t: this.shape_19
      }, {
        t: this.shape_18
      }, {
        t: this.shape_17
      }, {
        t: this.shape_16
      }, {
        t: this.shape_15
      }, {
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400.6, -47.4, 801.2, 94.9);


  (lib.Tween87 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("AglCLQgVgTAAgrIAAhwIgdAAIAAg1IAdAAIAAhEIBKAAIAABEIBIAAIAAA1IhIAAIAABUIAAAXQAAAJADAIQADAHAIAFQAIAEAOAAQAGAAAKgDIAOgEIAGAAIAAA1IgaAFQgOABgUAAQgtAAgUgSg");
    this.shape.setTransform(78.925, 2.175);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#252423").s().p("AhQB5IAAjwIBLAAIAAAiIANgMQAJgHAIgEQAIgFALgDQALgEAKAAIAHAAIAJABIAABHIgGAAIgNgDIgSAAQgMAAgMAEQgOADgLAEIAAChg");
    this.shape_1.setTransform(61.55, 5.35);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#252423").s().p("AhbBeQghgiAAg8QAAg7AhgiQAhgiA6AAQA7AAAhAiQAhAiAAA7QAAA8ghAiQggAig8AAQg6AAghgigAgRhGQgIADgHAJQgHAIgEAPQgDAPAAAVQAAAWADAOQAEANAGAIQAGAJAJADQAJAEAKAAQAJAAAJgEQAJgDAGgIQAHgJADgNQAEgNAAgXQAAgVgEgOQgEgOgGgJQgGgJgJgDQgIgEgLAAQgJAAgIADg");
    this.shape_2.setTransform(36.975, 5.375);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#252423").s().p("Ah1CoIAAlJIBLAAIAAAaQAQgPARgIQARgJAWAAQAqAAAXAhQAXAhAAA6QAAA5gdAjQgeAjgrAAQgRAAgNgEQgNgEgPgIIAABkgAgWhqQgLAEgJAGIAAB5QAGACAHABIARABQAbAAANgSQANgRAAgkQAAgjgLgQQgLgQgWAAQgKAAgJADg");
    this.shape_3.setTransform(10.425, 9.475);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#252423").s().p("Ah1CoIAAlJIBLAAIAAAaQAQgPARgIQARgJAWAAQAqAAAXAhQAXAhAAA6QAAA5gdAjQgeAjgrAAQgRAAgNgEQgNgEgPgIIAABkgAgWhqQgLAEgJAGIAAB5QAGACAHABIARABQAbAAANgSQANgRAAgkQAAgjgLgQQgLgQgWAAQgKAAgJADg");
    this.shape_4.setTransform(-17.275, 9.475);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#252423").s().p("AhcBlQgUgWAAgtIAAidIBMAAIAAB4IABAeQAAALAEAIQADAIAIAEQAHAEANAAQAHAAALgEQAKgEAKgHIAAiqIBLAAIAADxIhLAAIAAgbQgUAQgQAJQgPAIgXAAQgkAAgUgXg");
    this.shape_5.setTransform(-46.15, 5.7);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#252423").s().p("AhLCeQgcgHgYgKIAAhMIAIAAQAYAUAdALQAdALAbAAIARgBQAMgCAGgDQAJgEAGgGQAGgHAAgLQAAgMgKgHQgKgIgNgDIghgIQgTgEgQgGQglgNgRgVQgPgWAAghQAAgtAmgcQAmgcA3AAQAdAAAdAGQAbAGAXAKIAABJIgHAAQgSgQgZgKQgagLgbAAQgLAAgHACQgIABgKAFQgHADgGAHQgFAHAAAIQgBAMAKAHQAIAIAZAFIAeAHQAPAEARAGQAhAMAQATQAQAVABAhQgBAwgkAdQgmAdg/AAQglAAgcgIg");
    this.shape_6.setTransform(-73.7, 1.475);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-90, -28.5, 180.1, 57.1);


  (lib.Tween86 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("AglCLQgVgTAAgrIAAhwIgdAAIAAg1IAdAAIAAhEIBKAAIAABEIBIAAIAAA1IhIAAIAABUIAAAXQAAAJADAIQADAHAIAFQAIAEAOAAQAGAAAKgDIAOgEIAGAAIAAA1IgaAFQgOABgUAAQgtAAgUgSg");
    this.shape.setTransform(78.925, 2.175);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#252423").s().p("AhQB5IAAjwIBLAAIAAAiIANgMQAJgHAIgEQAIgFALgDQALgEAKAAIAHAAIAJABIAABHIgGAAIgNgDIgSAAQgMAAgMAEQgOADgLAEIAAChg");
    this.shape_1.setTransform(61.55, 5.35);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#252423").s().p("AhbBeQghgiAAg8QAAg7AhgiQAhgiA6AAQA7AAAhAiQAhAiAAA7QAAA8ghAiQggAig8AAQg6AAghgigAgRhGQgIADgHAJQgHAIgEAPQgDAPAAAVQAAAWADAOQAEANAGAIQAGAJAJADQAJAEAKAAQAJAAAJgEQAJgDAGgIQAHgJADgNQAEgNAAgXQAAgVgEgOQgEgOgGgJQgGgJgJgDQgIgEgLAAQgJAAgIADg");
    this.shape_2.setTransform(36.975, 5.375);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#252423").s().p("Ah1CoIAAlJIBLAAIAAAaQAQgPARgIQARgJAWAAQAqAAAXAhQAXAhAAA6QAAA5gdAjQgeAjgrAAQgRAAgNgEQgNgEgPgIIAABkgAgWhqQgLAEgJAGIAAB5QAGACAHABIARABQAbAAANgSQANgRAAgkQAAgjgLgQQgLgQgWAAQgKAAgJADg");
    this.shape_3.setTransform(10.425, 9.475);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#252423").s().p("Ah1CoIAAlJIBLAAIAAAaQAQgPARgIQARgJAWAAQAqAAAXAhQAXAhAAA6QAAA5gdAjQgeAjgrAAQgRAAgNgEQgNgEgPgIIAABkgAgWhqQgLAEgJAGIAAB5QAGACAHABIARABQAbAAANgSQANgRAAgkQAAgjgLgQQgLgQgWAAQgKAAgJADg");
    this.shape_4.setTransform(-17.275, 9.475);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#252423").s().p("AhcBlQgUgWAAgtIAAidIBMAAIAAB4IABAeQAAALAEAIQADAIAIAEQAHAEANAAQAHAAALgEQAKgEAKgHIAAiqIBLAAIAADxIhLAAIAAgbQgUAQgQAJQgPAIgXAAQgkAAgUgXg");
    this.shape_5.setTransform(-46.15, 5.7);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#252423").s().p("AhLCeQgcgHgYgKIAAhMIAIAAQAYAUAdALQAdALAbAAIARgBQAMgCAGgDQAJgEAGgGQAGgHAAgLQAAgMgKgHQgKgIgNgDIghgIQgTgEgQgGQglgNgRgVQgPgWAAghQAAgtAmgcQAmgcA3AAQAdAAAdAGQAbAGAXAKIAABJIgHAAQgSgQgZgKQgagLgbAAQgLAAgHACQgIABgKAFQgHADgGAHQgFAHAAAIQgBAMAKAHQAIAIAZAFIAeAHQAPAEARAGQAhAMAQATQAQAVABAhQgBAwgkAdQgmAdg/AAQglAAgcgIg");
    this.shape_6.setTransform(-73.7, 1.475);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-90, -28.5, 180.1, 57.1);


  (lib.Tween85 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.support_ico_x3();
    this.instance.setTransform(-134.6, -134.6, 0.5137, 0.5137);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-134.6, -134.6, 269.2, 269.2);


  (lib.Tween84 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.support_ico_x3();
    this.instance.setTransform(-134.6, -134.6, 0.5137, 0.5137);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-134.6, -134.6, 269.2, 269.2);


  (lib.Tween83 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("Eg/uA3oMAAAhvOMB/dAAAMAAABvOg");
    this.shape.setTransform(0.025, 0);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-407.9, -355.9, 815.9, 711.9);


  (lib.Tween82 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("Eg/uA3nMAAAhvOMB/dAAAMAAABvOg");
    this.shape.setTransform(0.025, 0);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-407.9, -355.9, 815.9, 711.9);


  (lib.Tween81 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#E99F2F").s().p("AgMAQIAAgfIAZAAIAAAfg");
    this.shape.setTransform(312.375, 13.3);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#E99F2F").s().p("Ag0BYIAAirIAWAAIAAANQAHgHAKgFQAKgFAKAAQAWAAAMARQAMARAAAdQgBAfgPASQgOATgXAAQgJAAgHgCQgHgDgHgEIAAA1gAgPg/QgIAEgHAHIAABGQAHAEAGABQAHACAHAAQAPAAAKgMQAIgMABgWQAAgWgHgMQgHgMgQAAQgHAAgJAEg");
    this.shape_1.setTransform(303, 10.775);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgGQgFgGgDgIQgDgJAAgNIAAhRIAVAAIAABHIABARQAAAGADAFQACAFAFACQAEADAJgBQAGABAJgFQAJgFAHgGIAAhdIAVAAIAAB9IgVAAIAAgOQgKAJgIAEQgJAFgKgBQgIAAgHgCg");
    this.shape_2.setTransform(289.725, 8.8);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#E99F2F").s().p("AAfA/IgfhgIgdBgIgUAAIggh9IAWAAIAWBgIAehgIAQAAIAfBgIAUhgIAWAAIggB9g");
    this.shape_3.setTransform(267.65, 8.625);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAZAAAPARQAQASAAAeQAAAggQARQgPARgZAAQgZAAgPgSgAgZgjQgJAMAAAXQAAAYAJAMQAKAMAPAAQARAAAJgMQAIgMAAgYQAAgXgIgMQgJgMgRAAQgPAAgKAMg");
    this.shape_4.setTransform(252.85, 8.625);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_5.setTransform(240.225, 6.175);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAKAGIAMAEQAGACAHAAIAKgBQAGgBADgCIAFgGQABgDAAgFQAAgIgDgEQgEgEgLgCIgKgDIgLgDQgPgEgHgHQgGgJAAgMQAAgRANgLQANgLAUAAQAKAAALADQALACAGAEIAAAXIgBAAQgHgGgLgEQgJgEgKAAQgKAAgHAFQgHAEgBAJQABAIAEAEQAEADAJADIAKACIANADQANADAHAIQAIAIAAAOQgBAIgDAHQgDAHgHAFQgGAGgIADQgJADgMAAQgNAAgKgEg");
    this.shape_6.setTransform(228.8, 8.625);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhEIgOAAIAAgSIAOAAIAAgkIAVAAIAAAkIAmAAIAAASIgmAAIAAA6IAAAPQABAFABAFQACADAFADQADACAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_7.setTransform(212.55, 6.95);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAZAAAPARQAQASAAAeQAAAggQARQgPARgZAAQgZAAgPgSgAgYgjQgKAMAAAXQAAAYAKAMQAJAMAPAAQARAAAJgMQAIgMAAgYQAAgXgIgMQgJgMgRAAQgPAAgJAMg");
    this.shape_8.setTransform(202.35, 8.625);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#E99F2F").s().p("AAcBBIAAhHIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABdIgVAAIAAh9IAVAAIAAAOQAJgIAJgFQAJgEAKgBQASAAALANQAKAMAAAXIAABRg");
    this.shape_9.setTransform(189.725, 8.45);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#E99F2F").s().p("AgYBUQgJgEgGgIQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHADAHAEIAAg2IAWAAIAACuIgWAAIAAgOIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhIQgHgDgHgBQgGgCgHAAQgPAAgKAMg");
    this.shape_10.setTransform(169.275, 6.35);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAVAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_11.setTransform(160.75, 6.475);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#E99F2F").s().p("AgYBUQgJgEgGgIQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHADAHAEIAAg2IAWAAIAACuIgWAAIAAgOIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhIQgHgDgHgBQgGgCgHAAQgPAAgKAMg");
    this.shape_12.setTransform(151.325, 6.35);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIABAAIAHAEIAKAGIALAEQAHACAHAAIAKgBQAGgBADgCIAFgGQACgDAAgFQAAgIgFgEQgDgEgLgCIgKgDIgMgDQgOgEgGgHQgHgJAAgMQAAgRANgLQAMgLAVAAQALAAAKADQAKACAIAEIAAAXIgCAAQgHgGgLgEQgJgEgLAAQgJAAgHAFQgIAEAAAJQAAAIAFAEQAEADAJADIAKACIANADQANADAHAIQAIAIgBAOQAAAIgDAHQgDAHgGAFQgHAGgIADQgJADgMAAQgMAAgMgEg");
    this.shape_13.setTransform(133.25, 8.625);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhEIgOAAIAAgSIAOAAIAAgkIAVAAIAAAkIAmAAIAAASIgmAAIAAA6IAAAPQABAFABAFQACADAEADQAFACAHAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_14.setTransform(124.2, 6.95);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQACgDgBgFQAAgIgDgEQgFgEgKgCIgJgDIgMgDQgPgEgHgHQgGgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQAKACAHAEIAAAXIAAAAQgJgGgKgEQgJgEgKAAQgKAAgHAFQgHAEgBAJQABAIAEAEQAEADAKADIAJACIANADQANADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgIADQgJADgMAAQgNAAgKgEg");
    this.shape_15.setTransform(115.3, 8.625);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_16.setTransform(103.975, 8.6);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgGQgFgGgDgIQgDgJAAgNIAAhRIAVAAIAABHIABARQAAAGADAFQACAFAFACQAEADAJgBQAGABAJgFQAJgFAHgGIAAhdIAVAAIAAB9IgVAAIAAgOQgKAJgIAEQgJAFgKgBQgIAAgHgCg");
    this.shape_17.setTransform(91.425, 8.8);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics.f("#E99F2F").s().p("AgWBXIgSgEIAAgWIACAAIAGACIAJADIALACIALABQAJAAAGgCQAHgDAEgEQAEgFABgGIABgOIAAgLQgIAIgIADQgIAEgMAAQgVAAgNgQQgNgQAAgdQAAgQAFgMQAEgMAIgIQAHgIAJgFQAKgEAJAAQAKAAAHACQAHACAHAFIACgGIAUAAIAABuQAAAggOAPQgOAPgcAAIgTgBgAgVg4QgJALAAAWQAAAVAHAKQAHALAQAAQAHAAAJgDQAJgDAGgGIAAhEIgOgGIgNgBQgPAAgKAMg");
    this.shape_18.setTransform(78.275, 10.825);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgSIAOAAIAAgDQAAgYALgLQALgMATAAIAMABIAKABIAAAUIgBAAIgIgCIgKgBQgNAAgGAFQgEAGgBARIAAADIAjAAIAAASIgiAAIAABrg");
    this.shape_19.setTransform(62.1, 6.1);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAVAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_20.setTransform(55.25, 6.475);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics.f("#E99F2F").s().p("AAfA/IgfhgIgeBgIgTAAIggh9IAWAAIAWBgIAehgIAQAAIAfBgIAUhgIAWAAIggB9g");
    this.shape_21.setTransform(36.9, 8.625);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAZAAAQARQAPASAAAeQAAAggPARQgQARgZAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAKgMQAIgMAAgYQAAgXgIgMQgKgMgQAAQgQAAgJAMg");
    this.shape_22.setTransform(22.1, 8.625);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_23.setTransform(9.475, 6.175);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAKAGIALAEQAHACAHAAIAKgBQAFgBAEgCIAFgGQABgDAAgFQAAgIgDgEQgEgEgLgCIgKgDIgLgDQgPgEgGgHQgHgJAAgMQAAgRANgLQANgLAUAAQAKAAALADQALACAGAEIAAAXIgBAAQgHgGgLgEQgKgEgKAAQgJAAgHAFQgIAEAAAJQABAIAEAEQAEADAJADIAKACIANADQANADAHAIQAIAIAAAOQgBAIgDAHQgDAHgHAFQgGAGgIADQgJADgMAAQgMAAgLgEg");
    this.shape_24.setTransform(-1.95, 8.625);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgJAMAAAXQAAAYAJAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_25.setTransform(-20.7, 8.625);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics.f("#E99F2F").s().p("AAcBBIAAhHIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABdIgVAAIAAh9IAVAAIAAAOQAJgIAJgFQAJgEAKgBQASAAALANQAKAMAAAXIAABRg");
    this.shape_26.setTransform(-33.325, 8.45);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_27.setTransform(-50.425, 8.625);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgKAMABAXQgBAYAKAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_28.setTransform(-61.55, 8.625);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics.f("#E99F2F").s().p("AgYBUQgJgEgGgIQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHADAHAEIAAg2IAWAAIAACuIgWAAIAAgOIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhIQgHgDgHgBQgGgCgHAAQgPAAgKAMg");
    this.shape_29.setTransform(-81.775, 6.35);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_30.setTransform(-93.725, 8.6);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics.f("#E99F2F").s().p("AgKBXIAAitIAVAAIAACtg");
    this.shape_31.setTransform(-102.425, 6.175);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics.f("#E99F2F").s().p("AgKBXIAAitIAVAAIAACtg");
    this.shape_32.setTransform(-107.675, 6.175);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_33.setTransform(-116.325, 8.6);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics.f("#E99F2F").s().p("AgMA+QgLgEgHgJQgIgIgEgMQgFgMAAgRQAAgPAFgMQAEgMAIgJQAHgIALgEQALgFAMAAQAKAAAKADQAJADAIAEIAAAYIgBAAIgGgFIgJgFIgKgEIgLgBQgQAAgKAMQgKANAAAVQAAAWAKANQAJAMARAAQAKAAAJgEQAKgEAHgHIABAAIAAAYIgIADIgIADIgKADIgLABQgMAAgLgEg");
    this.shape_34.setTransform(-127.575, 8.625);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics.f("#E99F2F").s().p("AAcBBIAAhHIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABdIgVAAIAAh9IAVAAIAAAOQAJgIAJgFQAJgEAKgBQASAAALANQAKAMAAAXIAABRg");
    this.shape_35.setTransform(-139.375, 8.45);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgGQALgFAOgBIAegDIAAgEQAAgIgDgEQgCgEgDgDQgFgCgFgCIgMgBQgHAAgKADQgJACgLAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAHAFQAHAGAEAIQADAIAAALIAABWIgVAAIAAgOIgHAGIgIAFIgLAFQgFABgJAAQgHAAgIgDgAAKgBIgSADQgIADgFAFQgGAFAAAKQAAAKAGAGQAGAFAMABQAIAAAJgFQAIgEAHgHIAAghIgTABg");
    this.shape_36.setTransform(-152.2, 8.65);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics.f("#E99F2F").s().p("AgMA+QgLgEgHgJQgIgIgEgMQgFgMAAgRQAAgPAFgMQAEgMAIgJQAHgIALgEQALgFAMAAQAKAAAKADQAJADAIAEIAAAYIgBAAIgGgFIgJgFIgKgEIgLgBQgQAAgKAMQgKANAAAVQAAAWAKANQAJAMARAAQAKAAAJgEQAKgEAHgHIABAAIAAAYIgIADIgIADIgKADIgLABQgMAAgLgEg");
    this.shape_37.setTransform(-163.025, 8.625);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgYgjQgJAMAAAXQAAAYAJAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_38.setTransform(-181.9, 8.625);

    this.shape_39 = new cjs.Shape();
    this.shape_39.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhEIgOAAIAAgSIAOAAIAAgkIAVAAIAAAkIAmAAIAAASIgmAAIAAA6IAAAPQABAFABAFQACADAEADQAEACAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgQAAgKgKg");
    this.shape_39.setTransform(-191.9, 6.95);

    this.shape_40 = new cjs.Shape();
    this.shape_40.graphics.f("#E99F2F").s().p("AAcBBIAAhHIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABdIgVAAIAAh9IAVAAIAAAOQAJgIAJgFQAJgEAKgBQASAAALANQAKAMAAAXIAABRg");
    this.shape_40.setTransform(-209.425, 8.45);

    this.shape_41 = new cjs.Shape();
    this.shape_41.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAJgMQAJgMAAgYQAAgXgJgMQgJgMgQAAQgQAAgJAMg");
    this.shape_41.setTransform(-222.15, 8.625);

    this.shape_42 = new cjs.Shape();
    this.shape_42.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAUAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_42.setTransform(-231, 6.475);

    this.shape_43 = new cjs.Shape();
    this.shape_43.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhEIgOAAIAAgSIAOAAIAAgkIAVAAIAAAkIAmAAIAAASIgmAAIAAA6IABAPQAAAFABAFQACADAFADQAEACAHAAIAKgCIAHgDIABAAIAAATIgMADIgMABQgQAAgJgKg");
    this.shape_43.setTransform(-237.4, 6.95);

    this.shape_44 = new cjs.Shape();
    this.shape_44.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgGQAKgFAPgBIAegDIAAgEQAAgIgDgEQgCgEgDgDQgFgCgGgCIgLgBQgHAAgKADQgJACgLAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAHAFQAHAGADAIQAEAIAAALIAABWIgVAAIAAgOIgHAGIgIAFIgLAFQgEABgKAAQgHAAgIgDgAAKgBIgSADQgIADgFAFQgGAFAAAKQAAAKAGAGQAGAFAMABQAJAAAIgFQAIgEAHgHIAAghIgTABg");
    this.shape_44.setTransform(-247.7, 8.65);

    this.shape_45 = new cjs.Shape();
    this.shape_45.graphics.f("#E99F2F").s().p("AgJA/Igvh9IAXAAIAiBgIAihgIAWAAIgwB9g");
    this.shape_45.setTransform(-259.075, 8.625);

    this.shape_46 = new cjs.Shape();
    this.shape_46.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_46.setTransform(-268.225, 8.625);

    this.shape_47 = new cjs.Shape();
    this.shape_47.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_47.setTransform(-279.125, 8.6);

    this.shape_48 = new cjs.Shape();
    this.shape_48.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQABgDAAgFQAAgIgDgEQgFgEgKgCIgKgDIgLgDQgPgEgHgHQgGgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQAKACAHAEIAAAXIgBAAQgIgGgKgEQgJgEgKAAQgKAAgHAFQgHAEgBAJQABAIAEAEQAEADAJADIAKACIANADQANADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgIADQgJADgMAAQgNAAgKgEg");
    this.shape_48.setTransform(-290.15, 8.625);

    this.shape_49 = new cjs.Shape();
    this.shape_49.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_49.setTransform(-301.475, 8.6);

    this.shape_50 = new cjs.Shape();
    this.shape_50.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_50.setTransform(-310.975, 8.625);

    this.shape_51 = new cjs.Shape();
    this.shape_51.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_51.setTransform(383.575, -21.15);

    this.shape_52 = new cjs.Shape();
    this.shape_52.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_52.setTransform(371.125, -23.575);

    this.shape_53 = new cjs.Shape();
    this.shape_53.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAEQACAFAEABQAFACAHAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgRAAgJgKg");
    this.shape_53.setTransform(360.9, -22.8);

    this.shape_54 = new cjs.Shape();
    this.shape_54.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgRIAOAAIAAgFQAAgXALgLQALgMATAAIAMABIAKABIAAATIgBAAIgJgCIgJgBQgNAAgGAHQgEAFgBAQIAAAFIAiAAIAAARIghAAIAABrg");
    this.shape_54.setTransform(346.7, -23.65);

    this.shape_55 = new cjs.Shape();
    this.shape_55.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAZAAAQARQAPASAAAeQAAAggPARQgQARgZAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAKgMQAIgMAAgYQAAgXgIgMQgKgMgQAAQgQAAgJAMg");
    this.shape_55.setTransform(336.2, -21.125);

    this.shape_56 = new cjs.Shape();
    this.shape_56.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQACgDgBgFQAAgIgDgEQgFgEgKgCIgKgDIgLgDQgPgEgHgHQgGgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQAKACAHAEIAAAXIgBAAQgIgGgKgEQgJgEgKAAQgKAAgHAFQgHAEgBAJQABAIAEAEQAEADAJADIAKACIANADQANADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgIADQgJADgMAAQgNAAgKgEg");
    this.shape_56.setTransform(317.8, -21.125);

    this.shape_57 = new cjs.Shape();
    this.shape_57.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgFQgFgHgDgJQgDgJAAgMIAAhQIAVAAIAABGIABAQQAAAHADAFQACAFAFACQAEADAJAAQAGAAAJgFQAJgFAHgGIAAhcIAVAAIAAB8IgVAAIAAgOQgKAJgIAEQgJAEgKABQgIgBgHgCg");
    this.shape_57.setTransform(306.025, -20.95);

    this.shape_58 = new cjs.Shape();
    this.shape_58.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAEQACAFAEABQAEACAIAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgQAAgKgKg");
    this.shape_58.setTransform(295.9, -22.8);

    this.shape_59 = new cjs.Shape();
    this.shape_59.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgFQAKgFAPgCIAegDIAAgEQAAgIgCgEQgDgFgEgCQgEgCgGgBIgKgBQgIgBgKADQgJACgKAEIgCAAIAAgVIARgEQALgCALAAQALAAAJACQAJACAIAGQAGAFADAHQAEAJAAALIAABVIgVAAIAAgNIgHAFIgJAGIgKAEQgFACgJAAQgIAAgGgDgAAKAAIgSACQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALgBQAKAAAHgDQAJgFAHgGIAAgiIgTACg");
    this.shape_59.setTransform(285.6, -21.1);

    this.shape_60 = new cjs.Shape();
    this.shape_60.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAEQACAFAEABQAFACAHAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgQAAgKgKg");
    this.shape_60.setTransform(276.2, -22.8);

    this.shape_61 = new cjs.Shape();
    this.shape_61.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIACAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQACgDgBgFQAAgIgDgEQgFgEgKgCIgJgDIgMgDQgPgEgHgHQgGgJAAgMQAAgRANgLQANgLAUAAQALAAAKADQAKACAIAEIAAAXIgBAAQgIgGgKgEQgLgEgJAAQgKAAgHAFQgHAEAAAJQAAAIAEAEQAEADAKADIAJACIAMADQAOADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgJADQgIADgMAAQgMAAgMgEg");
    this.shape_61.setTransform(267.3, -21.125);

    this.shape_62 = new cjs.Shape();
    this.shape_62.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_62.setTransform(248.775, -21.15);

    this.shape_63 = new cjs.Shape();
    this.shape_63.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_63.setTransform(236.325, -23.575);

    this.shape_64 = new cjs.Shape();
    this.shape_64.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQAEACAHAAIAKgBIAHgCIABAAIAAATIgMACIgMABQgQAAgJgKg");
    this.shape_64.setTransform(226.1, -22.8);

    this.shape_65 = new cjs.Shape();
    this.shape_65.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_65.setTransform(208.925, -21.15);

    this.shape_66 = new cjs.Shape();
    this.shape_66.graphics.f("#E99F2F").s().p("AgWBXIgSgEIAAgWIACAAIAGACIAJADIALACIALABQAJAAAGgCQAHgDAEgEQAEgFABgGIABgOIAAgLQgIAIgIADQgIAEgMAAQgVAAgNgQQgNgQAAgdQAAgQAFgMQAEgMAIgIQAHgIAJgFQAKgEAJAAQAKAAAHACQAHACAHAFIACgGIAUAAIAABuQAAAggOAPQgOAPgcAAIgTgBgAgVg4QgJALAAAWQAAAVAHAKQAHALAQAAQAHAAAJgDQAJgDAGgGIAAhEIgOgGIgNgBQgPAAgKAMg");
    this.shape_66.setTransform(196.075, -18.925);

    this.shape_67 = new cjs.Shape();
    this.shape_67.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_67.setTransform(183.775, -21.3);

    this.shape_68 = new cjs.Shape();
    this.shape_68.graphics.f("#E99F2F").s().p("AgZA/QgHgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgFQAKgFAPgCIAegDIAAgEQAAgIgCgEQgCgFgFgCQgDgCgHgBIgKgBQgIgBgKADQgKACgJAEIgCAAIAAgVIARgEQALgCALAAQAMAAAIACQAJACAHAGQAHAFADAHQAEAJAAALIAABVIgVAAIAAgNIgHAFIgJAGIgKAEQgFACgJAAQgHAAgHgDgAAKAAIgSACQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALgBQAKAAAHgDQAJgFAHgGIAAgiIgTACg");
    this.shape_68.setTransform(170.95, -21.1);

    this.shape_69 = new cjs.Shape();
    this.shape_69.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_69.setTransform(158.925, -23.575);

    this.shape_70 = new cjs.Shape();
    this.shape_70.graphics.f("#E99F2F").s().p("AgMA+QgLgEgHgJQgIgIgEgMQgFgMAAgRQAAgPAFgMQAEgMAIgJQAHgIALgEQALgFAMAAQAKAAAKADQAJADAIAEIAAAYIgBAAIgGgFIgJgFIgKgEIgLgBQgQAAgKAMQgKANAAAVQAAAWAKANQAJAMARAAQAKAAAJgEQAKgEAHgHIABAAIAAAYIgIADIgIADIgKADIgLABQgMAAgLgEg");
    this.shape_70.setTransform(147.275, -21.125);

    this.shape_71 = new cjs.Shape();
    this.shape_71.graphics.f("#E99F2F").s().p("AgYBVQgJgEgGgJQgGgIgDgMQgEgNAAgQQAAgQAFgLQAEgMAHgJQAHgIAKgFQAKgEAJAAQAKAAAHACQAHACAHAEIAAg2IAWAAIAACtIgWAAIAAgMIgIAGIgIAGIgKADIgKABQgKAAgJgEgAgVgLQgJALAAAXQAAAWAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhHQgHgEgHgCQgGgBgHAAQgPAAgKAMg");
    this.shape_71.setTransform(127.875, -23.4);

    this.shape_72 = new cjs.Shape();
    this.shape_72.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_72.setTransform(115.575, -21.3);

    this.shape_73 = new cjs.Shape();
    this.shape_73.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgFQAKgFAPgCIAegDIAAgEQAAgIgDgEQgBgFgFgCQgDgCgHgBIgLgBQgHgBgKADQgJACgKAEIgCAAIAAgVIARgEQALgCAKAAQANAAAIACQAJACAHAGQAHAFADAHQAEAJAAALIAABVIgVAAIAAgNIgHAFIgIAGIgLAEQgEACgKAAQgHAAgIgDgAAKAAIgSACQgIACgFAGQgGAFAAAKQAAALAGAFQAGAGAMgBQAIAAAJgDQAIgFAHgGIAAgiIgTACg");
    this.shape_73.setTransform(102.75, -21.1);

    this.shape_74 = new cjs.Shape();
    this.shape_74.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIABAAIAHAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQABgDAAgFQAAgIgDgEQgFgEgKgCIgJgDIgNgDQgOgEgHgHQgGgJAAgMQAAgRANgLQANgLAUAAQALAAAKADQAKACAIAEIAAAXIgBAAQgIgGgKgEQgLgEgJAAQgKAAgHAFQgHAEAAAJQgBAIAFAEQAEADAKADIAJACIAMADQAOADAIAIQAGAIAAAOQAAAIgDAHQgDAHgHAFQgGAGgJADQgIADgMAAQgMAAgMgEg");
    this.shape_74.setTransform(84.95, -21.125);

    this.shape_75 = new cjs.Shape();
    this.shape_75.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQAEACAHAAIAKgBIAHgCIABAAIAAATIgMACIgMABQgPAAgKgKg");
    this.shape_75.setTransform(75.9, -22.8);

    this.shape_76 = new cjs.Shape();
    this.shape_76.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIAAAAIAHAEIAKAGIALAEQAHACAHAAIAKgBQAGgBADgCIAFgGQACgDAAgFQgBgIgEgEQgDgEgLgCIgKgDIgMgDQgOgEgGgHQgHgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQALACAGAEIAAAXIgBAAQgHgGgLgEQgJgEgLAAQgJAAgHAFQgIAEAAAJQAAAIAFAEQAEADAJADIAKACIANADQANADAHAIQAIAIgBAOQAAAIgDAHQgDAHgGAFQgHAGgIADQgJADgMAAQgMAAgLgEg");
    this.shape_76.setTransform(67, -21.125);

    this.shape_77 = new cjs.Shape();
    this.shape_77.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_77.setTransform(55.675, -21.15);

    this.shape_78 = new cjs.Shape();
    this.shape_78.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgFQgFgHgDgJQgDgJAAgMIAAhQIAVAAIAABGIABAQQAAAHADAFQACAFAFACQAEADAJAAQAGAAAJgFQAJgFAHgGIAAhcIAVAAIAAB8IgVAAIAAgOQgKAJgIAEQgJAEgKABQgIgBgHgCg");
    this.shape_78.setTransform(43.125, -20.95);

    this.shape_79 = new cjs.Shape();
    this.shape_79.graphics.f("#E99F2F").s().p("AgWBXIgSgEIAAgWIACAAIAGACIAJADIALACIALABQAJAAAGgCQAHgDAEgEQAEgFABgGIABgOIAAgLQgIAIgIADQgIAEgMAAQgVAAgNgQQgNgQAAgdQAAgQAFgMQAEgMAIgIQAHgIAJgFQAKgEAJAAQAKAAAHACQAHACAHAFIACgGIAUAAIAABuQAAAggOAPQgOAPgcAAIgTgBgAgVg4QgJALAAAWQAAAVAHAKQAHALAQAAQAHAAAJgDQAJgDAGgGIAAhEIgOgGIgNgBQgPAAgKAMg");
    this.shape_79.setTransform(29.975, -18.925);

    this.shape_80 = new cjs.Shape();
    this.shape_80.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgRIAOAAIAAgFQAAgXALgLQALgMATAAIALABIALABIAAATIgCAAIgIgCIgJgBQgNAAgGAHQgFAFAAAQIAAAFIAiAAIAAARIghAAIAABrg");
    this.shape_80.setTransform(13.8, -23.65);

    this.shape_81 = new cjs.Shape();
    this.shape_81.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAJgMQAJgMAAgYQAAgXgJgMQgJgMgQAAQgQAAgJAMg");
    this.shape_81.setTransform(3.3, -21.125);

    this.shape_82 = new cjs.Shape();
    this.shape_82.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQADACAIAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgRAAgJgKg");
    this.shape_82.setTransform(-13.9, -22.8);

    this.shape_83 = new cjs.Shape();
    this.shape_83.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_83.setTransform(-24.225, -21.3);

    this.shape_84 = new cjs.Shape();
    this.shape_84.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgFQgFgHgDgJQgDgJAAgMIAAhQIAVAAIAABGIABAQQAAAHADAFQACAFAFACQAEADAJAAQAGAAAJgFQAJgFAHgGIAAhcIAVAAIAAB8IgVAAIAAgOQgKAJgIAEQgJAEgKABQgIgBgHgCg");
    this.shape_84.setTransform(-37.175, -20.95);

    this.shape_85 = new cjs.Shape();
    this.shape_85.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAJgMQAJgMAAgYQAAgXgJgMQgJgMgQAAQgQAAgJAMg");
    this.shape_85.setTransform(-49.8, -21.125);

    this.shape_86 = new cjs.Shape();
    this.shape_86.graphics.f("#E99F2F").s().p("AA8BAIAAhHIAAgQQgBgIgCgDQgCgFgEgCQgFgDgHABQgGAAgIADQgHAFgIAHIAAAFIAAAGIAABRIgUAAIAAhHIAAgQQgBgIgCgDQgDgFgEgCQgEgDgHABQgHAAgHAEQgIAEgHAHIAABcIgVAAIAAh8IAVAAIAAAOQAIgJAJgEQAIgFAKABQALAAAIAEQAIAGAEALQAKgLAJgFQAJgGALABQAIAAAGACQAHADAFAFQAFAGADAIQADAJAAANIAABRg");
    this.shape_86.setTransform(-65.625, -21.3);

    this.shape_87 = new cjs.Shape();
    this.shape_87.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgFQALgFAOgCIAegDIAAgEQAAgIgCgEQgCgFgFgCQgEgCgFgBIgLgBQgIgBgKADQgKACgKAEIgBAAIAAgVIARgEQALgCALAAQALAAAJACQAJACAIAGQAGAFAEAHQADAJAAALIAABVIgVAAIAAgNIgHAFIgJAGIgKAEQgEACgKAAQgIAAgGgDgAAKAAIgSACQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALgBQAKAAAHgDQAJgFAHgGIAAgiIgTACg");
    this.shape_87.setTransform(-81.7, -21.1);

    this.shape_88 = new cjs.Shape();
    this.shape_88.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_88.setTransform(-100.575, -21.15);

    this.shape_89 = new cjs.Shape();
    this.shape_89.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_89.setTransform(-113.025, -23.575);

    this.shape_90 = new cjs.Shape();
    this.shape_90.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQADACAIAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgRAAgJgKg");
    this.shape_90.setTransform(-123.25, -22.8);

    this.shape_91 = new cjs.Shape();
    this.shape_91.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAEQACAFAEABQAFACAHAAIAKgBIAHgCIABAAIAAATIgMACIgMABQgQAAgJgKg");
    this.shape_91.setTransform(-138.15, -22.8);

    this.shape_92 = new cjs.Shape();
    this.shape_92.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAVAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_92.setTransform(-144.7, -23.275);

    this.shape_93 = new cjs.Shape();
    this.shape_93.graphics.f("#E99F2F").s().p("AgYBVQgJgEgGgJQgGgIgDgMQgEgNAAgQQAAgQAFgLQAEgMAHgJQAHgIAKgFQAKgEAJAAQAKAAAHACQAHACAHAEIAAg2IAWAAIAACtIgWAAIAAgMIgIAGIgIAGIgKADIgKABQgKAAgJgEgAgVgLQgJALAAAXQAAAWAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhHQgHgEgHgCQgGgBgHAAQgPAAgKAMg");
    this.shape_93.setTransform(-154.125, -23.4);

    this.shape_94 = new cjs.Shape();
    this.shape_94.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_94.setTransform(-166.075, -21.15);

    this.shape_95 = new cjs.Shape();
    this.shape_95.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_95.setTransform(-185.725, -21.3);

    this.shape_96 = new cjs.Shape();
    this.shape_96.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgFQALgFAOgCIAegDIAAgEQAAgIgDgEQgCgFgDgCQgFgCgFgBIgMgBQgHgBgKADQgJACgLAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAHAGQAHAFAEAHQADAJAAALIAABVIgVAAIAAgNIgHAFIgIAGIgLAEQgFACgJAAQgHAAgIgDgAAKAAIgSACQgIACgFAGQgGAFAAAKQAAALAGAFQAGAGAMgBQAIAAAJgDQAIgFAHgGIAAgiIgTACg");
    this.shape_96.setTransform(-198.55, -21.1);

    this.shape_97 = new cjs.Shape();
    this.shape_97.graphics.f("#E99F2F").s().p("AgMA+QgLgEgHgJQgIgIgEgMQgFgMAAgRQAAgPAFgMQAEgMAIgJQAHgIALgEQALgFAMAAQAKAAAKADQAJADAIAEIAAAYIgBAAIgGgFIgJgFIgKgEIgLgBQgQAAgKAMQgKANAAAVQAAAWAKANQAJAMARAAQAKAAAJgEQAKgEAHgHIABAAIAAAYIgIADIgIADIgKADIgLABQgMAAgLgEg");
    this.shape_97.setTransform(-209.375, -21.125);

    this.shape_98 = new cjs.Shape();
    this.shape_98.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgFQgFgHgDgJQgDgJAAgMIAAhQIAVAAIAABGIABAQQAAAHADAFQACAFAFACQAEADAJAAQAGAAAJgFQAJgFAHgGIAAhcIAVAAIAAB8IgVAAIAAgOQgKAJgIAEQgJAEgKABQgIgBgHgCg");
    this.shape_98.setTransform(-228.475, -20.95);

    this.shape_99 = new cjs.Shape();
    this.shape_99.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgKAMABAXQgBAYAKAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_99.setTransform(-241.1, -21.125);

    this.shape_100 = new cjs.Shape();
    this.shape_100.graphics.f("#E99F2F").s().p("AgKBTIAAhHIg5hfIAZAAIAqBKIAshKIAXAAIg4BdIAABJg");
    this.shape_100.setTransform(-253.95, -23.2);

    this.shape_101 = new cjs.Shape();
    this.shape_101.graphics.f("#E99F2F").s().p("AgMAQIAAgfIAZAAIAAAfg");
    this.shape_101.setTransform(-271.275, -16.45);

    this.shape_102 = new cjs.Shape();
    this.shape_102.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_102.setTransform(-281.125, -21.3);

    this.shape_103 = new cjs.Shape();
    this.shape_103.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgJAMAAAXQAAAYAJAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_103.setTransform(-293.85, -21.125);

    this.shape_104 = new cjs.Shape();
    this.shape_104.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAUAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_104.setTransform(-302.7, -23.275);

    this.shape_105 = new cjs.Shape();
    this.shape_105.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQADACAIAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgRAAgJgKg");
    this.shape_105.setTransform(-309.1, -22.8);

    this.shape_106 = new cjs.Shape();
    this.shape_106.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgFQALgFAOgCIAegDIAAgEQAAgIgCgEQgDgFgDgCQgEgCgGgBIgMgBQgHgBgKADQgKACgKAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAIAGQAGAFAEAHQADAJAAALIAABVIgVAAIAAgNIgHAFIgJAGIgKAEQgFACgJAAQgIAAgGgDgAAKAAIgSACQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALgBQAKAAAHgDQAJgFAHgGIAAgiIgTACg");
    this.shape_106.setTransform(-319.4, -21.1);

    this.shape_107 = new cjs.Shape();
    this.shape_107.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_107.setTransform(-328.475, -21.125);

    this.shape_108 = new cjs.Shape();
    this.shape_108.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQADACAIAAIAKgBIAHgCIABAAIAAATIgMACIgMABQgPAAgKgKg");
    this.shape_108.setTransform(-337.1, -22.8);

    this.shape_109 = new cjs.Shape();
    this.shape_109.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIABAAIAHAEIAKAGIALAEQAHACAHAAIAKgBQAGgBADgCIAFgGQACgDAAgFQAAgIgFgEQgDgEgLgCIgKgDIgMgDQgOgEgGgHQgHgJAAgMQAAgRANgLQAMgLAVAAQALAAAKADQAKACAIAEIAAAXIgCAAQgHgGgLgEQgJgEgLAAQgJAAgHAFQgIAEAAAJQAAAIAFAEQAEADAJADIAKACIAMADQAOADAHAIQAIAIgBAOQAAAIgDAHQgDAHgGAFQgHAGgJADQgIADgMAAQgMAAgMgEg");
    this.shape_109.setTransform(-346, -21.125);

    this.shape_110 = new cjs.Shape();
    this.shape_110.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAUAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_110.setTransform(-353.9, -23.275);

    this.shape_111 = new cjs.Shape();
    this.shape_111.graphics.f("#E99F2F").s().p("AgWBXIgSgEIAAgWIACAAIAGACIAJADIALACIALABQAJAAAGgCQAHgDAEgEQAEgFABgGIABgOIAAgLQgIAIgIADQgIAEgMAAQgVAAgNgQQgNgQAAgdQAAgQAFgMQAEgMAIgIQAHgIAJgFQAKgEAJAAQAKAAAHACQAHACAHAFIACgGIAUAAIAABuQAAAggOAPQgOAPgcAAIgTgBgAgVg4QgJALAAAWQAAAVAHAKQAHALAQAAQAHAAAJgDQAJgDAGgGIAAhEIgOgGIgNgBQgPAAgKAMg");
    this.shape_111.setTransform(-363.325, -18.925);

    this.shape_112 = new cjs.Shape();
    this.shape_112.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_112.setTransform(-375.275, -21.15);

    this.shape_113 = new cjs.Shape();
    this.shape_113.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_113.setTransform(-384.775, -21.125);

    this.shape_114 = new cjs.Shape();
    this.shape_114.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_114.setTransform(362.175, -50.9);

    this.shape_115 = new cjs.Shape();
    this.shape_115.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_115.setTransform(349.725, -53.325);

    this.shape_116 = new cjs.Shape();
    this.shape_116.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAFQACADAFACQAEADAHAAIAKgCIAHgDIABAAIAAATIgMADIgMABQgQAAgJgKg");
    this.shape_116.setTransform(339.5, -52.55);

    this.shape_117 = new cjs.Shape();
    this.shape_117.graphics.f("#E99F2F").s().p("AgYBUQgJgDgGgJQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHACAHAFIAAg2IAWAAIAACtIgWAAIAAgNIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgEAHgGIAAhIQgHgDgHgCQgGgBgHAAQgPAAgKAMg");
    this.shape_117.setTransform(321.575, -53.15);

    this.shape_118 = new cjs.Shape();
    this.shape_118.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgEAKgBQASAAALANQAKAMAAAXIAABQg");
    this.shape_118.setTransform(309.275, -51.05);

    this.shape_119 = new cjs.Shape();
    this.shape_119.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_119.setTransform(296.775, -50.9);

    this.shape_120 = new cjs.Shape();
    this.shape_120.graphics.f("#E99F2F").s().p("AA8BAIAAhHIAAgQQgBgHgCgFQgCgEgEgCQgFgDgHAAQgGAAgIAFQgHADgIAIIAAAFIAAAGIAABRIgUAAIAAhHIAAgQQgBgHgCgFQgDgEgEgCQgEgDgHAAQgHAAgHAFQgIAEgHAHIAABcIgVAAIAAh8IAVAAIAAAOQAIgJAJgEQAIgEAKgBQALAAAIAGQAIAEAEAMQAKgKAJgGQAJgFALgBQAIABAGACQAHACAFAGQAFAGADAJQADAIAAANIAABRg");
    this.shape_120.setTransform(281.125, -51.05);

    this.shape_121 = new cjs.Shape();
    this.shape_121.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgGQALgFAOgBIAegDIAAgEQAAgIgDgEQgCgFgDgCQgFgDgFgBIgMgBQgHABgKACQgKACgKAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAIAFQAGAGAEAIQADAIAAAMIAABUIgVAAIAAgNIgHAGIgJAFIgKAFQgFABgJAAQgIAAgGgDgAAKgBIgSADQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGAMAAQAIAAAIgFQAJgEAHgHIAAghIgTABg");
    this.shape_121.setTransform(265.05, -50.85);

    this.shape_122 = new cjs.Shape();
    this.shape_122.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAKAMAPAAQAQAAAJgMQAJgMAAgYQAAgXgJgMQgJgMgQAAQgPAAgKAMg");
    this.shape_122.setTransform(245.95, -50.875);

    this.shape_123 = new cjs.Shape();
    this.shape_123.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAFQACADAEACQAEADAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_123.setTransform(235.95, -52.55);

    this.shape_124 = new cjs.Shape();
    this.shape_124.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgEAKgBQASAAALANQAKAMAAAXIAABQg");
    this.shape_124.setTransform(218.425, -51.05);

    this.shape_125 = new cjs.Shape();
    this.shape_125.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAZAAAQARQAPASAAAeQAAAggPARQgQARgZAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAKgMQAIgMAAgYQAAgXgIgMQgKgMgQAAQgQAAgJAMg");
    this.shape_125.setTransform(205.7, -50.875);

    this.shape_126 = new cjs.Shape();
    this.shape_126.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAVAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_126.setTransform(196.85, -53.025);

    this.shape_127 = new cjs.Shape();
    this.shape_127.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAFQACADAEACQAFADAHAAIAKgCIAHgDIABAAIAAATIgMADIgMABQgQAAgJgKg");
    this.shape_127.setTransform(190.45, -52.55);

    this.shape_128 = new cjs.Shape();
    this.shape_128.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgGQAKgFAPgBIAegDIAAgEQAAgIgDgEQgBgFgFgCQgDgDgHgBIgLgBQgHABgKACQgJACgKAEIgCAAIAAgVIARgEQALgCAKAAQANAAAIACQAJACAHAFQAHAGADAIQAEAIAAAMIAABUIgVAAIAAgNIgHAGIgIAFIgLAFQgEABgKAAQgHAAgIgDgAAKgBIgSADQgIACgFAGQgGAFAAAKQAAALAGAFQAGAGAMAAQAIAAAJgFQAIgEAHgHIAAghIgTABg");
    this.shape_128.setTransform(180.15, -50.85);

    this.shape_129 = new cjs.Shape();
    this.shape_129.graphics.f("#E99F2F").s().p("AgJA/Igvh9IAXAAIAiBgIAihgIAWAAIgwB9g");
    this.shape_129.setTransform(168.775, -50.875);

    this.shape_130 = new cjs.Shape();
    this.shape_130.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_130.setTransform(159.625, -50.875);

    this.shape_131 = new cjs.Shape();
    this.shape_131.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_131.setTransform(148.725, -50.9);

    this.shape_132 = new cjs.Shape();
    this.shape_132.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIACAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQACgDgBgFQAAgIgDgEQgFgEgKgCIgJgDIgMgDQgPgEgHgHQgGgJAAgMQAAgRANgLQANgLAUAAQALAAAKADQAKACAIAEIAAAXIgBAAQgJgGgJgEQgLgEgJAAQgKAAgHAFQgHAEAAAJQAAAIAEAEQAEADAKADIAJACIAMADQAOADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgJADQgIADgMAAQgMAAgMgEg");
    this.shape_132.setTransform(137.7, -50.875);

    this.shape_133 = new cjs.Shape();
    this.shape_133.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_133.setTransform(126.375, -50.9);

    this.shape_134 = new cjs.Shape();
    this.shape_134.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_134.setTransform(116.875, -50.875);

    this.shape_135 = new cjs.Shape();
    this.shape_135.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgSIAOAAIAAgDQAAgYALgLQALgMATAAIALABIALABIAAAUIgCAAIgIgCIgJgCQgNABgGAFQgFAGABARIAAADIAhAAIAAASIghAAIAABrg");
    this.shape_135.setTransform(101.75, -53.4);

    this.shape_136 = new cjs.Shape();
    this.shape_136.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgYgjQgJAMAAAXQAAAYAJAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_136.setTransform(91.25, -50.875);

    this.shape_137 = new cjs.Shape();
    this.shape_137.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_137.setTransform(71.775, -50.9);

    this.shape_138 = new cjs.Shape();
    this.shape_138.graphics.f("#E99F2F").s().p("AA8BAIAAhHIAAgQQgBgHgCgFQgCgEgEgCQgFgDgHAAQgGAAgIAFQgHADgIAIIAAAFIAAAGIAABRIgUAAIAAhHIAAgQQgBgHgCgFQgDgEgEgCQgEgDgHAAQgHAAgHAFQgIAEgHAHIAABcIgVAAIAAh8IAVAAIAAAOQAIgJAJgEQAIgEAKgBQALAAAIAGQAIAEAEAMQAKgKAJgGQAJgFALgBQAIABAGACQAHACAFAGQAFAGADAJQADAIAAANIAABRg");
    this.shape_138.setTransform(56.125, -51.05);

    this.shape_139 = new cjs.Shape();
    this.shape_139.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAUAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_139.setTransform(43.8, -53.025);

    this.shape_140 = new cjs.Shape();
    this.shape_140.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAFQACADAFACQADADAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_140.setTransform(37.4, -52.55);

    this.shape_141 = new cjs.Shape();
    this.shape_141.graphics.f("#E99F2F").s().p("AgpBpIBAjRIATAAIhADRg");
    this.shape_141.setTransform(28.625, -51.5);

    this.shape_142 = new cjs.Shape();
    this.shape_142.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_142.setTransform(18.625, -50.9);

    this.shape_143 = new cjs.Shape();
    this.shape_143.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAFQACADAEACQAEADAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgQAAgKgKg");
    this.shape_143.setTransform(8.8, -52.55);

    this.shape_144 = new cjs.Shape();
    this.shape_144.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgGQAKgFAPgBIAegDIAAgEQAAgIgCgEQgDgFgEgCQgEgDgGgBIgKgBQgIABgKACQgJACgKAEIgCAAIAAgVIARgEQALgCALAAQALAAAJACQAJACAIAFQAGAGADAIQAEAIAAAMIAABUIgVAAIAAgNIgHAGIgJAFIgKAFQgFABgJAAQgIAAgGgDgAAKgBIgSADQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALAAQAKAAAHgFQAJgEAHgHIAAghIgTABg");
    this.shape_144.setTransform(-1.5, -50.85);

    this.shape_145 = new cjs.Shape();
    this.shape_145.graphics.f("#E99F2F").s().p("AgYBUQgJgDgGgJQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHACAHAFIAAg2IAWAAIAACtIgWAAIAAgNIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgEAHgGIAAhIQgHgDgHgCQgGgBgHAAQgPAAgKAMg");
    this.shape_145.setTransform(-13.925, -53.15);

    this.shape_146 = new cjs.Shape();
    this.shape_146.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_146.setTransform(-33.075, -50.9);

    this.shape_147 = new cjs.Shape();
    this.shape_147.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_147.setTransform(-45.525, -53.325);

    this.shape_148 = new cjs.Shape();
    this.shape_148.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAFQACADAFACQADADAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_148.setTransform(-55.75, -52.55);

    this.shape_149 = new cjs.Shape();
    this.shape_149.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgSIAOAAIAAgDQAAgYALgLQALgMATAAIALABIALABIAAAUIgCAAIgHgCIgKgCQgNABgGAFQgFAGABARIAAADIAiAAIAAASIgiAAIAABrg");
    this.shape_149.setTransform(-69.95, -53.4);

    this.shape_150 = new cjs.Shape();
    this.shape_150.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgKAMABAXQgBAYAKAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_150.setTransform(-80.45, -50.875);

    this.shape_151 = new cjs.Shape();
    this.shape_151.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIABAAIAHAEIAJAGIAMAEQAHACAHAAIAKgBQAGgBACgCIAGgGQABgDABgFQAAgIgFgEQgDgEgLgCIgJgDIgNgDQgOgEgGgHQgHgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQAKACAIAEIAAAXIgBAAQgJgGgJgEQgKgEgLAAQgJAAgHAFQgIAEABAJQgBAIAFAEQAEADAKADIAJACIAMADQAOADAHAIQAIAIgBAOQABAIgEAHQgDAHgGAFQgHAGgJADQgIADgMAAQgNAAgLgEg");
    this.shape_151.setTransform(-98.85, -50.875);

    this.shape_152 = new cjs.Shape();
    this.shape_152.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_152.setTransform(-107.575, -50.875);

    this.shape_153 = new cjs.Shape();
    this.shape_153.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgGQgFgFgDgKQgDgJAAgMIAAhRIAVAAIAABHIABARQAAAGADAFQACAFAFACQAEADAJgBQAGAAAJgEQAJgFAHgGIAAhdIAVAAIAAB9IgVAAIAAgOQgKAJgIAEQgJAFgKgBQgIAAgHgCg");
    this.shape_153.setTransform(-118.925, -50.7);

    this.shape_154 = new cjs.Shape();
    this.shape_154.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAZAAAPARQAQASAAAeQAAAggQARQgPARgZAAQgZAAgPgSgAgZgjQgJAMAAAXQAAAYAJAMQAKAMAPAAQARAAAJgMQAIgMAAgYQAAgXgIgMQgJgMgRAAQgPAAgKAMg");
    this.shape_154.setTransform(-131.55, -50.875);

    this.shape_155 = new cjs.Shape();
    this.shape_155.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_155.setTransform(-144.175, -53.325);

    this.shape_156 = new cjs.Shape();
    this.shape_156.graphics.f("#E99F2F").s().p("AgzBVIAAgXIAVgUIAUgVQASgVAHgLQAHgNAAgNQAAgHgCgGQgDgFgDgDQgEgEgFgBQgFgCgGAAQgGAAgGACIgMADIgJAFIgHAEIgBAAIAAgXQAHgEAMgDQANgDALAAQAWAAANAMQAOAMAAAWQAAAJgDAJQgCAIgEAHIgKAOIgMAOIgXAYIgVATIBSAAIAAATg");
    this.shape_156.setTransform(-163.975, -53.125);

    this.shape_157 = new cjs.Shape();
    this.shape_157.graphics.f("#E99F2F").s().p("AgqBTIBMiSIhWAAIAAgTIBpAAIAAAZIhHCMg");
    this.shape_157.setTransform(-176.45, -52.95);

    this.shape_158 = new cjs.Shape();
    this.shape_158.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_158.setTransform(-196.075, -50.9);

    this.shape_159 = new cjs.Shape();
    this.shape_159.graphics.f("#E99F2F").s().p("AgJA/Igvh9IAXAAIAiBgIAihgIAWAAIgwB9g");
    this.shape_159.setTransform(-207.875, -50.875);

    this.shape_160 = new cjs.Shape();
    this.shape_160.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgGQALgFAOgBIAegDIAAgEQAAgIgCgEQgDgFgDgCQgEgDgGgBIgMgBQgHABgKACQgKACgKAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAIAFQAGAGAEAIQADAIAAAMIAABUIgVAAIAAgNIgHAGIgJAFIgKAFQgFABgJAAQgIAAgGgDgAAKgBIgSADQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALAAQAKAAAHgFQAJgEAHgHIAAghIgTABg");
    this.shape_160.setTransform(-219.95, -50.85);

    this.shape_161 = new cjs.Shape();
    this.shape_161.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_161.setTransform(-231.975, -53.325);

    this.shape_162 = new cjs.Shape();
    this.shape_162.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgGQgFgFgDgKQgDgJAAgMIAAhRIAVAAIAABHIABARQAAAGADAFQACAFAFACQAEADAJgBQAGAAAJgEQAJgFAHgGIAAhdIAVAAIAAB9IgVAAIAAgOQgKAJgIAEQgJAFgKgBQgIAAgHgCg");
    this.shape_162.setTransform(-252.125, -50.7);

    this.shape_163 = new cjs.Shape();
    this.shape_163.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAZAAAPARQAQASAAAeQAAAggQARQgPARgZAAQgZAAgPgSgAgZgjQgJAMAAAXQAAAYAJAMQAKAMAPAAQARAAAJgMQAIgMAAgYQAAgXgIgMQgJgMgRAAQgPAAgKAMg");
    this.shape_163.setTransform(-264.75, -50.875);

    this.shape_164 = new cjs.Shape();
    this.shape_164.graphics.f("#E99F2F").s().p("AggBWIAWg0Iguh3IAXAAIAhBcIAjhcIAWAAIhDCrg");
    this.shape_164.setTransform(-276.725, -48.55);

    this.shape_165 = new cjs.Shape();
    this.shape_165.graphics.f("#E99F2F").s().p("AgTA/IAAgsIAnAAIAAAsgAgTgSIAAgsIAnAAIAAAsg");
    this.shape_165.setTransform(-293.825, -50.9);

    this.shape_166 = new cjs.Shape();
    this.shape_166.graphics.f("#E99F2F").s().p("Ag9BYIAAisIAoAAIAAAOQAIgIAJgEQAIgFAMAAQAWAAAMARQAMARAAAeQAAAegPATQgQASgWAAQgJAAgHgCQgHgCgHgFIAAA1gAgLg3IgKAFIAAA+IAHACIAIABQAOAAAHgJQAHgIAAgUQAAgSgGgIQgGgJgLAAIgKACg");
    this.shape_166.setTransform(-304.825, -48.75);

    this.shape_167 = new cjs.Shape();
    this.shape_167.graphics.f("#E99F2F").s().p("AgTBXIAAh9IAnAAIAAB9gAgUg4IAAgeIApAAIAAAeg");
    this.shape_167.setTransform(-315.975, -53.325);

    this.shape_168 = new cjs.Shape();
    this.shape_168.graphics.f("#E99F2F").s().p("AgTBIQgLgKAAgWIAAg6IgPAAIAAgbIAPAAIAAgkIAmAAIAAAkIAmAAIAAAbIgmAAIAAAsIABALQgBAFACAEQACAEAEACQADADAJAAIAIgCIAHgCIADAAIAAAcIgOACIgRABQgXAAgLgKg");
    this.shape_168.setTransform(-324.15, -52.575);

    this.shape_169 = new cjs.Shape();
    this.shape_169.graphics.f("#E99F2F").s().p("AgvAxQgRgSAAgfQAAgfARgRQARgSAeAAQAfAAARASQARASAAAeQAAAfgRASQgRASgfAAQgeAAgRgSgAgJgkQgEABgEAFQgDAEgCAIQgCAIAAAKQAAAMACAHQACAHADAEQADAFAFABQAFACAEAAQAFAAAFgCQAFgBACgFQAEgEACgHQACgHAAgMQAAgKgCgHQgCgIgDgEQgEgFgEgCQgFgCgFAAQgFAAgEACg");
    this.shape_169.setTransform(-336.1, -50.9);

    this.shape_170 = new cjs.Shape();
    this.shape_170.graphics.f("#E99F2F").s().p("AgpA/IAAh9IAnAAIAAASIAHgGIAIgGIAKgEQAGgCAFAAIAEAAIAEAAIAAAlIgDAAIgGgBIgKAAQgGAAgHACIgMAEIAABTg");
    this.shape_170.setTransform(-347.425, -50.925);

    this.shape_171 = new cjs.Shape();
    this.shape_171.graphics.f("#E99F2F").s().p("Ag/BTIAAilIA/AAQAPgBALADQAKADAIAFQAKAHAFAKQAFAKAAAPQAAALgDALQgEAKgHAHIgJAIIgMAHIgNADQgHACgJAAIgVAAIAAA2gAgVgBIACAAIAMAAIAIgBIAHgCIAGgEQAEgFACgEQABgFAAgHQAAgHgDgFQgEgFgEgCQgGgCgFgBIgPgBIgFAAg");
    this.shape_171.setTransform(-360.075, -52.95);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_171
      }, {
        t: this.shape_170
      }, {
        t: this.shape_169
      }, {
        t: this.shape_168
      }, {
        t: this.shape_167
      }, {
        t: this.shape_166
      }, {
        t: this.shape_165
      }, {
        t: this.shape_164
      }, {
        t: this.shape_163
      }, {
        t: this.shape_162
      }, {
        t: this.shape_161
      }, {
        t: this.shape_160
      }, {
        t: this.shape_159
      }, {
        t: this.shape_158
      }, {
        t: this.shape_157
      }, {
        t: this.shape_156
      }, {
        t: this.shape_155
      }, {
        t: this.shape_154
      }, {
        t: this.shape_153
      }, {
        t: this.shape_152
      }, {
        t: this.shape_151
      }, {
        t: this.shape_150
      }, {
        t: this.shape_149
      }, {
        t: this.shape_148
      }, {
        t: this.shape_147
      }, {
        t: this.shape_146
      }, {
        t: this.shape_145
      }, {
        t: this.shape_144
      }, {
        t: this.shape_143
      }, {
        t: this.shape_142
      }, {
        t: this.shape_141
      }, {
        t: this.shape_140
      }, {
        t: this.shape_139
      }, {
        t: this.shape_138
      }, {
        t: this.shape_137
      }, {
        t: this.shape_136
      }, {
        t: this.shape_135
      }, {
        t: this.shape_134
      }, {
        t: this.shape_133
      }, {
        t: this.shape_132
      }, {
        t: this.shape_131
      }, {
        t: this.shape_130
      }, {
        t: this.shape_129
      }, {
        t: this.shape_128
      }, {
        t: this.shape_127
      }, {
        t: this.shape_126
      }, {
        t: this.shape_125
      }, {
        t: this.shape_124
      }, {
        t: this.shape_123
      }, {
        t: this.shape_122
      }, {
        t: this.shape_121
      }, {
        t: this.shape_120
      }, {
        t: this.shape_119
      }, {
        t: this.shape_118
      }, {
        t: this.shape_117
      }, {
        t: this.shape_116
      }, {
        t: this.shape_115
      }, {
        t: this.shape_114
      }, {
        t: this.shape_113
      }, {
        t: this.shape_112
      }, {
        t: this.shape_111
      }, {
        t: this.shape_110
      }, {
        t: this.shape_109
      }, {
        t: this.shape_108
      }, {
        t: this.shape_107
      }, {
        t: this.shape_106
      }, {
        t: this.shape_105
      }, {
        t: this.shape_104
      }, {
        t: this.shape_103
      }, {
        t: this.shape_102
      }, {
        t: this.shape_101
      }, {
        t: this.shape_100
      }, {
        t: this.shape_99
      }, {
        t: this.shape_98
      }, {
        t: this.shape_97
      }, {
        t: this.shape_96
      }, {
        t: this.shape_95
      }, {
        t: this.shape_94
      }, {
        t: this.shape_93
      }, {
        t: this.shape_92
      }, {
        t: this.shape_91
      }, {
        t: this.shape_90
      }, {
        t: this.shape_89
      }, {
        t: this.shape_88
      }, {
        t: this.shape_87
      }, {
        t: this.shape_86
      }, {
        t: this.shape_85
      }, {
        t: this.shape_84
      }, {
        t: this.shape_83
      }, {
        t: this.shape_82
      }, {
        t: this.shape_81
      }, {
        t: this.shape_80
      }, {
        t: this.shape_79
      }, {
        t: this.shape_78
      }, {
        t: this.shape_77
      }, {
        t: this.shape_76
      }, {
        t: this.shape_75
      }, {
        t: this.shape_74
      }, {
        t: this.shape_73
      }, {
        t: this.shape_72
      }, {
        t: this.shape_71
      }, {
        t: this.shape_70
      }, {
        t: this.shape_69
      }, {
        t: this.shape_68
      }, {
        t: this.shape_67
      }, {
        t: this.shape_66
      }, {
        t: this.shape_65
      }, {
        t: this.shape_64
      }, {
        t: this.shape_63
      }, {
        t: this.shape_62
      }, {
        t: this.shape_61
      }, {
        t: this.shape_60
      }, {
        t: this.shape_59
      }, {
        t: this.shape_58
      }, {
        t: this.shape_57
      }, {
        t: this.shape_56
      }, {
        t: this.shape_55
      }, {
        t: this.shape_54
      }, {
        t: this.shape_53
      }, {
        t: this.shape_52
      }, {
        t: this.shape_51
      }, {
        t: this.shape_50
      }, {
        t: this.shape_49
      }, {
        t: this.shape_48
      }, {
        t: this.shape_47
      }, {
        t: this.shape_46
      }, {
        t: this.shape_45
      }, {
        t: this.shape_44
      }, {
        t: this.shape_43
      }, {
        t: this.shape_42
      }, {
        t: this.shape_41
      }, {
        t: this.shape_40
      }, {
        t: this.shape_39
      }, {
        t: this.shape_38
      }, {
        t: this.shape_37
      }, {
        t: this.shape_36
      }, {
        t: this.shape_35
      }, {
        t: this.shape_34
      }, {
        t: this.shape_33
      }, {
        t: this.shape_32
      }, {
        t: this.shape_31
      }, {
        t: this.shape_30
      }, {
        t: this.shape_29
      }, {
        t: this.shape_28
      }, {
        t: this.shape_27
      }, {
        t: this.shape_26
      }, {
        t: this.shape_25
      }, {
        t: this.shape_24
      }, {
        t: this.shape_23
      }, {
        t: this.shape_22
      }, {
        t: this.shape_21
      }, {
        t: this.shape_20
      }, {
        t: this.shape_19
      }, {
        t: this.shape_18
      }, {
        t: this.shape_17
      }, {
        t: this.shape_16
      }, {
        t: this.shape_15
      }, {
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400.6, -69.6, 801.2, 91.3);


  (lib.Tween80 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#E99F2F").s().p("AgMAQIAAgfIAZAAIAAAfg");
    this.shape.setTransform(312.375, 13.3);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#E99F2F").s().p("Ag0BYIAAirIAWAAIAAANQAHgHAKgFQAKgFAKAAQAWAAAMARQAMARAAAdQgBAfgPASQgOATgXAAQgJAAgHgCQgHgDgHgEIAAA1gAgPg/QgIAEgHAHIAABGQAHAEAGABQAHACAHAAQAPAAAKgMQAIgMABgWQAAgWgHgMQgHgMgQAAQgHAAgJAEg");
    this.shape_1.setTransform(303, 10.775);

    this.shape_2 = new cjs.Shape();
    this.shape_2.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgGQgFgGgDgIQgDgJAAgNIAAhRIAVAAIAABHIABARQAAAGADAFQACAFAFACQAEADAJgBQAGABAJgFQAJgFAHgGIAAhdIAVAAIAAB9IgVAAIAAgOQgKAJgIAEQgJAFgKgBQgIAAgHgCg");
    this.shape_2.setTransform(289.725, 8.8);

    this.shape_3 = new cjs.Shape();
    this.shape_3.graphics.f("#E99F2F").s().p("AAfA/IgfhgIgdBgIgUAAIggh9IAWAAIAWBgIAehgIAQAAIAfBgIAUhgIAWAAIggB9g");
    this.shape_3.setTransform(267.65, 8.625);

    this.shape_4 = new cjs.Shape();
    this.shape_4.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAZAAAPARQAQASAAAeQAAAggQARQgPARgZAAQgZAAgPgSgAgZgjQgJAMAAAXQAAAYAJAMQAKAMAPAAQARAAAJgMQAIgMAAgYQAAgXgIgMQgJgMgRAAQgPAAgKAMg");
    this.shape_4.setTransform(252.85, 8.625);

    this.shape_5 = new cjs.Shape();
    this.shape_5.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_5.setTransform(240.225, 6.175);

    this.shape_6 = new cjs.Shape();
    this.shape_6.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAKAGIAMAEQAGACAHAAIAKgBQAGgBADgCIAFgGQABgDAAgFQAAgIgDgEQgEgEgLgCIgKgDIgLgDQgPgEgHgHQgGgJAAgMQAAgRANgLQANgLAUAAQAKAAALADQALACAGAEIAAAXIgBAAQgHgGgLgEQgJgEgKAAQgKAAgHAFQgHAEgBAJQABAIAEAEQAEADAJADIAKACIANADQANADAHAIQAIAIAAAOQgBAIgDAHQgDAHgHAFQgGAGgIADQgJADgMAAQgNAAgKgEg");
    this.shape_6.setTransform(228.8, 8.625);

    this.shape_7 = new cjs.Shape();
    this.shape_7.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhEIgOAAIAAgSIAOAAIAAgkIAVAAIAAAkIAmAAIAAASIgmAAIAAA6IAAAPQABAFABAFQACADAFADQADACAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_7.setTransform(212.55, 6.95);

    this.shape_8 = new cjs.Shape();
    this.shape_8.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAZAAAPARQAQASAAAeQAAAggQARQgPARgZAAQgZAAgPgSgAgYgjQgKAMAAAXQAAAYAKAMQAJAMAPAAQARAAAJgMQAIgMAAgYQAAgXgIgMQgJgMgRAAQgPAAgJAMg");
    this.shape_8.setTransform(202.35, 8.625);

    this.shape_9 = new cjs.Shape();
    this.shape_9.graphics.f("#E99F2F").s().p("AAcBBIAAhHIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABdIgVAAIAAh9IAVAAIAAAOQAJgIAJgFQAJgEAKgBQASAAALANQAKAMAAAXIAABRg");
    this.shape_9.setTransform(189.725, 8.45);

    this.shape_10 = new cjs.Shape();
    this.shape_10.graphics.f("#E99F2F").s().p("AgYBUQgJgEgGgIQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHADAHAEIAAg2IAWAAIAACuIgWAAIAAgOIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhIQgHgDgHgBQgGgCgHAAQgPAAgKAMg");
    this.shape_10.setTransform(169.275, 6.35);

    this.shape_11 = new cjs.Shape();
    this.shape_11.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAVAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_11.setTransform(160.75, 6.475);

    this.shape_12 = new cjs.Shape();
    this.shape_12.graphics.f("#E99F2F").s().p("AgYBUQgJgEgGgIQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHADAHAEIAAg2IAWAAIAACuIgWAAIAAgOIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhIQgHgDgHgBQgGgCgHAAQgPAAgKAMg");
    this.shape_12.setTransform(151.325, 6.35);

    this.shape_13 = new cjs.Shape();
    this.shape_13.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIABAAIAHAEIAKAGIALAEQAHACAHAAIAKgBQAGgBADgCIAFgGQACgDAAgFQAAgIgFgEQgDgEgLgCIgKgDIgMgDQgOgEgGgHQgHgJAAgMQAAgRANgLQAMgLAVAAQALAAAKADQAKACAIAEIAAAXIgCAAQgHgGgLgEQgJgEgLAAQgJAAgHAFQgIAEAAAJQAAAIAFAEQAEADAJADIAKACIANADQANADAHAIQAIAIgBAOQAAAIgDAHQgDAHgGAFQgHAGgIADQgJADgMAAQgMAAgMgEg");
    this.shape_13.setTransform(133.25, 8.625);

    this.shape_14 = new cjs.Shape();
    this.shape_14.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhEIgOAAIAAgSIAOAAIAAgkIAVAAIAAAkIAmAAIAAASIgmAAIAAA6IAAAPQABAFABAFQACADAEADQAFACAHAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_14.setTransform(124.2, 6.95);

    this.shape_15 = new cjs.Shape();
    this.shape_15.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQACgDgBgFQAAgIgDgEQgFgEgKgCIgJgDIgMgDQgPgEgHgHQgGgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQAKACAHAEIAAAXIAAAAQgJgGgKgEQgJgEgKAAQgKAAgHAFQgHAEgBAJQABAIAEAEQAEADAKADIAJACIANADQANADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgIADQgJADgMAAQgNAAgKgEg");
    this.shape_15.setTransform(115.3, 8.625);

    this.shape_16 = new cjs.Shape();
    this.shape_16.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_16.setTransform(103.975, 8.6);

    this.shape_17 = new cjs.Shape();
    this.shape_17.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgGQgFgGgDgIQgDgJAAgNIAAhRIAVAAIAABHIABARQAAAGADAFQACAFAFACQAEADAJgBQAGABAJgFQAJgFAHgGIAAhdIAVAAIAAB9IgVAAIAAgOQgKAJgIAEQgJAFgKgBQgIAAgHgCg");
    this.shape_17.setTransform(91.425, 8.8);

    this.shape_18 = new cjs.Shape();
    this.shape_18.graphics.f("#E99F2F").s().p("AgWBXIgSgEIAAgWIACAAIAGACIAJADIALACIALABQAJAAAGgCQAHgDAEgEQAEgFABgGIABgOIAAgLQgIAIgIADQgIAEgMAAQgVAAgNgQQgNgQAAgdQAAgQAFgMQAEgMAIgIQAHgIAJgFQAKgEAJAAQAKAAAHACQAHACAHAFIACgGIAUAAIAABuQAAAggOAPQgOAPgcAAIgTgBgAgVg4QgJALAAAWQAAAVAHAKQAHALAQAAQAHAAAJgDQAJgDAGgGIAAhEIgOgGIgNgBQgPAAgKAMg");
    this.shape_18.setTransform(78.275, 10.825);

    this.shape_19 = new cjs.Shape();
    this.shape_19.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgSIAOAAIAAgDQAAgYALgLQALgMATAAIAMABIAKABIAAAUIgBAAIgIgCIgKgBQgNAAgGAFQgEAGgBARIAAADIAjAAIAAASIgiAAIAABrg");
    this.shape_19.setTransform(62.1, 6.1);

    this.shape_20 = new cjs.Shape();
    this.shape_20.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAVAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_20.setTransform(55.25, 6.475);

    this.shape_21 = new cjs.Shape();
    this.shape_21.graphics.f("#E99F2F").s().p("AAfA/IgfhgIgeBgIgTAAIggh9IAWAAIAWBgIAehgIAQAAIAfBgIAUhgIAWAAIggB9g");
    this.shape_21.setTransform(36.9, 8.625);

    this.shape_22 = new cjs.Shape();
    this.shape_22.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAZAAAQARQAPASAAAeQAAAggPARQgQARgZAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAKgMQAIgMAAgYQAAgXgIgMQgKgMgQAAQgQAAgJAMg");
    this.shape_22.setTransform(22.1, 8.625);

    this.shape_23 = new cjs.Shape();
    this.shape_23.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_23.setTransform(9.475, 6.175);

    this.shape_24 = new cjs.Shape();
    this.shape_24.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAKAGIALAEQAHACAHAAIAKgBQAFgBAEgCIAFgGQABgDAAgFQAAgIgDgEQgEgEgLgCIgKgDIgLgDQgPgEgGgHQgHgJAAgMQAAgRANgLQANgLAUAAQAKAAALADQALACAGAEIAAAXIgBAAQgHgGgLgEQgKgEgKAAQgJAAgHAFQgIAEAAAJQABAIAEAEQAEADAJADIAKACIANADQANADAHAIQAIAIAAAOQgBAIgDAHQgDAHgHAFQgGAGgIADQgJADgMAAQgMAAgLgEg");
    this.shape_24.setTransform(-1.95, 8.625);

    this.shape_25 = new cjs.Shape();
    this.shape_25.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgJAMAAAXQAAAYAJAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_25.setTransform(-20.7, 8.625);

    this.shape_26 = new cjs.Shape();
    this.shape_26.graphics.f("#E99F2F").s().p("AAcBBIAAhHIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABdIgVAAIAAh9IAVAAIAAAOQAJgIAJgFQAJgEAKgBQASAAALANQAKAMAAAXIAABRg");
    this.shape_26.setTransform(-33.325, 8.45);

    this.shape_27 = new cjs.Shape();
    this.shape_27.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_27.setTransform(-50.425, 8.625);

    this.shape_28 = new cjs.Shape();
    this.shape_28.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgKAMABAXQgBAYAKAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_28.setTransform(-61.55, 8.625);

    this.shape_29 = new cjs.Shape();
    this.shape_29.graphics.f("#E99F2F").s().p("AgYBUQgJgEgGgIQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHADAHAEIAAg2IAWAAIAACuIgWAAIAAgOIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhIQgHgDgHgBQgGgCgHAAQgPAAgKAMg");
    this.shape_29.setTransform(-81.775, 6.35);

    this.shape_30 = new cjs.Shape();
    this.shape_30.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_30.setTransform(-93.725, 8.6);

    this.shape_31 = new cjs.Shape();
    this.shape_31.graphics.f("#E99F2F").s().p("AgKBXIAAitIAVAAIAACtg");
    this.shape_31.setTransform(-102.425, 6.175);

    this.shape_32 = new cjs.Shape();
    this.shape_32.graphics.f("#E99F2F").s().p("AgKBXIAAitIAVAAIAACtg");
    this.shape_32.setTransform(-107.675, 6.175);

    this.shape_33 = new cjs.Shape();
    this.shape_33.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_33.setTransform(-116.325, 8.6);

    this.shape_34 = new cjs.Shape();
    this.shape_34.graphics.f("#E99F2F").s().p("AgMA+QgLgEgHgJQgIgIgEgMQgFgMAAgRQAAgPAFgMQAEgMAIgJQAHgIALgEQALgFAMAAQAKAAAKADQAJADAIAEIAAAYIgBAAIgGgFIgJgFIgKgEIgLgBQgQAAgKAMQgKANAAAVQAAAWAKANQAJAMARAAQAKAAAJgEQAKgEAHgHIABAAIAAAYIgIADIgIADIgKADIgLABQgMAAgLgEg");
    this.shape_34.setTransform(-127.575, 8.625);

    this.shape_35 = new cjs.Shape();
    this.shape_35.graphics.f("#E99F2F").s().p("AAcBBIAAhHIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABdIgVAAIAAh9IAVAAIAAAOQAJgIAJgFQAJgEAKgBQASAAALANQAKAMAAAXIAABRg");
    this.shape_35.setTransform(-139.375, 8.45);

    this.shape_36 = new cjs.Shape();
    this.shape_36.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgGQALgFAOgBIAegDIAAgEQAAgIgDgEQgCgEgDgDQgFgCgFgCIgMgBQgHAAgKADQgJACgLAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAHAFQAHAGAEAIQADAIAAALIAABWIgVAAIAAgOIgHAGIgIAFIgLAFQgFABgJAAQgHAAgIgDgAAKgBIgSADQgIADgFAFQgGAFAAAKQAAAKAGAGQAGAFAMABQAIAAAJgFQAIgEAHgHIAAghIgTABg");
    this.shape_36.setTransform(-152.2, 8.65);

    this.shape_37 = new cjs.Shape();
    this.shape_37.graphics.f("#E99F2F").s().p("AgMA+QgLgEgHgJQgIgIgEgMQgFgMAAgRQAAgPAFgMQAEgMAIgJQAHgIALgEQALgFAMAAQAKAAAKADQAJADAIAEIAAAYIgBAAIgGgFIgJgFIgKgEIgLgBQgQAAgKAMQgKANAAAVQAAAWAKANQAJAMARAAQAKAAAJgEQAKgEAHgHIABAAIAAAYIgIADIgIADIgKADIgLABQgMAAgLgEg");
    this.shape_37.setTransform(-163.025, 8.625);

    this.shape_38 = new cjs.Shape();
    this.shape_38.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgYgjQgJAMAAAXQAAAYAJAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_38.setTransform(-181.9, 8.625);

    this.shape_39 = new cjs.Shape();
    this.shape_39.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhEIgOAAIAAgSIAOAAIAAgkIAVAAIAAAkIAmAAIAAASIgmAAIAAA6IAAAPQABAFABAFQACADAEADQAEACAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgQAAgKgKg");
    this.shape_39.setTransform(-191.9, 6.95);

    this.shape_40 = new cjs.Shape();
    this.shape_40.graphics.f("#E99F2F").s().p("AAcBBIAAhHIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABdIgVAAIAAh9IAVAAIAAAOQAJgIAJgFQAJgEAKgBQASAAALANQAKAMAAAXIAABRg");
    this.shape_40.setTransform(-209.425, 8.45);

    this.shape_41 = new cjs.Shape();
    this.shape_41.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAJgMQAJgMAAgYQAAgXgJgMQgJgMgQAAQgQAAgJAMg");
    this.shape_41.setTransform(-222.15, 8.625);

    this.shape_42 = new cjs.Shape();
    this.shape_42.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAUAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_42.setTransform(-231, 6.475);

    this.shape_43 = new cjs.Shape();
    this.shape_43.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhEIgOAAIAAgSIAOAAIAAgkIAVAAIAAAkIAmAAIAAASIgmAAIAAA6IABAPQAAAFABAFQACADAFADQAEACAHAAIAKgCIAHgDIABAAIAAATIgMADIgMABQgQAAgJgKg");
    this.shape_43.setTransform(-237.4, 6.95);

    this.shape_44 = new cjs.Shape();
    this.shape_44.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgGQAKgFAPgBIAegDIAAgEQAAgIgDgEQgCgEgDgDQgFgCgGgCIgLgBQgHAAgKADQgJACgLAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAHAFQAHAGADAIQAEAIAAALIAABWIgVAAIAAgOIgHAGIgIAFIgLAFQgEABgKAAQgHAAgIgDgAAKgBIgSADQgIADgFAFQgGAFAAAKQAAAKAGAGQAGAFAMABQAJAAAIgFQAIgEAHgHIAAghIgTABg");
    this.shape_44.setTransform(-247.7, 8.65);

    this.shape_45 = new cjs.Shape();
    this.shape_45.graphics.f("#E99F2F").s().p("AgJA/Igvh9IAXAAIAiBgIAihgIAWAAIgwB9g");
    this.shape_45.setTransform(-259.075, 8.625);

    this.shape_46 = new cjs.Shape();
    this.shape_46.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_46.setTransform(-268.225, 8.625);

    this.shape_47 = new cjs.Shape();
    this.shape_47.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_47.setTransform(-279.125, 8.6);

    this.shape_48 = new cjs.Shape();
    this.shape_48.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQABgDAAgFQAAgIgDgEQgFgEgKgCIgKgDIgLgDQgPgEgHgHQgGgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQAKACAHAEIAAAXIgBAAQgIgGgKgEQgJgEgKAAQgKAAgHAFQgHAEgBAJQABAIAEAEQAEADAJADIAKACIANADQANADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgIADQgJADgMAAQgNAAgKgEg");
    this.shape_48.setTransform(-290.15, 8.625);

    this.shape_49 = new cjs.Shape();
    this.shape_49.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHAAAGgBQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAFgDAFIgDAPIBCAAIgCgPQgCgGgDgFQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_49.setTransform(-301.475, 8.6);

    this.shape_50 = new cjs.Shape();
    this.shape_50.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_50.setTransform(-310.975, 8.625);

    this.shape_51 = new cjs.Shape();
    this.shape_51.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_51.setTransform(383.575, -21.15);

    this.shape_52 = new cjs.Shape();
    this.shape_52.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_52.setTransform(371.125, -23.575);

    this.shape_53 = new cjs.Shape();
    this.shape_53.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAEQACAFAEABQAFACAHAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgRAAgJgKg");
    this.shape_53.setTransform(360.9, -22.8);

    this.shape_54 = new cjs.Shape();
    this.shape_54.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgRIAOAAIAAgFQAAgXALgLQALgMATAAIAMABIAKABIAAATIgBAAIgJgCIgJgBQgNAAgGAHQgEAFgBAQIAAAFIAiAAIAAARIghAAIAABrg");
    this.shape_54.setTransform(346.7, -23.65);

    this.shape_55 = new cjs.Shape();
    this.shape_55.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAZAAAQARQAPASAAAeQAAAggPARQgQARgZAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAKgMQAIgMAAgYQAAgXgIgMQgKgMgQAAQgQAAgJAMg");
    this.shape_55.setTransform(336.2, -21.125);

    this.shape_56 = new cjs.Shape();
    this.shape_56.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIABAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQACgDgBgFQAAgIgDgEQgFgEgKgCIgKgDIgLgDQgPgEgHgHQgGgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQAKACAHAEIAAAXIgBAAQgIgGgKgEQgJgEgKAAQgKAAgHAFQgHAEgBAJQABAIAEAEQAEADAJADIAKACIANADQANADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgIADQgJADgMAAQgNAAgKgEg");
    this.shape_56.setTransform(317.8, -21.125);

    this.shape_57 = new cjs.Shape();
    this.shape_57.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgFQgFgHgDgJQgDgJAAgMIAAhQIAVAAIAABGIABAQQAAAHADAFQACAFAFACQAEADAJAAQAGAAAJgFQAJgFAHgGIAAhcIAVAAIAAB8IgVAAIAAgOQgKAJgIAEQgJAEgKABQgIgBgHgCg");
    this.shape_57.setTransform(306.025, -20.95);

    this.shape_58 = new cjs.Shape();
    this.shape_58.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAEQACAFAEABQAEACAIAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgQAAgKgKg");
    this.shape_58.setTransform(295.9, -22.8);

    this.shape_59 = new cjs.Shape();
    this.shape_59.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgFQAKgFAPgCIAegDIAAgEQAAgIgCgEQgDgFgEgCQgEgCgGgBIgKgBQgIgBgKADQgJACgKAEIgCAAIAAgVIARgEQALgCALAAQALAAAJACQAJACAIAGQAGAFADAHQAEAJAAALIAABVIgVAAIAAgNIgHAFIgJAGIgKAEQgFACgJAAQgIAAgGgDgAAKAAIgSACQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALgBQAKAAAHgDQAJgFAHgGIAAgiIgTACg");
    this.shape_59.setTransform(285.6, -21.1);

    this.shape_60 = new cjs.Shape();
    this.shape_60.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAEQACAFAEABQAFACAHAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgQAAgKgKg");
    this.shape_60.setTransform(276.2, -22.8);

    this.shape_61 = new cjs.Shape();
    this.shape_61.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIACAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQACgDgBgFQAAgIgDgEQgFgEgKgCIgJgDIgMgDQgPgEgHgHQgGgJAAgMQAAgRANgLQANgLAUAAQALAAAKADQAKACAIAEIAAAXIgBAAQgIgGgKgEQgLgEgJAAQgKAAgHAFQgHAEAAAJQAAAIAEAEQAEADAKADIAJACIAMADQAOADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgJADQgIADgMAAQgMAAgMgEg");
    this.shape_61.setTransform(267.3, -21.125);

    this.shape_62 = new cjs.Shape();
    this.shape_62.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_62.setTransform(248.775, -21.15);

    this.shape_63 = new cjs.Shape();
    this.shape_63.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_63.setTransform(236.325, -23.575);

    this.shape_64 = new cjs.Shape();
    this.shape_64.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQAEACAHAAIAKgBIAHgCIABAAIAAATIgMACIgMABQgQAAgJgKg");
    this.shape_64.setTransform(226.1, -22.8);

    this.shape_65 = new cjs.Shape();
    this.shape_65.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_65.setTransform(208.925, -21.15);

    this.shape_66 = new cjs.Shape();
    this.shape_66.graphics.f("#E99F2F").s().p("AgWBXIgSgEIAAgWIACAAIAGACIAJADIALACIALABQAJAAAGgCQAHgDAEgEQAEgFABgGIABgOIAAgLQgIAIgIADQgIAEgMAAQgVAAgNgQQgNgQAAgdQAAgQAFgMQAEgMAIgIQAHgIAJgFQAKgEAJAAQAKAAAHACQAHACAHAFIACgGIAUAAIAABuQAAAggOAPQgOAPgcAAIgTgBgAgVg4QgJALAAAWQAAAVAHAKQAHALAQAAQAHAAAJgDQAJgDAGgGIAAhEIgOgGIgNgBQgPAAgKAMg");
    this.shape_66.setTransform(196.075, -18.925);

    this.shape_67 = new cjs.Shape();
    this.shape_67.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_67.setTransform(183.775, -21.3);

    this.shape_68 = new cjs.Shape();
    this.shape_68.graphics.f("#E99F2F").s().p("AgZA/QgHgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgFQAKgFAPgCIAegDIAAgEQAAgIgCgEQgCgFgFgCQgDgCgHgBIgKgBQgIgBgKADQgKACgJAEIgCAAIAAgVIARgEQALgCALAAQAMAAAIACQAJACAHAGQAHAFADAHQAEAJAAALIAABVIgVAAIAAgNIgHAFIgJAGIgKAEQgFACgJAAQgHAAgHgDgAAKAAIgSACQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALgBQAKAAAHgDQAJgFAHgGIAAgiIgTACg");
    this.shape_68.setTransform(170.95, -21.1);

    this.shape_69 = new cjs.Shape();
    this.shape_69.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_69.setTransform(158.925, -23.575);

    this.shape_70 = new cjs.Shape();
    this.shape_70.graphics.f("#E99F2F").s().p("AgMA+QgLgEgHgJQgIgIgEgMQgFgMAAgRQAAgPAFgMQAEgMAIgJQAHgIALgEQALgFAMAAQAKAAAKADQAJADAIAEIAAAYIgBAAIgGgFIgJgFIgKgEIgLgBQgQAAgKAMQgKANAAAVQAAAWAKANQAJAMARAAQAKAAAJgEQAKgEAHgHIABAAIAAAYIgIADIgIADIgKADIgLABQgMAAgLgEg");
    this.shape_70.setTransform(147.275, -21.125);

    this.shape_71 = new cjs.Shape();
    this.shape_71.graphics.f("#E99F2F").s().p("AgYBVQgJgEgGgJQgGgIgDgMQgEgNAAgQQAAgQAFgLQAEgMAHgJQAHgIAKgFQAKgEAJAAQAKAAAHACQAHACAHAEIAAg2IAWAAIAACtIgWAAIAAgMIgIAGIgIAGIgKADIgKABQgKAAgJgEgAgVgLQgJALAAAXQAAAWAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhHQgHgEgHgCQgGgBgHAAQgPAAgKAMg");
    this.shape_71.setTransform(127.875, -23.4);

    this.shape_72 = new cjs.Shape();
    this.shape_72.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_72.setTransform(115.575, -21.3);

    this.shape_73 = new cjs.Shape();
    this.shape_73.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgFQAKgFAPgCIAegDIAAgEQAAgIgDgEQgBgFgFgCQgDgCgHgBIgLgBQgHgBgKADQgJACgKAEIgCAAIAAgVIARgEQALgCAKAAQANAAAIACQAJACAHAGQAHAFADAHQAEAJAAALIAABVIgVAAIAAgNIgHAFIgIAGIgLAEQgEACgKAAQgHAAgIgDgAAKAAIgSACQgIACgFAGQgGAFAAAKQAAALAGAFQAGAGAMgBQAIAAAJgDQAIgFAHgGIAAgiIgTACg");
    this.shape_73.setTransform(102.75, -21.1);

    this.shape_74 = new cjs.Shape();
    this.shape_74.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIABAAIAHAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQABgDAAgFQAAgIgDgEQgFgEgKgCIgJgDIgNgDQgOgEgHgHQgGgJAAgMQAAgRANgLQANgLAUAAQALAAAKADQAKACAIAEIAAAXIgBAAQgIgGgKgEQgLgEgJAAQgKAAgHAFQgHAEAAAJQgBAIAFAEQAEADAKADIAJACIAMADQAOADAIAIQAGAIAAAOQAAAIgDAHQgDAHgHAFQgGAGgJADQgIADgMAAQgMAAgMgEg");
    this.shape_74.setTransform(84.95, -21.125);

    this.shape_75 = new cjs.Shape();
    this.shape_75.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQAEACAHAAIAKgBIAHgCIABAAIAAATIgMACIgMABQgPAAgKgKg");
    this.shape_75.setTransform(75.9, -22.8);

    this.shape_76 = new cjs.Shape();
    this.shape_76.graphics.f("#E99F2F").s().p("AgaA+QgMgDgGgEIAAgXIAAAAIAHAEIAKAGIALAEQAHACAHAAIAKgBQAGgBADgCIAFgGQACgDAAgFQgBgIgEgEQgDgEgLgCIgKgDIgMgDQgOgEgGgHQgHgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQALACAGAEIAAAXIgBAAQgHgGgLgEQgJgEgLAAQgJAAgHAFQgIAEAAAJQAAAIAFAEQAEADAJADIAKACIANADQANADAHAIQAIAIgBAOQAAAIgDAHQgDAHgGAFQgHAGgIADQgJADgMAAQgMAAgLgEg");
    this.shape_76.setTransform(67, -21.125);

    this.shape_77 = new cjs.Shape();
    this.shape_77.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_77.setTransform(55.675, -21.15);

    this.shape_78 = new cjs.Shape();
    this.shape_78.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgFQgFgHgDgJQgDgJAAgMIAAhQIAVAAIAABGIABAQQAAAHADAFQACAFAFACQAEADAJAAQAGAAAJgFQAJgFAHgGIAAhcIAVAAIAAB8IgVAAIAAgOQgKAJgIAEQgJAEgKABQgIgBgHgCg");
    this.shape_78.setTransform(43.125, -20.95);

    this.shape_79 = new cjs.Shape();
    this.shape_79.graphics.f("#E99F2F").s().p("AgWBXIgSgEIAAgWIACAAIAGACIAJADIALACIALABQAJAAAGgCQAHgDAEgEQAEgFABgGIABgOIAAgLQgIAIgIADQgIAEgMAAQgVAAgNgQQgNgQAAgdQAAgQAFgMQAEgMAIgIQAHgIAJgFQAKgEAJAAQAKAAAHACQAHACAHAFIACgGIAUAAIAABuQAAAggOAPQgOAPgcAAIgTgBgAgVg4QgJALAAAWQAAAVAHAKQAHALAQAAQAHAAAJgDQAJgDAGgGIAAhEIgOgGIgNgBQgPAAgKAMg");
    this.shape_79.setTransform(29.975, -18.925);

    this.shape_80 = new cjs.Shape();
    this.shape_80.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgRIAOAAIAAgFQAAgXALgLQALgMATAAIALABIALABIAAATIgCAAIgIgCIgJgBQgNAAgGAHQgFAFAAAQIAAAFIAiAAIAAARIghAAIAABrg");
    this.shape_80.setTransform(13.8, -23.65);

    this.shape_81 = new cjs.Shape();
    this.shape_81.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAJgMQAJgMAAgYQAAgXgJgMQgJgMgQAAQgQAAgJAMg");
    this.shape_81.setTransform(3.3, -21.125);

    this.shape_82 = new cjs.Shape();
    this.shape_82.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQADACAIAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgRAAgJgKg");
    this.shape_82.setTransform(-13.9, -22.8);

    this.shape_83 = new cjs.Shape();
    this.shape_83.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_83.setTransform(-24.225, -21.3);

    this.shape_84 = new cjs.Shape();
    this.shape_84.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgFQgFgHgDgJQgDgJAAgMIAAhQIAVAAIAABGIABAQQAAAHADAFQACAFAFACQAEADAJAAQAGAAAJgFQAJgFAHgGIAAhcIAVAAIAAB8IgVAAIAAgOQgKAJgIAEQgJAEgKABQgIgBgHgCg");
    this.shape_84.setTransform(-37.175, -20.95);

    this.shape_85 = new cjs.Shape();
    this.shape_85.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAJgMQAJgMAAgYQAAgXgJgMQgJgMgQAAQgQAAgJAMg");
    this.shape_85.setTransform(-49.8, -21.125);

    this.shape_86 = new cjs.Shape();
    this.shape_86.graphics.f("#E99F2F").s().p("AA8BAIAAhHIAAgQQgBgIgCgDQgCgFgEgCQgFgDgHABQgGAAgIADQgHAFgIAHIAAAFIAAAGIAABRIgUAAIAAhHIAAgQQgBgIgCgDQgDgFgEgCQgEgDgHABQgHAAgHAEQgIAEgHAHIAABcIgVAAIAAh8IAVAAIAAAOQAIgJAJgEQAIgFAKABQALAAAIAEQAIAGAEALQAKgLAJgFQAJgGALABQAIAAAGACQAHADAFAFQAFAGADAIQADAJAAANIAABRg");
    this.shape_86.setTransform(-65.625, -21.3);

    this.shape_87 = new cjs.Shape();
    this.shape_87.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgFQALgFAOgCIAegDIAAgEQAAgIgCgEQgCgFgFgCQgEgCgFgBIgLgBQgIgBgKADQgKACgKAEIgBAAIAAgVIARgEQALgCALAAQALAAAJACQAJACAIAGQAGAFAEAHQADAJAAALIAABVIgVAAIAAgNIgHAFIgJAGIgKAEQgEACgKAAQgIAAgGgDgAAKAAIgSACQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALgBQAKAAAHgDQAJgFAHgGIAAgiIgTACg");
    this.shape_87.setTransform(-81.7, -21.1);

    this.shape_88 = new cjs.Shape();
    this.shape_88.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_88.setTransform(-100.575, -21.15);

    this.shape_89 = new cjs.Shape();
    this.shape_89.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_89.setTransform(-113.025, -23.575);

    this.shape_90 = new cjs.Shape();
    this.shape_90.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQADACAIAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgRAAgJgKg");
    this.shape_90.setTransform(-123.25, -22.8);

    this.shape_91 = new cjs.Shape();
    this.shape_91.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAEQACAFAEABQAFACAHAAIAKgBIAHgCIABAAIAAATIgMACIgMABQgQAAgJgKg");
    this.shape_91.setTransform(-138.15, -22.8);

    this.shape_92 = new cjs.Shape();
    this.shape_92.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAVAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_92.setTransform(-144.7, -23.275);

    this.shape_93 = new cjs.Shape();
    this.shape_93.graphics.f("#E99F2F").s().p("AgYBVQgJgEgGgJQgGgIgDgMQgEgNAAgQQAAgQAFgLQAEgMAHgJQAHgIAKgFQAKgEAJAAQAKAAAHACQAHACAHAEIAAg2IAWAAIAACtIgWAAIAAgMIgIAGIgIAGIgKADIgKABQgKAAgJgEgAgVgLQgJALAAAXQAAAWAHAMQAHAMAQAAQAHAAAJgEQAIgDAHgHIAAhHQgHgEgHgCQgGgBgHAAQgPAAgKAMg");
    this.shape_93.setTransform(-154.125, -23.4);

    this.shape_94 = new cjs.Shape();
    this.shape_94.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_94.setTransform(-166.075, -21.15);

    this.shape_95 = new cjs.Shape();
    this.shape_95.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_95.setTransform(-185.725, -21.3);

    this.shape_96 = new cjs.Shape();
    this.shape_96.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgFQALgFAOgCIAegDIAAgEQAAgIgDgEQgCgFgDgCQgFgCgFgBIgMgBQgHgBgKADQgJACgLAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAHAGQAHAFAEAHQADAJAAALIAABVIgVAAIAAgNIgHAFIgIAGIgLAEQgFACgJAAQgHAAgIgDgAAKAAIgSACQgIACgFAGQgGAFAAAKQAAALAGAFQAGAGAMgBQAIAAAJgDQAIgFAHgGIAAgiIgTACg");
    this.shape_96.setTransform(-198.55, -21.1);

    this.shape_97 = new cjs.Shape();
    this.shape_97.graphics.f("#E99F2F").s().p("AgMA+QgLgEgHgJQgIgIgEgMQgFgMAAgRQAAgPAFgMQAEgMAIgJQAHgIALgEQALgFAMAAQAKAAAKADQAJADAIAEIAAAYIgBAAIgGgFIgJgFIgKgEIgLgBQgQAAgKAMQgKANAAAVQAAAWAKANQAJAMARAAQAKAAAJgEQAKgEAHgHIABAAIAAAYIgIADIgIADIgKADIgLABQgMAAgLgEg");
    this.shape_97.setTransform(-209.375, -21.125);

    this.shape_98 = new cjs.Shape();
    this.shape_98.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgFQgFgHgDgJQgDgJAAgMIAAhQIAVAAIAABGIABAQQAAAHADAFQACAFAFACQAEADAJAAQAGAAAJgFQAJgFAHgGIAAhcIAVAAIAAB8IgVAAIAAgOQgKAJgIAEQgJAEgKABQgIgBgHgCg");
    this.shape_98.setTransform(-228.475, -20.95);

    this.shape_99 = new cjs.Shape();
    this.shape_99.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgKAMABAXQgBAYAKAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_99.setTransform(-241.1, -21.125);

    this.shape_100 = new cjs.Shape();
    this.shape_100.graphics.f("#E99F2F").s().p("AgKBTIAAhHIg5hfIAZAAIAqBKIAshKIAXAAIg4BdIAABJg");
    this.shape_100.setTransform(-253.95, -23.2);

    this.shape_101 = new cjs.Shape();
    this.shape_101.graphics.f("#E99F2F").s().p("AgMAQIAAgfIAZAAIAAAfg");
    this.shape_101.setTransform(-271.275, -16.45);

    this.shape_102 = new cjs.Shape();
    this.shape_102.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIABQgHAAgIAEQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgFAKABQASAAALAMQAKAMAAAXIAABQg");
    this.shape_102.setTransform(-281.125, -21.3);

    this.shape_103 = new cjs.Shape();
    this.shape_103.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgJAMAAAXQAAAYAJAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_103.setTransform(-293.85, -21.125);

    this.shape_104 = new cjs.Shape();
    this.shape_104.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAUAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_104.setTransform(-302.7, -23.275);

    this.shape_105 = new cjs.Shape();
    this.shape_105.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQADACAIAAIAKgBIAHgCIABAAIAAATIgMACIgLABQgRAAgJgKg");
    this.shape_105.setTransform(-309.1, -22.8);

    this.shape_106 = new cjs.Shape();
    this.shape_106.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgFQALgFAOgCIAegDIAAgEQAAgIgCgEQgDgFgDgCQgEgCgGgBIgMgBQgHgBgKADQgKACgKAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAIAGQAGAFAEAHQADAJAAALIAABVIgVAAIAAgNIgHAFIgJAGIgKAEQgFACgJAAQgIAAgGgDgAAKAAIgSACQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALgBQAKAAAHgDQAJgFAHgGIAAgiIgTACg");
    this.shape_106.setTransform(-319.4, -21.1);

    this.shape_107 = new cjs.Shape();
    this.shape_107.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_107.setTransform(-328.475, -21.125);

    this.shape_108 = new cjs.Shape();
    this.shape_108.graphics.f("#E99F2F").s().p("AgMBIQgKgJAAgWIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAEQACAFAFABQADACAIAAIAKgBIAHgCIABAAIAAATIgMACIgMABQgPAAgKgKg");
    this.shape_108.setTransform(-337.1, -22.8);

    this.shape_109 = new cjs.Shape();
    this.shape_109.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIABAAIAHAEIAKAGIALAEQAHACAHAAIAKgBQAGgBADgCIAFgGQACgDAAgFQAAgIgFgEQgDgEgLgCIgKgDIgMgDQgOgEgGgHQgHgJAAgMQAAgRANgLQAMgLAVAAQALAAAKADQAKACAIAEIAAAXIgCAAQgHgGgLgEQgJgEgLAAQgJAAgHAFQgIAEAAAJQAAAIAFAEQAEADAJADIAKACIAMADQAOADAHAIQAIAIgBAOQAAAIgDAHQgDAHgGAFQgHAGgJADQgIADgMAAQgMAAgMgEg");
    this.shape_109.setTransform(-346, -21.125);

    this.shape_110 = new cjs.Shape();
    this.shape_110.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAUAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_110.setTransform(-353.9, -23.275);

    this.shape_111 = new cjs.Shape();
    this.shape_111.graphics.f("#E99F2F").s().p("AgWBXIgSgEIAAgWIACAAIAGACIAJADIALACIALABQAJAAAGgCQAHgDAEgEQAEgFABgGIABgOIAAgLQgIAIgIADQgIAEgMAAQgVAAgNgQQgNgQAAgdQAAgQAFgMQAEgMAIgIQAHgIAJgFQAKgEAJAAQAKAAAHACQAHACAHAFIACgGIAUAAIAABuQAAAggOAPQgOAPgcAAIgTgBgAgVg4QgJALAAAWQAAAVAHAKQAHALAQAAQAHAAAJgDQAJgDAGgGIAAhEIgOgGIgNgBQgPAAgKAMg");
    this.shape_111.setTransform(-363.325, -18.925);

    this.shape_112 = new cjs.Shape();
    this.shape_112.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgdAQgTQAQgSAZAAQAMAAAJAEQAJADAGAHQAHAHAEALQADAKAAAPIAAALIhWAAQAAAVALAMQALAMATAAQAHgBAGgBQAHgBAFgDIAKgFIAHgFIABAAIAAAYIgJADIgKADIgLADIgNABQgdAAgRgRgAgLgtQgGADgEAEQgFAGgDAFIgDAOIBCAAIgCgPQgCgFgDgFQgEgFgGgCQgGgCgIgBQgIABgGACg");
    this.shape_112.setTransform(-375.275, -21.15);

    this.shape_113 = new cjs.Shape();
    this.shape_113.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_113.setTransform(-384.775, -21.125);

    this.shape_114 = new cjs.Shape();
    this.shape_114.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_114.setTransform(362.175, -50.9);

    this.shape_115 = new cjs.Shape();
    this.shape_115.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_115.setTransform(349.725, -53.325);

    this.shape_116 = new cjs.Shape();
    this.shape_116.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAFQACADAFACQAEADAHAAIAKgCIAHgDIABAAIAAATIgMADIgMABQgQAAgJgKg");
    this.shape_116.setTransform(339.5, -52.55);

    this.shape_117 = new cjs.Shape();
    this.shape_117.graphics.f("#E99F2F").s().p("AgYBUQgJgDgGgJQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHACAHAFIAAg2IAWAAIAACtIgWAAIAAgNIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgEAHgGIAAhIQgHgDgHgCQgGgBgHAAQgPAAgKAMg");
    this.shape_117.setTransform(321.575, -53.15);

    this.shape_118 = new cjs.Shape();
    this.shape_118.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgEAKgBQASAAALANQAKAMAAAXIAABQg");
    this.shape_118.setTransform(309.275, -51.05);

    this.shape_119 = new cjs.Shape();
    this.shape_119.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_119.setTransform(296.775, -50.9);

    this.shape_120 = new cjs.Shape();
    this.shape_120.graphics.f("#E99F2F").s().p("AA8BAIAAhHIAAgQQgBgHgCgFQgCgEgEgCQgFgDgHAAQgGAAgIAFQgHADgIAIIAAAFIAAAGIAABRIgUAAIAAhHIAAgQQgBgHgCgFQgDgEgEgCQgEgDgHAAQgHAAgHAFQgIAEgHAHIAABcIgVAAIAAh8IAVAAIAAAOQAIgJAJgEQAIgEAKgBQALAAAIAGQAIAEAEAMQAKgKAJgGQAJgFALgBQAIABAGACQAHACAFAGQAFAGADAJQADAIAAANIAABRg");
    this.shape_120.setTransform(281.125, -51.05);

    this.shape_121 = new cjs.Shape();
    this.shape_121.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgGQALgFAOgBIAegDIAAgEQAAgIgDgEQgCgFgDgCQgFgDgFgBIgMgBQgHABgKACQgKACgKAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAIAFQAGAGAEAIQADAIAAAMIAABUIgVAAIAAgNIgHAGIgJAFIgKAFQgFABgJAAQgIAAgGgDgAAKgBIgSADQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGAMAAQAIAAAIgFQAJgEAHgHIAAghIgTABg");
    this.shape_121.setTransform(265.05, -50.85);

    this.shape_122 = new cjs.Shape();
    this.shape_122.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAKAMAPAAQAQAAAJgMQAJgMAAgYQAAgXgJgMQgJgMgQAAQgPAAgKAMg");
    this.shape_122.setTransform(245.95, -50.875);

    this.shape_123 = new cjs.Shape();
    this.shape_123.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAFQACADAEACQAEADAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_123.setTransform(235.95, -52.55);

    this.shape_124 = new cjs.Shape();
    this.shape_124.graphics.f("#E99F2F").s().p("AAcBAIAAhGIgBgQQgBgHgCgFQgDgEgEgDQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAh8IAVAAIAAAOQAJgJAJgEQAJgEAKgBQASAAALANQAKAMAAAXIAABQg");
    this.shape_124.setTransform(218.425, -51.05);

    this.shape_125 = new cjs.Shape();
    this.shape_125.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAZAAAQARQAPASAAAeQAAAggPARQgQARgZAAQgZAAgPgSgAgZgjQgIAMgBAXQABAYAIAMQAJAMAQAAQAQAAAKgMQAIgMAAgYQAAgXgIgMQgKgMgQAAQgQAAgJAMg");
    this.shape_125.setTransform(205.7, -50.875);

    this.shape_126 = new cjs.Shape();
    this.shape_126.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAVAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_126.setTransform(196.85, -53.025);

    this.shape_127 = new cjs.Shape();
    this.shape_127.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAFQACADAEACQAFADAHAAIAKgCIAHgDIABAAIAAATIgMADIgMABQgQAAgJgKg");
    this.shape_127.setTransform(190.45, -52.55);

    this.shape_128 = new cjs.Shape();
    this.shape_128.graphics.f("#E99F2F").s().p("AgaA/QgGgDgGgFQgFgGgDgHQgDgHAAgJQAAgNAFgJQAGgIAMgGQAKgFAPgBIAegDIAAgEQAAgIgDgEQgBgFgFgCQgDgDgHgBIgLgBQgHABgKACQgJACgKAEIgCAAIAAgVIARgEQALgCAKAAQANAAAIACQAJACAHAFQAHAGADAIQAEAIAAAMIAABUIgVAAIAAgNIgHAGIgIAFIgLAFQgEABgKAAQgHAAgIgDgAAKgBIgSADQgIACgFAGQgGAFAAAKQAAALAGAFQAGAGAMAAQAIAAAJgFQAIgEAHgHIAAghIgTABg");
    this.shape_128.setTransform(180.15, -50.85);

    this.shape_129 = new cjs.Shape();
    this.shape_129.graphics.f("#E99F2F").s().p("AgJA/Igvh9IAXAAIAiBgIAihgIAWAAIgwB9g");
    this.shape_129.setTransform(168.775, -50.875);

    this.shape_130 = new cjs.Shape();
    this.shape_130.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_130.setTransform(159.625, -50.875);

    this.shape_131 = new cjs.Shape();
    this.shape_131.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_131.setTransform(148.725, -50.9);

    this.shape_132 = new cjs.Shape();
    this.shape_132.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIACAAIAGAEIAJAGIANAEQAGACAHAAIAKgBQAFgBADgCIAGgGQACgDgBgFQAAgIgDgEQgFgEgKgCIgJgDIgMgDQgPgEgHgHQgGgJAAgMQAAgRANgLQANgLAUAAQALAAAKADQAKACAIAEIAAAXIgBAAQgJgGgJgEQgLgEgJAAQgKAAgHAFQgHAEAAAJQAAAIAEAEQAEADAKADIAJACIAMADQAOADAIAIQAGAIABAOQAAAIgEAHQgDAHgHAFQgGAGgJADQgIADgMAAQgMAAgMgEg");
    this.shape_132.setTransform(137.7, -50.875);

    this.shape_133 = new cjs.Shape();
    this.shape_133.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_133.setTransform(126.375, -50.9);

    this.shape_134 = new cjs.Shape();
    this.shape_134.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_134.setTransform(116.875, -50.875);

    this.shape_135 = new cjs.Shape();
    this.shape_135.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgSIAOAAIAAgDQAAgYALgLQALgMATAAIALABIALABIAAAUIgCAAIgIgCIgJgCQgNABgGAFQgFAGABARIAAADIAhAAIAAASIghAAIAABrg");
    this.shape_135.setTransform(101.75, -53.4);

    this.shape_136 = new cjs.Shape();
    this.shape_136.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAQgSQAPgRAYAAQAaAAAPARQAPASAAAeQAAAggPARQgPARgaAAQgZAAgPgSgAgYgjQgJAMAAAXQAAAYAJAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_136.setTransform(91.25, -50.875);

    this.shape_137 = new cjs.Shape();
    this.shape_137.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_137.setTransform(71.775, -50.9);

    this.shape_138 = new cjs.Shape();
    this.shape_138.graphics.f("#E99F2F").s().p("AA8BAIAAhHIAAgQQgBgHgCgFQgCgEgEgCQgFgDgHAAQgGAAgIAFQgHADgIAIIAAAFIAAAGIAABRIgUAAIAAhHIAAgQQgBgHgCgFQgDgEgEgCQgEgDgHAAQgHAAgHAFQgIAEgHAHIAABcIgVAAIAAh8IAVAAIAAAOQAIgJAJgEQAIgEAKgBQALAAAIAGQAIAEAEAMQAKgKAJgGQAJgFALgBQAIABAGACQAHACAFAGQAFAGADAJQADAIAAANIAABRg");
    this.shape_138.setTransform(56.125, -51.05);

    this.shape_139 = new cjs.Shape();
    this.shape_139.graphics.f("#E99F2F").s().p("AgKBUIAAh8IAUAAIAAB8gAgLg9IAAgWIAXAAIAAAWg");
    this.shape_139.setTransform(43.8, -53.025);

    this.shape_140 = new cjs.Shape();
    this.shape_140.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAFQACADAFACQADADAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_140.setTransform(37.4, -52.55);

    this.shape_141 = new cjs.Shape();
    this.shape_141.graphics.f("#E99F2F").s().p("AgpBpIBAjRIATAAIhADRg");
    this.shape_141.setTransform(28.625, -51.5);

    this.shape_142 = new cjs.Shape();
    this.shape_142.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_142.setTransform(18.625, -50.9);

    this.shape_143 = new cjs.Shape();
    this.shape_143.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IAAAPQABAFABAFQACADAEACQAEADAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgQAAgKgKg");
    this.shape_143.setTransform(8.8, -52.55);

    this.shape_144 = new cjs.Shape();
    this.shape_144.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgGQAKgFAPgBIAegDIAAgEQAAgIgCgEQgDgFgEgCQgEgDgGgBIgKgBQgIABgKACQgJACgKAEIgCAAIAAgVIARgEQALgCALAAQALAAAJACQAJACAIAFQAGAGADAIQAEAIAAAMIAABUIgVAAIAAgNIgHAGIgJAFIgKAFQgFABgJAAQgIAAgGgDgAAKgBIgSADQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALAAQAKAAAHgFQAJgEAHgHIAAghIgTABg");
    this.shape_144.setTransform(-1.5, -50.85);

    this.shape_145 = new cjs.Shape();
    this.shape_145.graphics.f("#E99F2F").s().p("AgYBUQgJgDgGgJQgGgIgDgMQgEgNAAgPQAAgRAFgLQAEgMAHgJQAHgIAKgEQAKgGAJAAQAKAAAHACQAHACAHAFIAAg2IAWAAIAACtIgWAAIAAgNIgIAIIgIAEIgKAEIgKABQgKAAgJgFgAgVgLQgJALAAAYQAAAVAHAMQAHAMAQAAQAHAAAJgEQAIgEAHgGIAAhIQgHgDgHgCQgGgBgHAAQgPAAgKAMg");
    this.shape_145.setTransform(-13.925, -53.15);

    this.shape_146 = new cjs.Shape();
    this.shape_146.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_146.setTransform(-33.075, -50.9);

    this.shape_147 = new cjs.Shape();
    this.shape_147.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_147.setTransform(-45.525, -53.325);

    this.shape_148 = new cjs.Shape();
    this.shape_148.graphics.f("#E99F2F").s().p("AgMBIQgKgKAAgVIAAhFIgOAAIAAgRIAOAAIAAgkIAVAAIAAAkIAmAAIAAARIgmAAIAAA7IABAPQAAAFABAFQACADAFACQADADAIAAIAKgCIAHgDIABAAIAAATIgMADIgLABQgRAAgJgKg");
    this.shape_148.setTransform(-55.75, -52.55);

    this.shape_149 = new cjs.Shape();
    this.shape_149.graphics.f("#E99F2F").s().p("AgYBYIAAhrIgOAAIAAgSIAOAAIAAgDQAAgYALgLQALgMATAAIALABIALABIAAAUIgCAAIgHgCIgKgCQgNABgGAFQgFAGABARIAAADIAiAAIAAASIgiAAIAABrg");
    this.shape_149.setTransform(-69.95, -53.4);

    this.shape_150 = new cjs.Shape();
    this.shape_150.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAaAAAOARQAQASAAAeQAAAggQARQgOARgaAAQgZAAgPgSgAgYgjQgKAMABAXQgBAYAKAMQAIAMAQAAQARAAAIgMQAJgMAAgYQAAgXgJgMQgIgMgRAAQgQAAgIAMg");
    this.shape_150.setTransform(-80.45, -50.875);

    this.shape_151 = new cjs.Shape();
    this.shape_151.graphics.f("#E99F2F").s().p("AgbA+QgKgDgIgEIAAgXIABAAIAHAEIAJAGIAMAEQAHACAHAAIAKgBQAGgBACgCIAGgGQABgDABgFQAAgIgFgEQgDgEgLgCIgJgDIgNgDQgOgEgGgHQgHgJAAgMQAAgRANgLQAMgLAVAAQAKAAALADQAKACAIAEIAAAXIgBAAQgJgGgJgEQgKgEgLAAQgJAAgHAFQgIAEABAJQgBAIAFAEQAEADAKADIAJACIAMADQAOADAHAIQAIAIgBAOQABAIgEAHQgDAHgGAFQgHAGgJADQgIADgMAAQgNAAgLgEg");
    this.shape_151.setTransform(-98.85, -50.875);

    this.shape_152 = new cjs.Shape();
    this.shape_152.graphics.f("#E99F2F").s().p("AggA/IAAh9IAVAAIAAATQALgKAIgEQAIgFAIAAIAFABIAEAAIAAAWIgBAAIgFgBIgGAAQgJAAgIAEQgIADgHAIIAABYg");
    this.shape_152.setTransform(-107.575, -50.875);

    this.shape_153 = new cjs.Shape();
    this.shape_153.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgGQgFgFgDgKQgDgJAAgMIAAhRIAVAAIAABHIABARQAAAGADAFQACAFAFACQAEADAJgBQAGAAAJgEQAJgFAHgGIAAhdIAVAAIAAB9IgVAAIAAgOQgKAJgIAEQgJAFgKgBQgIAAgHgCg");
    this.shape_153.setTransform(-118.925, -50.7);

    this.shape_154 = new cjs.Shape();
    this.shape_154.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAZAAAPARQAQASAAAeQAAAggQARQgPARgZAAQgZAAgPgSgAgZgjQgJAMAAAXQAAAYAJAMQAKAMAPAAQARAAAJgMQAIgMAAgYQAAgXgIgMQgJgMgRAAQgPAAgKAMg");
    this.shape_154.setTransform(-131.55, -50.875);

    this.shape_155 = new cjs.Shape();
    this.shape_155.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_155.setTransform(-144.175, -53.325);

    this.shape_156 = new cjs.Shape();
    this.shape_156.graphics.f("#E99F2F").s().p("AgzBVIAAgXIAVgUIAUgVQASgVAHgLQAHgNAAgNQAAgHgCgGQgDgFgDgDQgEgEgFgBQgFgCgGAAQgGAAgGACIgMADIgJAFIgHAEIgBAAIAAgXQAHgEAMgDQANgDALAAQAWAAANAMQAOAMAAAWQAAAJgDAJQgCAIgEAHIgKAOIgMAOIgXAYIgVATIBSAAIAAATg");
    this.shape_156.setTransform(-163.975, -53.125);

    this.shape_157 = new cjs.Shape();
    this.shape_157.graphics.f("#E99F2F").s().p("AgqBTIBMiSIhWAAIAAgTIBpAAIAAAZIhHCMg");
    this.shape_157.setTransform(-176.45, -52.95);

    this.shape_158 = new cjs.Shape();
    this.shape_158.graphics.f("#E99F2F").s().p("AglAxQgQgRAAgfQAAgeAQgRQAQgTAZAAQAMAAAJADQAJAEAGAHQAHAHAEAKQADALAAAPIAAAKIhWAAQAAAXALALQALALATAAQAHABAGgCQAHgCAFgCIAKgFIAHgEIABAAIAAAWIgJAEIgKAEIgLACIgNABQgdAAgRgRgAgLgtQgGADgEAFQgFAEgDAHIgDANIBCAAIgCgOQgCgHgDgEQgEgEgGgDQgGgCgIAAQgIAAgGACg");
    this.shape_158.setTransform(-196.075, -50.9);

    this.shape_159 = new cjs.Shape();
    this.shape_159.graphics.f("#E99F2F").s().p("AgJA/Igvh9IAXAAIAiBgIAihgIAWAAIgwB9g");
    this.shape_159.setTransform(-207.875, -50.875);

    this.shape_160 = new cjs.Shape();
    this.shape_160.graphics.f("#E99F2F").s().p("AgZA/QgIgDgFgFQgFgGgDgHQgDgHAAgJQAAgNAGgJQAFgIAMgGQALgFAOgBIAegDIAAgEQAAgIgCgEQgDgFgDgCQgEgDgGgBIgMgBQgHABgKACQgKACgKAEIgBAAIAAgVIARgEQALgCAKAAQAMAAAKACQAIACAIAFQAGAGAEAIQADAIAAAMIAABUIgVAAIAAgNIgHAGIgJAFIgKAFQgFABgJAAQgIAAgGgDgAAKgBIgSADQgIACgGAGQgFAFAAAKQAAALAGAFQAGAGALAAQAKAAAHgFQAJgEAHgHIAAghIgTABg");
    this.shape_160.setTransform(-219.95, -50.85);

    this.shape_161 = new cjs.Shape();
    this.shape_161.graphics.f("#E99F2F").s().p("AAcBXIAAhHIgBgQQgBgHgCgEQgDgFgEgCQgFgDgIAAQgHAAgIAFQgIAEgIAHIAABcIgVAAIAAitIAVAAIAAA/QAJgJAJgEQAJgFAKAAQASAAALANQAKAMAAAWIAABRg");
    this.shape_161.setTransform(-231.975, -53.325);

    this.shape_162 = new cjs.Shape();
    this.shape_162.graphics.f("#E99F2F").s().p("AgYA+QgIgDgFgGQgFgFgDgKQgDgJAAgMIAAhRIAVAAIAABHIABARQAAAGADAFQACAFAFACQAEADAJgBQAGAAAJgEQAJgFAHgGIAAhdIAVAAIAAB9IgVAAIAAgOQgKAJgIAEQgJAFgKgBQgIAAgHgCg");
    this.shape_162.setTransform(-252.125, -50.7);

    this.shape_163 = new cjs.Shape();
    this.shape_163.graphics.f("#E99F2F").s().p("AgoAwQgPgRAAgfQAAgeAPgSQAQgRAYAAQAZAAAPARQAQASAAAeQAAAggQARQgPARgZAAQgZAAgPgSgAgZgjQgJAMAAAXQAAAYAJAMQAKAMAPAAQARAAAJgMQAIgMAAgYQAAgXgIgMQgJgMgRAAQgPAAgKAMg");
    this.shape_163.setTransform(-264.75, -50.875);

    this.shape_164 = new cjs.Shape();
    this.shape_164.graphics.f("#E99F2F").s().p("AggBWIAWg0Iguh3IAXAAIAhBcIAjhcIAWAAIhDCrg");
    this.shape_164.setTransform(-276.725, -48.55);

    this.shape_165 = new cjs.Shape();
    this.shape_165.graphics.f("#E99F2F").s().p("AgTA/IAAgsIAnAAIAAAsgAgTgSIAAgsIAnAAIAAAsg");
    this.shape_165.setTransform(-293.825, -50.9);

    this.shape_166 = new cjs.Shape();
    this.shape_166.graphics.f("#E99F2F").s().p("Ag9BYIAAisIAoAAIAAAOQAIgIAJgEQAIgFAMAAQAWAAAMARQAMARAAAeQAAAegPATQgQASgWAAQgJAAgHgCQgHgCgHgFIAAA1gAgLg3IgKAFIAAA+IAHACIAIABQAOAAAHgJQAHgIAAgUQAAgSgGgIQgGgJgLAAIgKACg");
    this.shape_166.setTransform(-304.825, -48.75);

    this.shape_167 = new cjs.Shape();
    this.shape_167.graphics.f("#E99F2F").s().p("AgTBXIAAh9IAnAAIAAB9gAgUg4IAAgeIApAAIAAAeg");
    this.shape_167.setTransform(-315.975, -53.325);

    this.shape_168 = new cjs.Shape();
    this.shape_168.graphics.f("#E99F2F").s().p("AgTBIQgLgKAAgWIAAg6IgPAAIAAgbIAPAAIAAgkIAmAAIAAAkIAmAAIAAAbIgmAAIAAAsIABALQgBAFACAEQACAEAEACQADADAJAAIAIgCIAHgCIADAAIAAAcIgOACIgRABQgXAAgLgKg");
    this.shape_168.setTransform(-324.15, -52.575);

    this.shape_169 = new cjs.Shape();
    this.shape_169.graphics.f("#E99F2F").s().p("AgvAxQgRgSAAgfQAAgfARgRQARgSAeAAQAfAAARASQARASAAAeQAAAfgRASQgRASgfAAQgeAAgRgSgAgJgkQgEABgEAFQgDAEgCAIQgCAIAAAKQAAAMACAHQACAHADAEQADAFAFABQAFACAEAAQAFAAAFgCQAFgBACgFQAEgEACgHQACgHAAgMQAAgKgCgHQgCgIgDgEQgEgFgEgCQgFgCgFAAQgFAAgEACg");
    this.shape_169.setTransform(-336.1, -50.9);

    this.shape_170 = new cjs.Shape();
    this.shape_170.graphics.f("#E99F2F").s().p("AgpA/IAAh9IAnAAIAAASIAHgGIAIgGIAKgEQAGgCAFAAIAEAAIAEAAIAAAlIgDAAIgGgBIgKAAQgGAAgHACIgMAEIAABTg");
    this.shape_170.setTransform(-347.425, -50.925);

    this.shape_171 = new cjs.Shape();
    this.shape_171.graphics.f("#E99F2F").s().p("Ag/BTIAAilIA/AAQAPgBALADQAKADAIAFQAKAHAFAKQAFAKAAAPQAAALgDALQgEAKgHAHIgJAIIgMAHIgNADQgHACgJAAIgVAAIAAA2gAgVgBIACAAIAMAAIAIgBIAHgCIAGgEQAEgFACgEQABgFAAgHQAAgHgDgFQgEgFgEgCQgGgCgFgBIgPgBIgFAAg");
    this.shape_171.setTransform(-360.075, -52.95);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_171
      }, {
        t: this.shape_170
      }, {
        t: this.shape_169
      }, {
        t: this.shape_168
      }, {
        t: this.shape_167
      }, {
        t: this.shape_166
      }, {
        t: this.shape_165
      }, {
        t: this.shape_164
      }, {
        t: this.shape_163
      }, {
        t: this.shape_162
      }, {
        t: this.shape_161
      }, {
        t: this.shape_160
      }, {
        t: this.shape_159
      }, {
        t: this.shape_158
      }, {
        t: this.shape_157
      }, {
        t: this.shape_156
      }, {
        t: this.shape_155
      }, {
        t: this.shape_154
      }, {
        t: this.shape_153
      }, {
        t: this.shape_152
      }, {
        t: this.shape_151
      }, {
        t: this.shape_150
      }, {
        t: this.shape_149
      }, {
        t: this.shape_148
      }, {
        t: this.shape_147
      }, {
        t: this.shape_146
      }, {
        t: this.shape_145
      }, {
        t: this.shape_144
      }, {
        t: this.shape_143
      }, {
        t: this.shape_142
      }, {
        t: this.shape_141
      }, {
        t: this.shape_140
      }, {
        t: this.shape_139
      }, {
        t: this.shape_138
      }, {
        t: this.shape_137
      }, {
        t: this.shape_136
      }, {
        t: this.shape_135
      }, {
        t: this.shape_134
      }, {
        t: this.shape_133
      }, {
        t: this.shape_132
      }, {
        t: this.shape_131
      }, {
        t: this.shape_130
      }, {
        t: this.shape_129
      }, {
        t: this.shape_128
      }, {
        t: this.shape_127
      }, {
        t: this.shape_126
      }, {
        t: this.shape_125
      }, {
        t: this.shape_124
      }, {
        t: this.shape_123
      }, {
        t: this.shape_122
      }, {
        t: this.shape_121
      }, {
        t: this.shape_120
      }, {
        t: this.shape_119
      }, {
        t: this.shape_118
      }, {
        t: this.shape_117
      }, {
        t: this.shape_116
      }, {
        t: this.shape_115
      }, {
        t: this.shape_114
      }, {
        t: this.shape_113
      }, {
        t: this.shape_112
      }, {
        t: this.shape_111
      }, {
        t: this.shape_110
      }, {
        t: this.shape_109
      }, {
        t: this.shape_108
      }, {
        t: this.shape_107
      }, {
        t: this.shape_106
      }, {
        t: this.shape_105
      }, {
        t: this.shape_104
      }, {
        t: this.shape_103
      }, {
        t: this.shape_102
      }, {
        t: this.shape_101
      }, {
        t: this.shape_100
      }, {
        t: this.shape_99
      }, {
        t: this.shape_98
      }, {
        t: this.shape_97
      }, {
        t: this.shape_96
      }, {
        t: this.shape_95
      }, {
        t: this.shape_94
      }, {
        t: this.shape_93
      }, {
        t: this.shape_92
      }, {
        t: this.shape_91
      }, {
        t: this.shape_90
      }, {
        t: this.shape_89
      }, {
        t: this.shape_88
      }, {
        t: this.shape_87
      }, {
        t: this.shape_86
      }, {
        t: this.shape_85
      }, {
        t: this.shape_84
      }, {
        t: this.shape_83
      }, {
        t: this.shape_82
      }, {
        t: this.shape_81
      }, {
        t: this.shape_80
      }, {
        t: this.shape_79
      }, {
        t: this.shape_78
      }, {
        t: this.shape_77
      }, {
        t: this.shape_76
      }, {
        t: this.shape_75
      }, {
        t: this.shape_74
      }, {
        t: this.shape_73
      }, {
        t: this.shape_72
      }, {
        t: this.shape_71
      }, {
        t: this.shape_70
      }, {
        t: this.shape_69
      }, {
        t: this.shape_68
      }, {
        t: this.shape_67
      }, {
        t: this.shape_66
      }, {
        t: this.shape_65
      }, {
        t: this.shape_64
      }, {
        t: this.shape_63
      }, {
        t: this.shape_62
      }, {
        t: this.shape_61
      }, {
        t: this.shape_60
      }, {
        t: this.shape_59
      }, {
        t: this.shape_58
      }, {
        t: this.shape_57
      }, {
        t: this.shape_56
      }, {
        t: this.shape_55
      }, {
        t: this.shape_54
      }, {
        t: this.shape_53
      }, {
        t: this.shape_52
      }, {
        t: this.shape_51
      }, {
        t: this.shape_50
      }, {
        t: this.shape_49
      }, {
        t: this.shape_48
      }, {
        t: this.shape_47
      }, {
        t: this.shape_46
      }, {
        t: this.shape_45
      }, {
        t: this.shape_44
      }, {
        t: this.shape_43
      }, {
        t: this.shape_42
      }, {
        t: this.shape_41
      }, {
        t: this.shape_40
      }, {
        t: this.shape_39
      }, {
        t: this.shape_38
      }, {
        t: this.shape_37
      }, {
        t: this.shape_36
      }, {
        t: this.shape_35
      }, {
        t: this.shape_34
      }, {
        t: this.shape_33
      }, {
        t: this.shape_32
      }, {
        t: this.shape_31
      }, {
        t: this.shape_30
      }, {
        t: this.shape_29
      }, {
        t: this.shape_28
      }, {
        t: this.shape_27
      }, {
        t: this.shape_26
      }, {
        t: this.shape_25
      }, {
        t: this.shape_24
      }, {
        t: this.shape_23
      }, {
        t: this.shape_22
      }, {
        t: this.shape_21
      }, {
        t: this.shape_20
      }, {
        t: this.shape_19
      }, {
        t: this.shape_18
      }, {
        t: this.shape_17
      }, {
        t: this.shape_16
      }, {
        t: this.shape_15
      }, {
        t: this.shape_14
      }, {
        t: this.shape_13
      }, {
        t: this.shape_12
      }, {
        t: this.shape_11
      }, {
        t: this.shape_10
      }, {
        t: this.shape_9
      }, {
        t: this.shape_8
      }, {
        t: this.shape_7
      }, {
        t: this.shape_6
      }, {
        t: this.shape_5
      }, {
        t: this.shape_4
      }, {
        t: this.shape_3
      }, {
        t: this.shape_2
      }, {
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400.6, -69.6, 801.2, 91.3);


  (lib.Tween79 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.resbtns();
    this.instance.setTransform(-184.05, -63.05, 0.3834, 0.3834);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-184, -63, 196.7, 103.9);


  (lib.Tween78 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.resbtns();
    this.instance.setTransform(-184.05, -63.05, 0.3834, 0.3834);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-184, -63, 196.7, 103.9);


  (lib.Tween77 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("wherever you are in the application, \nthis icon will always provide a shortcut back to your reservations.", "23px 'Tahoma'", "#252423");
    this.text.textAlign = "center";
    this.text.lineHeight = 30;
    this.text.lineWidth = 797;
    this.text.parent = this;
    this.text.setTransform(0, -47.8);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400.6, -49.8, 801.2, 99.69999999999999);


  (lib.Tween76 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("wherever you are in the application, \nthis icon will always provide a shortcut back to your reservations.", "23px 'Tahoma'", "#252423");
    this.text.textAlign = "center";
    this.text.lineHeight = 30;
    this.text.lineWidth = 797;
    this.text.parent = this;
    this.text.setTransform(0, -47.8);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400.6, -49.8, 801.2, 99.69999999999999);


  (lib.Tween75 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Reservations", "bold 43px 'Tahoma'", "#252423");
    this.text.textAlign = "center";
    this.text.lineHeight = 54;
    this.text.lineWidth = 286;
    this.text.parent = this;
    this.text.setTransform(17.85, -26.35);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-126.9, -28.3, 289.6, 59);


  (lib.Tween74 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Reservations", "bold 43px 'Tahoma'", "#252423");
    this.text.textAlign = "center";
    this.text.lineHeight = 54;
    this.text.lineWidth = 286;
    this.text.parent = this;
    this.text.setTransform(17.85, -26.35);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-126.9, -28.3, 289.6, 59);


  (lib.Tween73 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.restauranticox3();
    this.instance.setTransform(-145.75, -145.75, 0.5563, 0.5563);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-145.7, -145.7, 291.5, 291.5);


  (lib.Tween72 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.restauranticox3();
    this.instance.setTransform(-145.75, -145.75, 0.5563, 0.5563);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-145.7, -145.7, 291.5, 291.5);


  (lib.Tween71 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f().s("#252423").ss(0.1, 1, 1).p("Eg/Mg3gMB+ZAAAMAAABvBMh+ZAAAg");
    this.shape.setTransform(0.025, 0.025);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#252423").s().p("Eg/MA3hMAAAhvBMB+ZAAAMAAABvBg");
    this.shape_1.setTransform(0.025, 0.025);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-405.5, -356.3, 811.1, 712.7);


  (lib.Tween70 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f().s("#252423").ss(0.1, 1, 1).p("Eg/Mg3gMB+ZAAAMAAABvBMh+ZAAAg");
    this.shape.setTransform(0.025, 0.025);

    this.shape_1 = new cjs.Shape();
    this.shape_1.graphics.f("#252423").s().p("Eg/MA3hMAAAhvBMB+ZAAAMAAABvBg");
    this.shape_1.setTransform(0.025, 0.025);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape_1
      }, {
        t: this.shape
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-405.5, -356.3, 811.1, 712.7);


  (lib.Tween63 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Manage your personal info, reservations,\nchange users, logout, and much more", "25px 'Tahoma'", "#252423");
    this.text.textAlign = "center";
    this.text.lineHeight = 32;
    this.text.lineWidth = 796;
    this.text.parent = this;
    this.text.setTransform(0, -37.45);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-399.8, -39.4, 799.7, 78.9);


  (lib.Tween62 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Manage your personal info, reservations,\nchange users, logout, and much more", "25px 'Tahoma'", "#252423");
    this.text.textAlign = "center";
    this.text.lineHeight = 32;
    this.text.lineWidth = 796;
    this.text.parent = this;
    this.text.setTransform(0, -37.45);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-399.8, -39.4, 799.7, 78.9);


  (lib.Tween61 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Menu", "bold 43px 'Tahoma'", "#252423");
    this.text.lineHeight = 54;
    this.text.lineWidth = 138;
    this.text.parent = this;
    this.text.setTransform(-68.95, -29.5);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-70.9, -31.5, 141.9, 63);


  (lib.Tween60 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Menu", "bold 43px 'Tahoma'", "#252423");
    this.text.lineHeight = 54;
    this.text.lineWidth = 138;
    this.text.parent = this;
    this.text.setTransform(-68.95, -29.5);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-70.9, -31.5, 141.9, 63);


  (lib.Tween59 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.menuicox3();
    this.instance.setTransform(-136.6, -94.05, 0.5225, 0.5225);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-136.6, -94, 273.29999999999995, 188.1);


  (lib.Tween58 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.menuicox3();
    this.instance.setTransform(-136.6, -94.05, 0.5225, 0.5225);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-136.6, -94, 273.29999999999995, 188.1);


  (lib.Tween36 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("We will go in more details on the next pages", "22px 'Tahoma'", "#FF9933");
    this.text.lineHeight = 29;
    this.text.lineWidth = 542;
    this.text.parent = this;
    this.text.setTransform(-270.95, -18.65);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-272.9, -20.6, 545.9, 41.3);


  (lib.Tween35 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("We will go in more details on the next pages", "22px 'Tahoma'", "#FF9933");
    this.text.lineHeight = 29;
    this.text.lineWidth = 542;
    this.text.parent = this;
    this.text.setTransform(-270.95, -18.65);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-272.9, -20.6, 545.9, 41.3);


  (lib.Tween34 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("You find all the important stuff up in the top of the page", "22px 'Tahoma'", "#FFFFFF");
    this.text.lineHeight = 29;
    this.text.lineWidth = 633;
    this.text.parent = this;
    this.text.setTransform(-316.25, -27.55);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-318.2, -29.5, 636.5, 59.1);


  (lib.Tween33 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("You find all the important stuff up in the top of the page", "22px 'Tahoma'", "#FFFFFF");
    this.text.lineHeight = 29;
    this.text.lineWidth = 633;
    this.text.parent = this;
    this.text.setTransform(-316.25, -27.55);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-318.2, -29.5, 636.5, 59.1);


  (lib.Tween32 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Desktop", "17px 'Tahoma'", "#FFFFFF");
    this.text.lineHeight = 23;
    this.text.lineWidth = 100;
    this.text.parent = this;
    this.text.setTransform(-50, -10.25);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-52, -12.2, 104, 24.5);


  (lib.Tween31 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Desktop", "17px 'Tahoma'", "#FFFFFF");
    this.text.lineHeight = 23;
    this.text.lineWidth = 100;
    this.text.parent = this;
    this.text.setTransform(-50, -10.25);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-52, -12.2, 104, 24.5);


  (lib.Tween30 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Mobile", "17px 'Tahoma'", "#FFFFFF");
    this.text.lineHeight = 23;
    this.text.lineWidth = 100;
    this.text.parent = this;
    this.text.setTransform(-50, -10.25);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-52, -12.2, 104, 24.5);


  (lib.Tween29 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Mobile", "17px 'Tahoma'", "#FFFFFF");
    this.text.lineHeight = 23;
    this.text.lineWidth = 100;
    this.text.parent = this;
    this.text.setTransform(-50, -10.25);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-52, -12.2, 104, 24.5);


  (lib.Tween24 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.TopMenu111();
    this.instance.setTransform(-346.95, -5.15, 0.1312, 0.1312);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-346.9, -5.1, 681.3, 42.2);


  (lib.Tween23 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.TopMenu111();
    this.instance.setTransform(-346.95, -5.15, 0.1312, 0.1312);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-346.9, -5.1, 681.3, 42.2);


  (lib.Tween22 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.TopMenuMobile();
    this.instance.setTransform(-192.35, -7.35, 0.2134, 0.2134);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-192.3, -7.3, 334.3, 43.9);


  (lib.Tween21 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.TopMenuMobile();
    this.instance.setTransform(-192.35, -7.35, 0.2134, 0.2134);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-192.3, -7.3, 334.3, 43.9);


  (lib.Tween18 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Support();
    this.instance.setTransform(-41.5, -41.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-41.5, -41.5, 83, 83);


  (lib.Tween17 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Support();
    this.instance.setTransform(-41.5, -41.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-41.5, -41.5, 83, 83);


  (lib.Tween16 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.restaurantico();
    this.instance.setTransform(-41.5, -41.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-41.5, -41.5, 83, 83);


  (lib.Tween15 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.restaurantico();
    this.instance.setTransform(-41.5, -41.5);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-41.5, -41.5, 83, 83);


  (lib.Tween14 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Menu();
    this.instance.setTransform(-41.5, -29);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-41.5, -29, 83, 58);


  (lib.Tween13 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.Menu();
    this.instance.setTransform(-41.5, -29);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-41.5, -29, 83, 58);


  (lib.Tween12 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("Eg+fA2sMAAAhtXMB8/AAAMAAABtXg");

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400, -350, 800, 700);


  (lib.Tween11 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.shape = new cjs.Shape();
    this.shape.graphics.f("#252423").s().p("Eg+fA2sMAAAhtXMB8/AAAMAAABtXg");

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-400, -350, 800, 700);


  (lib.Tween10 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Here is a brief story of the most important aspects of our application...", "17px 'Tahoma'", "#E8A015");
    this.text.lineHeight = 23;
    this.text.lineWidth = 523;
    this.text.parent = this;
    this.text.setTransform(-295, -14);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-297, -16, 527, 31);


  (lib.Tween9 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Here is a brief story of the most important aspects of our application...", "17px 'Tahoma'", "#E8A015");
    this.text.lineHeight = 23;
    this.text.lineWidth = 523;
    this.text.parent = this;
    this.text.setTransform(-295, -14);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-297, -16, 527, 31);


  (lib.Tween8 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("You are now a part of the PERE family, and we are glad to have you join us!", "17px 'Tahoma'", "#E8A015");
    this.text.textAlign = "center";
    this.text.lineHeight = 23;
    this.text.lineWidth = 421;
    this.text.parent = this;
    this.text.setTransform(-20.5, -26.45);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-233, -28.4, 425, 59.4);


  (lib.Tween7 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("You are now a part of the PERE family, and we are glad to have you join us!", "17px 'Tahoma'", "#E8A015");
    this.text.textAlign = "center";
    this.text.lineHeight = 23;
    this.text.lineWidth = 421;
    this.text.parent = this;
    this.text.setTransform(-20.5, -26.45);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-233, -28.4, 425, 59.4);


  (lib.Tween6 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Welcome onboard!", "bold 50px 'Tahoma'");
    this.text.lineHeight = 62;
    this.text.lineWidth = 493;
    this.text.parent = this;
    this.text.setTransform(-246.5, -34.8);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-248.5, -36.8, 497, 73.6);


  (lib.Tween5 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Welcome onboard!", "bold 50px 'Tahoma'");
    this.text.lineHeight = 62;
    this.text.lineWidth = 493;
    this.text.parent = this;
    this.text.setTransform(-246.5, -34.8);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-248.5, -36.8, 497, 73.6);


  (lib.Tween3 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.rocket();
    this.instance.setTransform(24.7, -88.4, 0.2305, 0.2305, 59.9969);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-88.9, -88.4, 177.8, 176.8);


  (lib.smoke_1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.instance = new lib.smoke();
    this.instance.setTransform(0, 9, 0.1929, 0.1929, -14.9959);

    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 51.9, 77.4);


  (lib.previous_btn = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_2
    this.shape = new cjs.Shape();
    this.shape.graphics.f("rgba(232,160,21,0)").s().p("AoHB6IAAj0IQPAAIAAD0g");
    this.shape.setTransform(52, 12.25);

    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    // Layer_1
    this.text = new cjs.Text("Previous", "bold 14px 'Tahoma'", "#E8A015");
    this.text.textAlign = "center";
    this.text.lineHeight = 19;
    this.text.lineWidth = 100;
    this.text.parent = this;
    this.text.setTransform(52, 2);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 104, 24.5);


  (lib.next_button_ = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Next", "bold 14px 'Tahoma'", "#E8A015");
    this.text.textAlign = "center";
    this.text.lineHeight = 19;
    this.text.lineWidth = 56;
    this.text.parent = this;
    this.text.setTransform(30.1, 2);

    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 0, 60.3, 20.9);


  (lib.app_btn = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.text = new cjs.Text("Let's go", "25px 'Tahoma'");
    this.text.textAlign = "center";
    this.text.lineHeight = 32;
    this.text.lineWidth = 408;
    this.text.parent = this;
    this.text.setTransform(206, 26.95);

    this.shape = new cjs.Shape();
    this.shape.graphics.f("#E8A014").s().p("A+nFaQhkAAAAhTIAAoNQAAhUBkAAMA9PAAAQBkAAAABUIAAINQAABThkAAg");
    this.shape.setTransform(205.975, 41.4);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape
      }, {
        t: this.text
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(0, 6.8, 412, 69.3);


  (lib.Tween103 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.app_btn = new lib.app_btn();
    this.app_btn.name = "app_btn";
    this.app_btn.setTransform(-319.9, 0.05, 1, 1, 0, 0, 0, 206, 41.4);
    new cjs.ButtonHelper(this.app_btn, 0, 1, 1);

    this.shape = new cjs.Shape();
    this.shape.graphics.f().s("#252423").ss(0.1, 1, 1).p("A+nmdMA9PAAAQBkAAAABkIAAJzQAABkhkAAMg9PAAAQhkAAAAhkIAApzQAAhkBkAAg");
    this.shape.setTransform(319.975, 0);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape
      }, {
        t: this.app_btn
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-525.9, -42.3, 1052.9, 84.69999999999999);


  (lib.Tween102 = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Layer_1
    this.app_btn = new lib.app_btn();
    this.app_btn.name = "app_btn";
    this.app_btn.setTransform(-319.9, 0.05, 1, 1, 0, 0, 0, 206, 41.4);
    new cjs.ButtonHelper(this.app_btn, 0, 1, 1);

    this.shape = new cjs.Shape();
    this.shape.graphics.f().s("#252423").ss(0.1, 1, 1).p("A+nmcMA9PAAAQBkAAAABkIAAJyQAABkhkAAMg9PAAAQhkAAAAhkIAApyQAAhkBkAAg");
    this.shape.setTransform(319.975, 0);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: [{
        t: this.shape
      }, {
        t: this.app_btn
      }]
    }).wait(1));

    this._renderFirstFrame();

  }).prototype = p = new cjs.MovieClip();
  p.nominalBounds = new cjs.Rectangle(-525.9, -42.3, 1052.9, 84.69999999999999);


  // stage content:
  (lib.demorestaurants = function (mode, startPosition, loop, reversed) {
    if (loop == null) {
      loop = true;
    }
    if (reversed == null) {
      reversed = false;
    }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    this.actionFrames = [0, 69, 71, 145, 147, 207, 209, 278, 280, 361, 369, 391, 398];
    // timeline functions:
    this.frame_0 = function () {
      this.next_button_.addEventListener('click', () => {
        this.gotoAndPlay(71)
      });
    }
    this.frame_69 = function () {
      /* Stop at This Frame
      The  timeline will stop/pause at the frame where you insert this code.
      Can also be used to stop/pause the timeline of movieclips.
      */

      this.stop();
    }
    this.frame_71 = function () {
      this.next_button_.addEventListener('click', () => {
        this.gotoAndPlay(147)
      });
      this.prev_btn.addEventListener('click', () => {
        this.gotoAndPlay(1)
      });
    }
    this.frame_145 = function () {
      /* Stop at This Frame
      The  timeline will stop/pause at the frame where you insert this code.
      Can also be used to stop/pause the timeline of movieclips.
      */

      this.stop();
    }
    this.frame_147 = function () {
      this.next_button_.addEventListener('click', () => {
        this.gotoAndPlay(209)
      });
      this.prev_btn.addEventListener('click', () => {
        this.gotoAndPlay(86)
      });
    }
    this.frame_207 = function () {
      /* Stop at This Frame
      The  timeline will stop/pause at the frame where you insert this code.
      Can also be used to stop/pause the timeline of movieclips.
      */

      this.stop();
    }
    this.frame_209 = function () {
      this.next_button_.addEventListener('click', () => {
        this.gotoAndPlay(280)
      });
      this.prev_btn.addEventListener('click', () => {
        this.gotoAndPlay(148)
      });
    }
    this.frame_278 = function () {
      /* Stop at This Frame
      The  timeline will stop/pause at the frame where you insert this code.
      Can also be used to stop/pause the timeline of movieclips.
      */

      this.stop();
    }
    this.frame_280 = function () {
      this.next_button_.addEventListener('click', () => {
        this.gotoAndPlay(363)
      });
      this.prev_btn.addEventListener('click', () => {
        this.gotoAndPlay(220)
      });
    }
    this.frame_361 = function () {
      /* Stop at This Frame
      The  timeline will stop/pause at the frame where you insert this code.
      Can also be used to stop/pause the timeline of movieclips.
      */

      this.stop();
    }
    this.frame_369 = function () {
      this.prev_btn.addEventListener('click', () => {
        this.gotoAndPlay(282)
      });
    }
    this.frame_391 = function () {
      var _this = this;
      /*
      Clicking on the specified symbol instance executes a function.
      */
      _this.app_btn.on('click', function () {
        /*
        Loads the URL in a new browser window.
        */
        window.open('/admin/grid', '_self');
      });
    }
    this.frame_398 = function () {
      /* Stop at This Frame
      The  timeline will stop/pause at the frame where you insert this code.
      Can also be used to stop/pause the timeline of movieclips.
      */

      this.stop();
    }

    // actions tween:
    this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(69).call(this.frame_69).wait(2).call(this.frame_71).wait(74).call(this.frame_145).wait(2).call(this.frame_147).wait(60).call(this.frame_207).wait(2).call(this.frame_209).wait(69).call(this.frame_278).wait(2).call(this.frame_280).wait(81).call(this.frame_361).wait(8).call(this.frame_369).wait(22).call(this.frame_391).wait(7).call(this.frame_398).wait(1));

    // next_btn
    this.next_button_ = new lib.next_button_();
    this.next_button_.name = "next_button_";
    this.next_button_.setTransform(768.85, 676.85, 1, 1, 0, 0, 0, 30.1, 10.5);
    new cjs.ButtonHelper(this.next_button_, 0, 1, 1);

    this.timeline.addTween(cjs.Tween.get(this.next_button_).wait(280).to({
      _off: true
    }, 82).wait(37));

    // prev_btn
    this.prev_btn = new lib.previous_btn();
    this.prev_btn.name = "prev_btn";
    this.prev_btn.setTransform(52, 676.1, 1, 1, 0, 0, 0, 52, 12.2);
    this.prev_btn._off = true;
    new cjs.ButtonHelper(this.prev_btn, 0, 1, 1);

    this.timeline.addTween(cjs.Tween.get(this.prev_btn).wait(71).to({
      _off: false
    }, 0).wait(328));

    // app_btn
    this.instance = new lib.Tween102("synched", 0);
    this.instance.setTransform(73.95, 421.3);
    this.instance._off = true;

    this.instance_1 = new lib.Tween103("synched", 0);
    this.instance_1.setTransform(817.85, 421.3);
    this.instance_1._off = true;

    this.app_btn = new lib.Tween103();
    this.app_btn.name = "app_btn";
    this.app_btn.setTransform(721.9, 421.3);
    new cjs.ButtonHelper(this.app_btn, 0, 1, 1);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance
      }]
    }, 382).to({
      state: [{
        t: this.instance_1
      }]
    }, 7).to({
      state: [{
        t: this.app_btn
      }]
    }, 2).wait(8));
    this.timeline.addTween(cjs.Tween.get(this.instance).wait(382).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 817.85
    }, 7).wait(10));
    this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(382).to({
      _off: false
    }, 7).to({
      _off: true,
      x: 721.9,
      mode: "independent"
    }, 2).wait(8));

    // text
    this.instance_2 = new lib.Tween100("synched", 0);
    this.instance_2.setTransform(398.6, 322.8);
    this.instance_2.alpha = 0;
    this.instance_2._off = true;

    this.instance_3 = new lib.Tween101("synched", 0);
    this.instance_3.setTransform(398.6, 322.8);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_2
      }]
    }, 372).to({
      state: [{
        t: this.instance_3
      }]
    }, 10).wait(17));
    this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(372).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 10).wait(17));

    // bg
    this.instance_4 = new lib.Tween98("synched", 0);
    this.instance_4.setTransform(-8.3, 704.55);
    this.instance_4._off = true;

    this.instance_5 = new lib.Tween99("synched", 0);
    this.instance_5.setTransform(439.85, 349.1, 25.8019, 25.8019, 0, 0, 0, 1.3, 1.3);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_4
      }]
    }, 362).to({
      state: [{
        t: this.instance_5
      }]
    }, 10).wait(27));
    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(362).to({
      _off: false
    }, 0).to({
      _off: true,
      regX: 1.3,
      regY: 1.3,
      scaleX: 25.8019,
      scaleY: 25.8019,
      x: 439.85,
      y: 349.1
    }, 10).wait(27));

    // desc
    this.instance_6 = new lib.Tween94("synched", 0);
    this.instance_6.setTransform(399.95, 651.4);
    this.instance_6.alpha = 0;
    this.instance_6._off = true;

    this.instance_7 = new lib.Tween95("synched", 0);
    this.instance_7.setTransform(399.95, 651.4);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_6
      }]
    }, 342).to({
      state: [{
        t: this.instance_7
      }]
    }, 10).to({
      state: []
    }, 10).wait(37));
    this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(342).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 10).wait(47));

    // support_input
    this.instance_8 = new lib.Tween92("synched", 0);
    this.instance_8.setTransform(400.4, 552.35);
    this.instance_8.alpha = 0;
    this.instance_8._off = true;

    this.instance_9 = new lib.Tween93("synched", 0);
    this.instance_9.setTransform(400.4, 545.6);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_8
      }]
    }, 332).to({
      state: [{
        t: this.instance_9
      }]
    }, 10).to({
      state: [{
        t: this.instance_9
      }]
    }, 12).to({
      state: []
    }, 8).wait(37));
    this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(332).to({
      _off: false
    }, 0).to({
      _off: true,
      y: 545.6,
      alpha: 1
    }, 10).wait(57));

    // subtitle_2
    this.instance_10 = new lib.Tween90("synched", 0);
    this.instance_10.setTransform(398.6, 438.6);
    this.instance_10.alpha = 0;
    this.instance_10._off = true;

    this.instance_11 = new lib.Tween91("synched", 0);
    this.instance_11.setTransform(398.6, 438.6);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_10
      }]
    }, 322).to({
      state: [{
        t: this.instance_11
      }]
    }, 10).to({
      state: []
    }, 30).wait(37));
    this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(322).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 10).wait(67));

    // subtitle
    this.instance_12 = new lib.Tween88("synched", 0);
    this.instance_12.setTransform(399.4, 349.4);
    this.instance_12.alpha = 0;
    this.instance_12._off = true;

    this.instance_13 = new lib.Tween89("synched", 0);
    this.instance_13.setTransform(399.4, 349.4);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_12
      }]
    }, 312).to({
      state: [{
        t: this.instance_13
      }]
    }, 10).to({
      state: []
    }, 40).wait(37));
    this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(312).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 10).wait(77));

    // title
    this.instance_14 = new lib.Tween86("synched", 0);
    this.instance_14.setTransform(409.7, 239.85);
    this.instance_14.alpha = 0;
    this.instance_14._off = true;

    this.instance_15 = new lib.Tween87("synched", 0);
    this.instance_15.setTransform(409.7, 239.85);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_14
      }]
    }, 301).to({
      state: [{
        t: this.instance_15
      }]
    }, 11).to({
      state: []
    }, 50).wait(37));
    this.timeline.addTween(cjs.Tween.get(this.instance_14).wait(301).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 11).wait(87));

    // support_ico_x3
    this.instance_16 = new lib.Tween84("synched", 0);
    this.instance_16.setTransform(399.6, 220.6);
    this.instance_16._off = true;

    this.instance_17 = new lib.Tween85("synched", 0);
    this.instance_17.setTransform(402.25, 84.95, 0.424, 0.424, 0, 0, 0, 2.6, -135.6);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_16
      }]
    }, 290).to({
      state: [{
        t: this.instance_16
      }]
    }, 10).to({
      state: [{
        t: this.instance_17
      }]
    }, 1).to({
      state: []
    }, 61).wait(37));
    this.timeline.addTween(cjs.Tween.get(this.instance_16).wait(290).to({
      _off: false
    }, 0).to({
      regY: 0.1,
      scaleX: 0.4764,
      scaleY: 0.4764,
      x: 402,
      y: 148.65
    }, 10).to({
      _off: true,
      regX: 2.6,
      regY: -135.6,
      scaleX: 0.424,
      scaleY: 0.424,
      x: 402.25,
      y: 84.95
    }, 1).wait(98));

    // bg
    this.instance_18 = new lib.Tween82("synched", 0);
    this.instance_18.setTransform(405.25, 353.3);
    this.instance_18.alpha = 0;
    this.instance_18._off = true;

    this.instance_19 = new lib.Tween83("synched", 0);
    this.instance_19.setTransform(405.25, 353.3);
    this.instance_19._off = true;

    this.timeline.addTween(cjs.Tween.get(this.instance_18).wait(282).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 8).wait(109));
    this.timeline.addTween(cjs.Tween.get(this.instance_19).wait(282).to({
      _off: false
    }, 8).to({
      alpha: 0
    }, 11).to({
      _off: true
    }, 1).wait(97));

    // desc
    this.instance_20 = new lib.Tween80("synched", 0);
    this.instance_20.setTransform(401.3, 634.05);
    this.instance_20.alpha = 0;
    this.instance_20._off = true;

    this.instance_21 = new lib.Tween81("synched", 0);
    this.instance_21.setTransform(401.3, 634.05);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_20
      }]
    }, 268).to({
      state: [{
        t: this.instance_21
      }]
    }, 10).to({
      state: []
    }, 1).wait(120));
    this.timeline.addTween(cjs.Tween.get(this.instance_20).wait(268).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 10).wait(121));

    // btns_img
    this.instance_22 = new lib.Tween78("synched", 0);
    this.instance_22.setTransform(499.8, 523.05);
    this.instance_22.alpha = 0;
    this.instance_22._off = true;

    this.instance_23 = new lib.Tween79("synched", 0);
    this.instance_23.setTransform(489, 523.05);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_22
      }]
    }, 258).to({
      state: [{
        t: this.instance_23
      }]
    }, 10).to({
      state: []
    }, 11).wait(120));
    this.timeline.addTween(cjs.Tween.get(this.instance_22).wait(258).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 489,
      alpha: 1
    }, 10).wait(131));

    // subtitle
    this.instance_24 = new lib.Tween76("synched", 0);
    this.instance_24.setTransform(399.4, 364.75);
    this.instance_24.alpha = 0;
    this.instance_24._off = true;

    this.instance_25 = new lib.Tween77("synched", 0);
    this.instance_25.setTransform(399.4, 364.75);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_24
      }]
    }, 247).to({
      state: [{
        t: this.instance_25
      }]
    }, 11).to({
      state: []
    }, 21).wait(120));
    this.timeline.addTween(cjs.Tween.get(this.instance_24).wait(247).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 11).wait(141));

    // title
    this.instance_26 = new lib.Tween74("synched", 0);
    this.instance_26.setTransform(390.05, 251.65);
    this.instance_26.alpha = 0;
    this.instance_26._off = true;

    this.instance_27 = new lib.Tween75("synched", 0);
    this.instance_27.setTransform(392.75, 251.65);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_26
      }]
    }, 237).to({
      state: [{
        t: this.instance_27
      }]
    }, 10).to({
      state: []
    }, 32).wait(120));
    this.timeline.addTween(cjs.Tween.get(this.instance_26).wait(237).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 392.75,
      alpha: 1
    }, 10).wait(152));

    // res_ico_x3
    this.instance_28 = new lib.Tween72("synched", 0);
    this.instance_28.setTransform(399.75, 350.75);
    this.instance_28.alpha = 0;
    this.instance_28._off = true;

    this.instance_29 = new lib.Tween73("synched", 0);
    this.instance_29.setTransform(399.75, 350.75);
    this.instance_29._off = true;

    this.timeline.addTween(cjs.Tween.get(this.instance_28).wait(218).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 9).wait(172));
    this.timeline.addTween(cjs.Tween.get(this.instance_29).wait(218).to({
      _off: false
    }, 9).to({
      regX: 0.1,
      scaleX: 0.4733,
      scaleY: 0.4733,
      x: 399.8,
      y: 152.75
    }, 9).to({
      regX: 0,
      regY: -145.8,
      scaleX: 0.4147,
      scaleY: 0.4147,
      x: 399.75,
      y: 86.3
    }, 1).to({
      _off: true
    }, 42).wait(120));

    // bg
    this.instance_30 = new lib.Tween70("synched", 0);
    this.instance_30.setTransform(395.2, 344.65);
    this.instance_30.alpha = 0;
    this.instance_30._off = true;

    this.instance_31 = new lib.Tween71("synched", 0);
    this.instance_31.setTransform(395.2, 344.65);
    this.instance_31._off = true;

    this.timeline.addTween(cjs.Tween.get(this.instance_30).wait(208).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 10).wait(181));
    this.timeline.addTween(cjs.Tween.get(this.instance_31).wait(208).to({
      _off: false
    }, 10).wait(9).to({
      startPosition: 0
    }, 0).to({
      alpha: 0
    }, 10).to({
      _off: true
    }, 1).wait(161));

    // subtitle
    this.instance_32 = new lib.Tween62("synched", 0);
    this.instance_32.setTransform(399.85, 384.3);
    this.instance_32.alpha = 0;
    this.instance_32._off = true;

    this.instance_33 = new lib.Tween63("synched", 0);
    this.instance_33.setTransform(399.85, 384.3);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_32
      }]
    }, 176).to({
      state: [{
        t: this.instance_33
      }]
    }, 11).to({
      state: []
    }, 21).wait(191));
    this.timeline.addTween(cjs.Tween.get(this.instance_32).wait(176).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 11).wait(212));

    // title
    this.instance_34 = new lib.Tween60("synched", 0);
    this.instance_34.setTransform(403.85, 232.35);
    this.instance_34.alpha = 0;
    this.instance_34._off = true;

    this.instance_35 = new lib.Tween61("synched", 0);
    this.instance_35.setTransform(403.85, 232.35);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_34
      }]
    }, 166).to({
      state: [{
        t: this.instance_35
      }]
    }, 10).to({
      state: []
    }, 32).wait(191));
    this.timeline.addTween(cjs.Tween.get(this.instance_34).wait(166).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 10).wait(223));

    // menu_ico
    this.instance_36 = new lib.Tween58("synched", 0);
    this.instance_36.setTransform(399.6, 351.05);
    this.instance_36.alpha = 0;
    this.instance_36._off = true;

    this.instance_37 = new lib.Tween59("synched", 0);
    this.instance_37.setTransform(399.6, 351.05);
    this.instance_37._off = true;

    this.timeline.addTween(cjs.Tween.get(this.instance_36).wait(146).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 10).wait(243));
    this.timeline.addTween(cjs.Tween.get(this.instance_37).wait(146).to({
      _off: false
    }, 10).to({
      regX: 0.1,
      scaleX: 0.4734,
      scaleY: 0.4734,
      x: 399.65,
      y: 133.45
    }, 9).to({
      regX: 0,
      regY: -94,
      scaleX: 0.4149,
      scaleY: 0.4149,
      x: 399.6,
      y: 89.05
    }, 1).to({
      _off: true
    }, 42).wait(191));

    // mobile_txt
    this.instance_38 = new lib.Tween29("synched", 0);
    this.instance_38.setTransform(586.05, 308.05);
    this.instance_38.alpha = 0;
    this.instance_38._off = true;

    this.instance_39 = new lib.Tween30("synched", 0);
    this.instance_39.setTransform(586.05, 308.05);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_38
      }]
    }, 130).to({
      state: [{
        t: this.instance_39
      }]
    }, 11).to({
      state: []
    }, 5).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_38).wait(130).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 11).wait(258));

    // mobile_nav
    this.instance_40 = new lib.Tween21("synched", 0);
    this.instance_40.setTransform(-413.65, 332.35);
    this.instance_40._off = true;

    this.instance_41 = new lib.Tween22("synched", 0);
    this.instance_41.setTransform(451.5, 332.35);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_40
      }]
    }, 123).to({
      state: [{
        t: this.instance_41
      }]
    }, 8).to({
      state: []
    }, 15).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_40).wait(123).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 451.5
    }, 8).wait(268));

    // desktop_txt
    this.instance_42 = new lib.Tween31("synched", 0);
    this.instance_42.setTransform(715.3, 413.3);
    this.instance_42.alpha = 0;
    this.instance_42._off = true;

    this.instance_43 = new lib.Tween32("synched", 0);
    this.instance_43.setTransform(715.3, 413.3);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_42
      }]
    }, 139).to({
      state: [{
        t: this.instance_43
      }]
    }, 6).to({
      state: []
    }, 1).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_42).wait(139).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 6).wait(254));

    // desktop_nav
    this.instance_44 = new lib.Tween23("synched", 0);
    this.instance_44.setTransform(-416.05, 436.15);
    this.instance_44._off = true;

    this.instance_45 = new lib.Tween24("synched", 0);
    this.instance_45.setTransform(406.5, 436.15);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_44
      }]
    }, 131).to({
      state: [{
        t: this.instance_45
      }]
    }, 9).to({
      state: []
    }, 6).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_44).wait(131).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 406.5
    }, 9).wait(259));

    // __nd_subtitle_2
    this.instance_46 = new lib.Tween35("synched", 0);
    this.instance_46.setTransform(458.3, 656.55);
    this.instance_46.alpha = 0;
    this.instance_46._off = true;

    this.instance_47 = new lib.Tween36("synched", 0);
    this.instance_47.setTransform(458.3, 656.55);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_46
      }]
    }, 133).to({
      state: [{
        t: this.instance_47
      }]
    }, 12).to({
      state: []
    }, 1).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_46).wait(133).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 12).wait(254));

    // _nd_subtitle
    this.instance_48 = new lib.Tween33("synched", 0);
    this.instance_48.setTransform(439.55, 539.65);
    this.instance_48.alpha = 0;
    this.instance_48._off = true;

    this.instance_49 = new lib.Tween34("synched", 0);
    this.instance_49.setTransform(439.55, 539.65);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_48
      }]
    }, 119).to({
      state: [{
        t: this.instance_49
      }]
    }, 14).to({
      state: []
    }, 13).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_48).wait(119).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 14).wait(266));

    // menu_ico
    this.instance_50 = new lib.Tween13("synched", 0);
    this.instance_50.setTransform(-205.5, 184);
    this.instance_50._off = true;

    this.instance_51 = new lib.Tween14("synched", 0);
    this.instance_51.setTransform(547.15, 184);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_50
      }]
    }, 84).to({
      state: [{
        t: this.instance_51
      }]
    }, 11).to({
      state: [{
        t: this.instance_51
      }]
    }, 23).to({
      state: [{
        t: this.instance_51
      }]
    }, 1).to({
      state: []
    }, 27).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_50).wait(84).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 547.15
    }, 11).wait(304));

    // restaurant_ico
    this.instance_52 = new lib.Tween15("synched", 0);
    this.instance_52.setTransform(-360.5, 185.5);
    this.instance_52._off = true;

    this.instance_53 = new lib.Tween16("synched", 0);
    this.instance_53.setTransform(404.15, 185.5);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_52
      }]
    }, 95).to({
      state: [{
        t: this.instance_53
      }]
    }, 11).to({
      state: [{
        t: this.instance_53
      }]
    }, 12).to({
      state: [{
        t: this.instance_53
      }]
    }, 1).to({
      state: []
    }, 27).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_52).wait(95).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 404.15
    }, 11).wait(293));

    // support_ico
    this.instance_54 = new lib.Tween17("synched", 0);
    this.instance_54.setTransform(-507.5, 185.5);
    this.instance_54._off = true;

    this.instance_55 = new lib.Tween18("synched", 0);
    this.instance_55.setTransform(266.15, 185.5);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_54
      }]
    }, 106).to({
      state: [{
        t: this.instance_55
      }]
    }, 12).to({
      state: [{
        t: this.instance_55
      }]
    }, 1).to({
      state: []
    }, 27).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_54).wait(106).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 266.15
    }, 12).wait(281));

    // bg_2
    this.instance_56 = new lib.Tween11("synched", 0);
    this.instance_56.setTransform(400, 350.05);
    this.instance_56.alpha = 0;
    this.instance_56._off = true;

    this.instance_57 = new lib.Tween12("synched", 0);
    this.instance_57.setTransform(400, 350.05);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_56
      }]
    }, 70).to({
      state: [{
        t: this.instance_57
      }]
    }, 14).to({
      state: [{
        t: this.instance_57
      }]
    }, 15).to({
      state: [{
        t: this.instance_57
      }]
    }, 20).to({
      state: []
    }, 27).wait(253));
    this.timeline.addTween(cjs.Tween.get(this.instance_56).wait(70).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 14).wait(315));

    // subtitle_2
    this.instance_58 = new lib.Tween9("synched", 0);
    this.instance_58.setTransform(439, 632.9);
    this.instance_58.alpha = 0;
    this.instance_58._off = true;

    this.instance_59 = new lib.Tween10("synched", 0);
    this.instance_59.setTransform(451, 632.9);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_58
      }]
    }, 48).to({
      state: [{
        t: this.instance_59
      }]
    }, 10).to({
      state: []
    }, 12).wait(329));
    this.timeline.addTween(cjs.Tween.get(this.instance_58).wait(48).to({
      _off: false
    }, 0).to({
      _off: true,
      x: 451,
      alpha: 1
    }, 10).wait(341));

    // Subtitle
    this.instance_60 = new lib.Tween7("synched", 0);
    this.instance_60.setTransform(433, 584.45);
    this.instance_60.alpha = 0;
    this.instance_60._off = true;

    this.instance_61 = new lib.Tween8("synched", 0);
    this.instance_61.setTransform(433, 584.45);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_60
      }]
    }, 31).to({
      state: [{
        t: this.instance_61
      }]
    }, 13).to({
      state: []
    }, 41).wait(314));
    this.timeline.addTween(cjs.Tween.get(this.instance_60).wait(31).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 13).wait(355));

    // Welcome
    this.instance_62 = new lib.Tween5("synched", 0);
    this.instance_62.setTransform(411.5, 510.2);
    this.instance_62.alpha = 0;
    this.instance_62._off = true;

    this.instance_63 = new lib.Tween6("synched", 0);
    this.instance_63.setTransform(411.5, 510.2);

    this.timeline.addTween(cjs.Tween.get({}).to({
      state: []
    }).to({
      state: [{
        t: this.instance_62
      }]
    }, 15).to({
      state: [{
        t: this.instance_63
      }]
    }, 22).to({
      state: []
    }, 48).wait(314));
    this.timeline.addTween(cjs.Tween.get(this.instance_62).wait(15).to({
      _off: false
    }, 0).to({
      _off: true,
      alpha: 1
    }, 22).wait(362));

    // smoke
    this.instance_64 = new lib.smoke_1("synched", 0);
    this.instance_64.setTransform(-27.95, 415.95, 0.5921, 0.5921, 0, 0, 0, 25.9, 38.8);

    this.timeline.addTween(cjs.Tween.get(this.instance_64).to({
      regY: 38.7,
      scaleX: 1,
      scaleY: 1,
      rotation: 14.9983,
      guide: {
        path: [-27.9, 416, -8.2, 403.1, 30.5, 383, 68.8, 363.1, 111.6, 344.2]
      },
      alpha: 0
    }, 6).to({
      regX: 26,
      scaleX: 1.4232,
      scaleY: 1.4232,
      rotation: 14.9982,
      guide: {
        path: [111.7, 344.2, 155.2, 325, 203.2, 307, 267.2, 282.9, 335.1, 262.5]
      },
      alpha: 1
    }, 7).to({
      alpha: 0
    }, 9).to({
      guide: {
        path: [335.1, 262.5, 335.1, 262.5, 335.1, 262.5]
      },
      alpha: 1
    }, 10).to({
      alpha: 0
    }, 9).to({
      alpha: 1
    }, 8).to({
      alpha: 0
    }, 10).to({
      alpha: 1
    }, 10).wait(15).to({
      startPosition: 0
    }, 0).to({
      _off: true
    }, 1).wait(314));

    // rocket
    this.instance_65 = new lib.Tween3("synched", 0);
    this.instance_65.setTransform(-28, 415.95, 0.5388, 0.5388, 0, 0, 0, -0.1, 0.3);

    this.timeline.addTween(cjs.Tween.get(this.instance_65).to({
      regX: 0.2,
      regY: 0.1,
      scaleX: 1.4175,
      scaleY: 1.4175,
      rotation: 15.6869,
      guide: {
        path: [-27.9, 416, -8.2, 403.1, 30.5, 383, 108, 342.8, 203.3, 307, 325.1, 261.2, 460.9, 228.6],
        orient: 'fixed'
      }
    }, 13).to({
      startPosition: 0
    }, 56).to({
      startPosition: 0
    }, 15).to({
      _off: true
    }, 1).wait(314));

    // bg
    this.instance_66 = new lib.bg();
    this.instance_66.setTransform(0, 0, 0.4577, 0.4917);

    this.timeline.addTween(cjs.Tween.get(this.instance_66).wait(84).to({
      _off: true
    }, 1).wait(314));

    this._renderFirstFrame();

  }).prototype = p = new lib.AnMovieClip();
  p.nominalBounds = new cjs.Rectangle(-363, 118.6, 1707.8, 760.5);
  // library properties:
  lib.properties = {
    id: '47A4D6DD2AFDBD4992A5989269A11E7A',
    width: 800,
    height: 700,
    fps: 24,
    color: "#FFFFFF",
    opacity: 1.00,
    manifest: [{
        src: "assets/js/suo-images/bg.png",
        id: "bg"
      },
      {
        src: "assets/js/suo-images/chatinput.png",
        id: "chatinput"
      },
      {
        src: "assets/js/suo-images/menuicox3.png",
        id: "menuicox3"
      },
      {
        src: "assets/js/suo-images/Menu.png",
        id: "Menu"
      },
      {
        src: "assets/js/suo-images/resbtns.png",
        id: "resbtns"
      },
      {
        src: "assets/js/suo-images/restauranticox3.png",
        id: "restauranticox3"
      },
      {
        src: "assets/js/suo-images/restaurantico.png",
        id: "restaurantico"
      },
      {
        src: "assets/js/suo-images/rocket.png",
        id: "rocket"
      },
      {
        src: "assets/js/suo-images/smoke.png",
        id: "smoke"
      },
      {
        src: "assets/js/suo-images/Support.png",
        id: "Support"
      },
      {
        src: "assets/js/suo-images/support_ico_x3.png",
        id: "support_ico_x3"
      },
      {
        src: "assets/js/suo-images/TopMenu111.png",
        id: "TopMenu111"
      },
      {
        src: "assets/js/suo-images/TopMenuMobile.png",
        id: "TopMenuMobile"
      }
    ],
    preloads: []
  };



  // bootstrap callback support:

  (lib.Stage = function (canvas) {
    createjs.Stage.call(this, canvas);
  }).prototype = p = new createjs.Stage();

  p.setAutoPlay = function (autoPlay) {
    this.tickEnabled = autoPlay;
  }
  p.play = function () {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndPlay(this.getTimelinePosition())
  }
  p.stop = function (ms) {
    if (ms) this.seek(ms);
    this.tickEnabled = false;
  }
  p.seek = function (ms) {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000);
  }
  p.getDuration = function () {
    return this.getChildAt(0).totalFrames / lib.properties.fps * 1000;
  }

  p.getTimelinePosition = function () {
    return this.getChildAt(0).currentFrame / lib.properties.fps * 1000;
  }

  an.bootcompsLoaded = an.bootcompsLoaded || [];
  if (!an.bootstrapListeners) {
    an.bootstrapListeners = [];
  }

  an.bootstrapCallback = function (fnCallback) {
    an.bootstrapListeners.push(fnCallback);
    if (an.bootcompsLoaded.length > 0) {
      for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
        fnCallback(an.bootcompsLoaded[i]);
      }
    }
  };

  an.compositions = an.compositions || {};
  an.compositions['47A4D6DD2AFDBD4992A5989269A11E7A'] = {
    getStage: function () {
      return exportRoot.stage;
    },
    getLibrary: function () {
      return lib;
    },
    getSpriteSheet: function () {
      return ss;
    },
    getImages: function () {
      return img;
    }
  };

  an.compositionLoaded = function (id) {
    an.bootcompsLoaded.push(id);
    for (var j = 0; j < an.bootstrapListeners.length; j++) {
      an.bootstrapListeners[j](id);
    }
  }

  an.getComposition = function (id) {
    return an.compositions[id];
  }


  an.makeResponsive = function (isResp, respDim, isScale, scaleType, domContainers) {
    var lastW, lastH, lastS = 1;
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    function resizeCanvas() {
      var w = lib.properties.width,
        h = lib.properties.height;
      var iw = window.innerWidth,
        ih = window.innerHeight;
      var pRatio = window.devicePixelRatio || 1,
        xRatio = iw / w,
        yRatio = ih / h,
        sRatio = 1;
      if (isResp) {
        if ((respDim == 'width' && lastW == iw) || (respDim == 'height' && lastH == ih)) {
          sRatio = lastS;
        } else if (!isScale) {
          if (iw < w || ih < h)
            sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType == 1) {
          sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType == 2) {
          sRatio = Math.max(xRatio, yRatio);
        }
      }
      domContainers[0].width = w * pRatio * sRatio;
      domContainers[0].height = h * pRatio * sRatio;
      domContainers.forEach(function (container) {
        container.style.width = w * sRatio + 'px';
        container.style.height = h * sRatio + 'px';
      });
      stage.scaleX = pRatio * sRatio;
      stage.scaleY = pRatio * sRatio;
      lastW = iw;
      lastH = ih;
      lastS = sRatio;
      stage.tickOnUpdate = false;
      stage.update();
      stage.tickOnUpdate = true;
    }
  }
  an.handleSoundStreamOnTick = function (event) {
    if (!event.paused) {
      var stageChild = stage.getChildAt(0);
      if (!stageChild.paused || stageChild.ignorePause) {
        stageChild.syncStreamSounds();
      }
    }
  }


})(createjs = createjs || {}, AdobeAn = AdobeAn || {});
var createjs, AdobeAn;


var canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;

function initServiceUserOnboarding() {
  canvas = document.getElementById("canvas");
  anim_container = document.getElementById("animation_container");
  dom_overlay_container = document.getElementById("dom_overlay_container");
  var comp = AdobeAn.getComposition("47A4D6DD2AFDBD4992A5989269A11E7A");
  var lib = comp.getLibrary();
  createjs.MotionGuidePlugin.install();
  var loader = new createjs.LoadQueue(false);
  loader.addEventListener("fileload", function (evt) {
    handleFileLoad(evt, comp)
  });
  loader.addEventListener("complete", function (evt) {
    handleComplete(evt, comp)
  });
  var lib = comp.getLibrary();
  loader.loadManifest(lib.properties.manifest);
}

function handleFileLoad(evt, comp) {
  var images = comp.getImages();
  if (evt && (evt.item.type == "image")) {
    images[evt.item.id] = evt.result;
  }
}

function handleComplete(evt, comp) {
  //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
  var lib = comp.getLibrary();
  var ss = comp.getSpriteSheet();
  var queue = evt.target;
  var ssMetadata = lib.ssMetadata;
  for (i = 0; i < ssMetadata.length; i++) {
    ss[ssMetadata[i].name] = new createjs.SpriteSheet({
      "images": [queue.getResult(ssMetadata[i].name)],
      "frames": ssMetadata[i].frames
    })
  }
  exportRoot = new lib.demorestaurants();
  stage = new lib.Stage(canvas);
  stage.enableMouseOver();
  //Registers the "tick" event listener.
  fnStartAnimation = function () {
    stage.addChild(exportRoot);
    createjs.Ticker.framerate = lib.properties.fps;
    createjs.Ticker.addEventListener("tick", stage);
  }
  //Code to support hidpi screens and responsive scaling.
  AdobeAn.makeResponsive(true, 'both', false, 1, [canvas, anim_container, dom_overlay_container]);
  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
}
